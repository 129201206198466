query Site($input: SiteGetByIDRequestT!) {
  site(input: $input) {
    id
    number
    orgID
    isDemoSite
    name
    address {
      id
      type
      place_type
      relevance
      properties {
        accuracy
      }
      text
      place_name
      center
      geometry {
        type
        coordinates
      }
      address
      context {
        id
        short_code
        wikidata
        text
      }
    }
    boundaries {
      id
      name
      points {
        latitude
        longitude
      }
      additionalData
      additional {
        sourceInformation
        category
        subcategory
      }
      markers {
        id
        comment
        location {
          longitude
          latitude
        }
        status
        createdAt
        createdBy
        updatedAt
        updatedBy
        approvalStatusChangedAt
        approvalStatusChangedBy
        approvalStatus
      }
    }
    monitoringZones {
      id
      name
      description
      points {
        latitude
        longitude
      }
      targetID
      targetName
      subTargetID
      subTargetName
    }
    assets {
      id
      siteID
      name
      number
      assetType {
        id
        name
      }
      boundaries {
        id
        name
        points {
          latitude
          longitude
        }
        additionalData
        additional {
          sourceInformation
          category
          subcategory
        }
        markers {
          id
          comment
          location {
            longitude
            latitude
          }
          status
          createdAt
          createdBy
          updatedAt
          updatedBy
          approvalStatusChangedAt
          approvalStatusChangedBy
          approvalStatus
        }
      }
      monitoringZones {
        id
        name
        description
        points {
          latitude
          longitude
        }
        targetID
        targetName
        subTargetID
        subTargetName
      }
    }
    surveys {
      id
      startDate
      endDate
      issues {
        id
        surveyID
        assetID
        assetTypeID
        assetTypeName
        name
        number
        notes
        priority
        riskType
        issueType
        status
        componentTypeID
        componentTypeName
        componentTypeFullName
        materialTypeID
        materialTypeName
        component {
          id
          name
          condition
          measurement
          componentTypeName
          componentTypeID
          materialTypeID
          materialTypeName
        }
        location {
          latitude
          longitude
          altitude
        }
        imageID
        imageHash
        imageDate
        coordinates {
          x
          y
        }
        repairEstimate
        defectTypeID
        defectType
        defectSubTypeID
        defectSubType
        defectSuggestedRemedy
        defectDescription
        defectCostToRepair
        defectCostPriorityScalar
      }
      components {
        id
        name
        assetID
        componentTypeID
        componentTypeName
        componentTypeFullName
        materialTypeID
        materialTypeName
        costPerUnit
        labourPerHour
        measurement
        condition
        points {
          latitude
          longitude
          altitude
        }
        children {
          id
          name
          componentTypeID
          componentTypeName
          componentTypeFullName
          materialTypeID
          materialTypeName
          costPerUnit
          labourPerHour
          measurement
          condition
          points {
            latitude
            longitude
            altitude
          }
        }
      }

      reconstructions {
        id
        instantAssessmentID
        cesiumID
        orthoID
        manualTranslation {
          longitude
          latitude
          altitude
        }
        clippingPlanesEnabled
        clippingPlanesInset
        terrainEnabled
        complete
        aoi {
          longitude
          latitude
        }
        useAligned
      }
    }
    lidar {
      id
      name
      state
      hash
      dateTaken
      bbox {
        latitude
        longitude
        altitude
      }
      classifications {
        name
        numPoints
      }
      hasRGB
    }
  }
}
