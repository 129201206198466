import React from 'react'
import './button.scss'

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  variant?: 'default' | 'primary' | 'secondary' | 'ghost' | 'light' | 'dark'
  wrapText?: boolean
  fullWidth?: boolean
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ onClick, children, variant = 'default', wrapText = true, fullWidth = false, ...props }, ref) => {
    return (
      <button
        ref={ref}
        className={`ui-button ${wrapText ? '' : 'text-nowrap'} ui-button-${variant} ${
          fullWidth ? 'ui-button-fullwidth' : ''
        }`}
        onClick={onClick}
        {...props}
      >
        {children}
      </button>
    )
  }
)

Button.displayName = 'Button'
