import React from 'react'
import { useUser } from '~/base'
import { getTracking, useLocation } from '~/components'
import { Currency } from '~/models'
import { BaseLayerType, useAppState } from '~/state'

const truncate = (input: string) => (input.length > 7 ? `${input.substring(0, 7)}...` : input)

export const AssetNav = () => {
  const user = useUser()
  const location = useLocation()
  const { view, site, asset, sites, map } = useAppState()

  const resetView = () => {
    setTimeout(() => {
      map.setBaseLayerType(BaseLayerType.Satellite).then(() => {
        map.jumpTo(sites.getBounds(user.org.currency !== Currency.Usd))
        getTracking().event({
          category: 'Breadcrumb',
          action: `User clicked home breadcrumb`,
          label: 'Asset',
        })
      })
    }, 0)
  }

  return (
    <nav aria-label='breadcrumbs'>
      <ol className='breadcrumb'>
        <li className='breadcrumb-item'>
          <a
            href='#'
            onClick={(e) => {
              e.preventDefault()
              resetView()
            }}
          >
            Home
          </a>
        </li>
        <li className='breadcrumb-item'>
          <a
            href='#'
            onClick={(e) => {
              e.preventDefault()
              if (view.surveyID) {
                location.setLocation(`/${site.site.id}/${view.surveyID}`)
              } else {
                location.setLocation(`/${site.site.id}`)
              }
            }}
          >
            {truncate(site.site?.name || '')}
          </a>
        </li>
        <li className='breadcrumb-item active' aria-current='page'>
          {asset.asset && asset.asset.name}
        </li>
      </ol>
    </nav>
  )
}
