import {
  SitesQuery,
  SiteQuery,
  ActivityStreamForOrgQuery,
  OrganizationsQuery,
  AdminGetOrganizationQuery,
  EventsQuery,
  QueryImagesByDaysResponseT,
  GetDefectTypesQuery,
  ImageTimelineQuery,
  AdminGetMonitoringZoneTargetsQuery,
  ActivityStreamForSurveyQuery,
  AnalysisResultQuery,
  SearchQuery,
  AdminGetSupplierQuery,
  SuppliersQuery,
  CurrentSupplierQuery,
  CurrentSupplierLicencesQuery,
  CurrentSupplierInsuranceQuery,
  SupplierInvitationsQuery,
  SupplierUsersQuery,
  WeatherSummaryForSitesQuery,
} from './gql-types'

export type SitesQuerySite = SitesQuery['sites'][0]

export type SitesQuerySiteWithExtra = { s: SitesQuerySite } & {
  extra: {
    siteID: string
    lastUpload: number
    center: Cesium.Cartesian3
    weather: WeatherSummaryForSitesQuery['weatherSummaryForSites'][0]
  }
}

export type SitesQuerySiteGroup = SitesQuery['siteGroups'][0]

export type SitesQuerySiteGroupWithExtra = {
  group: SitesQuerySiteGroup
  sites: SitesQuerySiteWithExtra[]
  lastUploadTime: number
  numAssets: number
  numHighIssues: number
  numMediumIssues: number
  numLowIssues: number
  center: Cesium.Cartesian3
  hull?: Cesium.Cartesian3[]
}

export type SiteQuerySite = SiteQuery['site']

export type SiteQueryAsset = SiteQuery['site']['assets'][0]

export type SiteQueryLidar = SiteQuery['site']['lidar'][0]

export type SiteQueryLidarClassification = SiteQuery['site']['lidar'][0]['classifications'][0]

export type SiteQuerySurvey = SiteQuery['site']['surveys'][0]

export type SiteQueryRecon = SiteQuery['site']['surveys'][0]['reconstructions'][0]

export type SiteQueryBoundary = SiteQuery['site']['boundaries'][0]

export type SiteQueryMonitoringZone = SiteQuery['site']['monitoringZones'][0]

export type SiteQueryImageTimelineItem = ImageTimelineQuery['queryImageTimeline']['days'][0]

export type OrgActivityItem = ActivityStreamForOrgQuery['activityStreamForOrg']['items'][0]

export type SurveyActivityItem = ActivityStreamForSurveyQuery['activityStreamForSurvey']['items'][0]

export type Photo = QueryImagesByDaysResponseT['images'][0]

export type OrganizationsQueryOrg = OrganizationsQuery['organizations']['orgs'][0]

export type SuppliersQuerySupplier = SuppliersQuery['suppliers']['suppliers'][0]

export type AdminGetOrganizationQueryOrg = AdminGetOrganizationQuery['organizationByID']

export type AdminGetSupplierQuerySupplier = AdminGetSupplierQuery['supplierByID']

export type Event = EventsQuery['events']['events'][0]

export type DefectType = GetDefectTypesQuery['defectTypes'][0]

export type DefectSubType = GetDefectTypesQuery['defectTypes'][0]['subTypes'][0]

export type Issue = SiteQuery['site']['surveys'][0]['issues'][0]

export type MonitoringZoneTarget = AdminGetMonitoringZoneTargetsQuery['monitoringZoneTargets'][0]

export type MonitoringZoneSubTarget = AdminGetMonitoringZoneTargetsQuery['monitoringZoneTargets'][0]['subTypes'][0]

export type SiteQueryIssue = SiteQuery['site']['surveys'][0]['issues'][0]

export type SiteQueryComponent = SiteQuery['site']['surveys'][0]['components'][0]

export type Analysis = AnalysisResultQuery['analysisResult']['items'][0]

export type SearchResult = SearchQuery['search']['items'][0]

export type Supplier = CurrentSupplierQuery['currentSupplier']['supplier']
export type SupplierCounts = Pick<
  CurrentSupplierQuery['currentSupplier'],
  | 'orderCountCancelled'
  | 'orderCountComplete'
  | 'orderCountInProgress'
  | 'orderCountInValidation'
  | 'orderCountPending'
  | 'orderCountRefinement'
>

export type ServiceArea = CurrentSupplierQuery['currentSupplier']['supplier']['specializations'][0]['serviceAreas'][0]

export type SupplierLicence = CurrentSupplierLicencesQuery['currentSupplierLicences'][0]

export type SupplierInsurance = CurrentSupplierInsuranceQuery['currentSupplierInsurance'][0]

export type SupplierUser = SupplierUsersQuery['supplierUsers'][0]

export type SupplierInvitation = SupplierInvitationsQuery['supplierInvitations'][0]

export type SupplierDrone =
  CurrentSupplierQuery['currentSupplier']['supplier']['specializations'][0]['droneSurvey']['drones'][0]
