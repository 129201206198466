import * as React from 'react'

import './pagination.scss'
import { ButtonProps } from 'ui'

const Pagination = ({ className, ...props }: React.ComponentProps<'nav'>) => (
  <nav role='navigation' aria-label='pagination' className={`ui-pagination ${className || ''}`} {...props} />
)
Pagination.displayName = 'Pagination'

const PaginationContent = React.forwardRef<HTMLUListElement, React.ComponentProps<'ul'>>(
  ({ className, ...props }, ref) => <ul ref={ref} className={`ui-pagination-content ${className || ''}`} {...props} />
)
PaginationContent.displayName = 'PaginationContent'

const PaginationItem = React.forwardRef<HTMLLIElement, React.ComponentProps<'li'>>(({ className, ...props }, ref) => (
  <li ref={ref} className={`ui-pagination-item ${className || ''}`} {...props} />
))
PaginationItem.displayName = 'PaginationItem'

type PaginationLinkProps = {
  isActive?: boolean
} & Pick<ButtonProps, 'variant'> &
  React.ComponentProps<'a'>

const PaginationLink = ({ className, isActive, variant = 'ghost', ...props }: PaginationLinkProps) => (
  <a
    aria-current={isActive ? 'page' : undefined}
    className={`ui-pagination-link ${isActive ? 'ui-pagination-link-active' : ''} ${className || ''}`}
    {...props}
  />
)
PaginationLink.displayName = 'PaginationLink'

const PaginationPrevious = ({ className, ...props }: React.ComponentProps<typeof PaginationLink>) => (
  <PaginationLink aria-label='Go to previous page' className={`ui-pagination-previous ${className || ''}`} {...props}>
    <i className='fa-light fa-arrow-left' />
    <span>Previous</span>
  </PaginationLink>
)
PaginationPrevious.displayName = 'PaginationPrevious'

const PaginationNext = ({ className, ...props }: React.ComponentProps<typeof PaginationLink>) => (
  <PaginationLink aria-label='Go to next page' className={`ui-pagination-next ${className || ''}`} {...props}>
    <span>Next</span>
    <i className='fa-light fa-arrow-right' />
  </PaginationLink>
)
PaginationNext.displayName = 'PaginationNext'

const PaginationEllipsis = ({ className, ...props }: React.ComponentProps<'span'>) => (
  <span aria-hidden className={`ui-pagination-ellipsis ${className || ''}`} {...props}>
    <i className='fa-solid fa-ellipsis-h' />
    <span className='ui-sr-only'>More pages</span>
  </span>
)
PaginationEllipsis.displayName = 'PaginationEllipsis'

export {
  Pagination,
  PaginationContent,
  PaginationLink,
  PaginationItem,
  PaginationPrevious,
  PaginationNext,
  PaginationEllipsis,
}
