import Offset from 'polygon-offset'
import { PointT } from '~/models'
import simplify from 'simplify-js'

export function getOffsetPolygon(points: PointT[], factor: number, inMeters = true, inset = false): PointT[] {
  if (points.length === 0) {
    return []
  }

  let pointsToUse = [...points].map((p) => [p.longitude, p.latitude])
  if (inMeters === true) {
    factor /= 111111 * Math.cos((points[0].latitude * Math.PI) / 180)
  }

  pointsToUse = simplify(pointsToUse.map((p) => ({ x: p[0], y: p[1] })), factor).map(p => ([p.x, p.y]))

  const offset = new Offset().data(pointsToUse)
  const margined = inset ? offset.padding(factor) : offset.margin(factor)

  return margined[0].map((p) => {
    return {
      longitude: p[0],
      latitude: p[1],
    }
  })
}

export function insetPolygon(pointsToUse: PointT[], offset: number): PointT[] {
  if (pointsToUse.length < 3) {
    throw new Error("A polygon must have at least three points.");
  }

  let polygon = [...pointsToUse]
  offset /= 111111 * Math.cos((pointsToUse[0].latitude * Math.PI) / 180)

  const EARTH_RADIUS = 6371000; // Earth's radius in meters

  const toRadians = (degrees: number) => (degrees * Math.PI) / 180;
  const toDegrees = (radians: number) => (radians * 180) / Math.PI;

  const movePoint = (point: PointT, bearing: number, distance: number): PointT => {
    const lat1 = toRadians(point.latitude);
    const lon1 = toRadians(point.longitude);
    const angularDistance = distance / EARTH_RADIUS;

    const lat2 = Math.asin(
      Math.sin(lat1) * Math.cos(angularDistance) +
      Math.cos(lat1) * Math.sin(angularDistance) * Math.cos(bearing)
    );

    const lon2 =
      lon1 +
      Math.atan2(
        Math.sin(bearing) * Math.sin(angularDistance) * Math.cos(lat1),
        Math.cos(angularDistance) - Math.sin(lat1) * Math.sin(lat2)
      );

    return {
      latitude: toDegrees(lat2),
      longitude: toDegrees(lon2),
    };
  };

  const insetPoints: PointT[] = [];

  for (let i = 0; i < polygon.length; i++) {
    const prev = polygon[(i - 1 + polygon.length) % polygon.length];
    const curr = polygon[i];
    const next = polygon[(i + 1) % polygon.length];

    const angle1 = Math.atan2(curr.latitude - prev.latitude, curr.longitude - prev.longitude);
    const angle2 = Math.atan2(next.latitude - curr.latitude, next.longitude - curr.longitude);

    const bisectorAngle = (angle1 + angle2) / 2 + Math.PI / 2;

    const insetPoint = movePoint(curr, bisectorAngle, offset);
    insetPoints.push(insetPoint);
  }

  return insetPoints;
}