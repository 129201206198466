import React, { useEffect } from 'react'
import { ReportErrorMutation, ReportErrorMutationVariables } from '~/models'
import REPORT_ERROR_MUTATION from '../components/errors/mutation-report-error.gql'
import { useMutation } from '~/components'
import ASSETI_LOGO from '../images/asseti-logo.svg'
import { useUser } from '~/base'
import { useAppState } from '~/state'

interface CesiumCrashedDialogProps {
  err: string
  extra: object
}

export const CesiumCrashedDialog = (props: CesiumCrashedDialogProps) => {
  const { components, issues } = useAppState()
  const [reportError] = useMutation<ReportErrorMutation, ReportErrorMutationVariables>(REPORT_ERROR_MUTATION)
  const user = useUser()

  useEffect(() => {
    reportError({
      variables: {
        input: {
          url: window.location.href,
          message: 'Cesium crashed ' + user.id,
          stack: JSON.stringify({
            err: props.err,
            extra: props.extra,
            components: {
              draw: components.draw,
              fill: components.fill,
              selectedComponent: !!components.selectedComponent,
              componentToUpdate: !!components.componentToUpdate,
            },
            issues: {
              issue: !!issues.issue,
              issueToUpdate: !!issues.issueToUpdate,
              addingBulk: issues.addingBulk,
              movingBulk: false,
              show: issues.show,
              draw: issues.draw,
            }
          }, null, 2) || 'No stack available',
        },
      },
    })
  }, [])

  return (
    <div className='requires-subscription-popup'>
      <div className='requires-subscription-popup-content higher' style={{ height: '220px', width: '420px' }}>
        <div className='requires-subscription-popup-content-header'>
          <img src={ASSETI_LOGO} alt='Asseti Logo' /> <span>Asseti</span>
        </div>
        <div className='requires-subscription-popup-content-text'>
          <h5>
            An error has occurred and 3D rendering has stopped
            <br />
            <br />
            <span style={{ fontSize: '0.75rem' }}>A report has automatically been sent to Asseti.</span>
          </h5>
        </div>
        <div className='requires-subscription-popup-content-buttons'>
          <button
            className='btn btn-primary'
            onClick={(e) => {
              window.location.reload()
            }}
          >
            Reload Page
          </button>
        </div>
      </div>
      <div className='requires-subscription-popup-overlay'></div>
    </div>
  )
}
