import React from 'react'
import { calculateComponentMeasurement, calculatePolygonArea, classes, handleAreaFormat } from '~/components'
import { formatAnnotations, formatAnnotationType } from '~/annotations/annotations-utils'
import { useAppState } from '~/state'
import { ClientPermission, useUser } from '~/base'
import { SiteQueryComponent, SiteQueryMonitoringZone } from '~/models'

export const EntityContext = () => {
  const user = useUser()
  const { map, components, monitoringZoneState, annotations, entityContext, site, timeline } = useAppState()

  const isDemoSite = site.site?.isDemoSite
  const canUpdateComponent = !isDemoSite && user.hasPermission(ClientPermission.ComponentsUpdate)
  const canRemoveComponent = !isDemoSite && user.hasPermission(ClientPermission.ComponentsRemove)
  const canUpdateAnnotation = !isDemoSite && user.hasPermission(ClientPermission.AnnotationsUpdate)
  const canRemoveAnnotation = !isDemoSite && user.hasPermission(ClientPermission.AnnotationsRemove)
  const canUpdateMonitoringZone = !isDemoSite && user.hasPermission(ClientPermission.MonitoringZonesUpdate)
  const canRemoveMonitoringZone = !isDemoSite && user.hasPermission(ClientPermission.MonitoringZonesRemove)

  const isBulkTagging = map.positionForBulkImageTagging.current
  const isBulkMoving = map.positionForBulkImageMoving.current
  if (!map.initialized || !map.showControls || isBulkTagging || isBulkMoving) {
    return null
  }

  const haveComponent = !!components.selectedComponent
  const showComponentUpdate = canUpdateComponent
  const haveAnnotation = !!annotations.selectedAnnotation
  const haveMZ = !!monitoringZoneState.selectedMonitoringZone
  if (!haveComponent && !haveAnnotation && !haveMZ) {
    return null
  }

  if (haveComponent) {
    let c: SiteQueryComponent
    for (const component of timeline.activeSurvey.components) {
      if (component.id === components.selectedComponent.id) {
        c = component
        break
      }
    }
    if (!c) {
      return null
    }
    return (
      <div className='entity-context'>
        <div className='entity-context-title'>
          <div>
            COMPONENT
            {showComponentUpdate && (
              <button
                className='btn btn-sm btn-secondary'
                onClick={() => {
                  components.setComponentToUpdate(c)
                }}
              >
                Update
              </button>
            )}
            {canRemoveComponent && (
              <button
                className='btn btn-sm btn-secondary'
                onClick={() => {
                  components.setComponentToDelete(c)
                }}
              >
                Remove
              </button>
            )}
          </div>
          <div onClick={entityContext.clear}>
            <span className='material-icons'>close</span>
          </div>
        </div>
        <div className='entity-context-content'>
          <div>
            {c.componentTypeFullName} {c.materialTypeName === '' ? '' : ' - ' + c.materialTypeName}
          </div>
          <div className='component-card-lower'>
            <div
              className={classes({
                'component-card-area': true,
              })}
            >
              {calculateComponentMeasurement(c, user.org.measurementSystem)}
            </div>
            <div
              className={classes({
                'component-card-condition': true,
                [c.condition]: true,
              })}
            >
              {(c.condition || '').replace(/([A-Z])/g, ' $1').trim()}
            </div>
          </div>
        </div>
      </div>
    )
  }

  if (haveAnnotation) {
    const m = annotations.selectedAnnotation
    return (
      <div className='entity-context'>
        <div className='entity-context-title'>
          <div>
            ANNOTATION
            {canUpdateAnnotation && (
              <button
                className='btn btn-sm btn-secondary'
                onClick={() => {
                  annotations.setAnnotationToUpdate(m)
                }}
              >
                Update
              </button>
            )}
            {canRemoveAnnotation && (
              <button
                className='btn btn-sm btn-secondary'
                onClick={() => {
                  annotations.setAnnotationToDelete(m)
                }}
              >
                Remove
              </button>
            )}
          </div>
          <div onClick={entityContext.clear}>
            <span className='material-icons'>close</span>
          </div>
        </div>
        <div className='entity-context-content'>
          <div>{m.name || 'Unnamed'}</div>
          <div className='component-card-lower'>
            <div className='component-card-area'>{formatAnnotationType(m.type)}</div>
            {m.measurements.length > 0 && (
              <div className='component-card-area'>{formatAnnotations(m, user.org.measurementSystem)}</div>
            )}
          </div>
        </div>
      </div>
    )
  }

  if (haveMZ) {
    let m: SiteQueryMonitoringZone
    for (const mz of site.site.monitoringZones) {
      if (mz.id === monitoringZoneState.selectedMonitoringZone.id) {
        m = mz
        break
      }
    }
    if (!m) {
      return null
    }

    const area = handleAreaFormat(
      calculatePolygonArea(m.points.map((p) => Cesium.Cartesian3.fromDegrees(p.longitude, p.latitude, 0)))
    )
    return (
      <div className='entity-context'>
        <div className='entity-context-title'>
          <div>
            MONITORING ZONE
            {canUpdateMonitoringZone && (
              <button
                className='btn btn-sm btn-secondary'
                onClick={() => {
                  monitoringZoneState.setMonitoringZoneToEdit(m)
                }}
              >
                Update
              </button>
            )}
            {canRemoveMonitoringZone && (
              <button
                className='btn btn-sm btn-secondary'
                onClick={() => {
                  monitoringZoneState.setMonitoringZoneToDelete(m)
                }}
              >
                Remove
              </button>
            )}
          </div>
          <div onClick={entityContext.clear}>
            <span className='material-icons'>close</span>
          </div>
        </div>
        <div className='entity-context-content'>
          <div>{m.name || 'Unnamed'}</div>
          <div className='component-card-lower'>
            <div className='component-card-area'>
              {m.targetName === '' ? 'No target' : m.targetName}
              {m.subTargetName === '' ? '' : ' - ' + m.subTargetName}
            </div>
            <div className='component-card-area'>{area}</div>
          </div>
        </div>
      </div>
    )
  }

  return null
}
