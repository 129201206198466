import React, { useState } from 'react'
import './button-toggle.scss'

type ToggleOption = {
  label: string;
  value: string;
};

type ButtonToggleProps = {
  options: ToggleOption[];
  defaultValue?: string;
  onChange?: (value: string) => void;
};

export const ButtonToggle: React.FC<ButtonToggleProps> = ({
                                                            options,
                                                            defaultValue,
                                                            onChange,
                                                          }) => {
  const [activeValue, setActiveValue] = useState(defaultValue || options[0].value)

  const handleClick = (value: string) => {
    setActiveValue(value)
    onChange?.(value)
  }

  return (
    <div className="ui-button-toggle">
      {options.map((option) => (
        <button
          key={option.value}
          className={`ui-button-toggle-option ${
            option.value === activeValue ? 'ui-button-toggle-option-active' : ''
          }`}
          onClick={() => handleClick(option.value)}
        >
          {option.label}
        </button>
      ))}
    </div>
  )
}
