import React from 'react'

type Props = {
  size?: number
}

export const IconAssetiOSWide = ({ size }: Props) => {
  const aspectRatio = 127 / 27
  const width = size * aspectRatio
  const height = size
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox='0 0 127 27' fill='none'>
      <path
        d='M18.9729 23.989L21.0977 20.7277C22.9877 22.0989 24.9766 22.8154 26.6072 22.8154C28.0402 22.8154 28.6949 22.2966 28.6949 21.506V21.4442C28.6949 20.3695 27.0025 20.0113 25.0754 19.4183C22.6295 18.7018 19.85 17.553 19.85 14.1682V14.1064C19.85 10.5487 22.7159 8.55981 26.249 8.55981C28.4725 8.55981 30.8814 9.31336 32.7715 10.5857L30.8814 14.0076C29.152 12.9946 27.4225 12.377 26.1501 12.377C24.9395 12.377 24.3218 12.8958 24.3218 13.5876V13.6493C24.3218 14.6252 25.9895 15.0823 27.8796 15.737C30.3255 16.5524 33.1668 17.7259 33.1668 20.9254V20.9872C33.1668 24.8661 30.2637 26.6326 26.4837 26.6326C23.7536 26.6079 21.11 25.6814 18.9729 23.989Z'
        fill='#1E1B39'
      />
      <path
        d='M33.7476 23.989L35.8723 20.7277C37.7624 22.0989 39.7512 22.8154 41.3942 22.8154C42.8272 22.8154 43.4819 22.2966 43.4819 21.506V21.4442C43.4819 20.3695 41.7895 20.0113 39.8624 19.4183C37.4165 18.7018 34.637 17.553 34.637 14.1682V14.1064C34.637 10.5487 37.5029 8.55981 41.036 8.55981C43.2595 8.55981 45.6684 9.31336 47.5585 10.5857L45.6684 14.0076C43.939 12.9946 42.2095 12.377 40.9371 12.377C39.7265 12.377 39.1089 12.8958 39.1089 13.5876V13.6493C39.1089 14.6252 40.7765 15.0823 42.6666 15.737C45.1125 16.5524 47.9538 17.7259 47.9538 20.9254V20.9872C47.9538 24.8661 45.0508 26.6326 41.2583 26.6326C38.5283 26.6079 35.8847 25.6814 33.7476 23.989Z'
        fill='#1E1B39'
      />
      <path
        d='M48.9543 17.6519V17.5902C48.9543 12.5995 52.5121 8.48584 57.6016 8.48584C63.4447 8.48584 66.113 13.0195 66.113 17.9855C66.113 18.3808 66.0759 18.8378 66.0512 19.2949H53.8833C54.3774 21.5432 55.9463 22.7168 58.1575 22.7168C59.8252 22.7168 61.0234 22.1979 62.3947 20.9255L65.2359 23.4332C63.6053 25.4592 61.2582 26.6945 58.0957 26.6945C52.8456 26.6945 48.9543 23.0009 48.9543 17.6519ZM61.2952 16.1819C60.9987 13.9583 59.6893 12.4636 57.614 12.4636C55.5633 12.4636 54.2168 13.9336 53.8215 16.1819H61.2952Z'
        fill='#1E1B39'
      />
      <path
        d='M69.1765 20.8759V12.0557H66.1252V8.95507H69.1765V3.51965H73.8707V8.95507H77.0084V12.0557H73.8707V19.6406C73.8707 21.6912 74.7354 22.1854 75.7731 22.1854C76.1808 22.1977 76.5761 22.0989 76.9343 21.9012L78.4291 25.706C78.4291 25.706 77.6632 26.299 76.8478 26.509C76.1561 26.6819 75.4519 26.7684 74.7478 26.7561C71.4495 26.7437 69.1765 24.0631 69.1765 20.8759Z'
        fill='#1E1B39'
      />
      <path
        d='M13.3769 8.9552V10.8947C9.67095 8.13989 4.43318 8.90579 1.67841 12.5994C-1.07635 16.293 -0.310452 21.5431 3.38316 24.2979C6.34794 26.5091 10.4121 26.5091 13.3769 24.2979V26.0891H17.8117V8.9552H13.3769ZM8.55916 22.0867C6.08852 22.0867 4.07494 20.0855 4.07494 17.6025C4.07494 15.1195 6.07616 13.1182 8.55916 13.1182C11.0298 13.1182 13.0434 15.1195 13.0434 17.6025C13.0434 20.0731 11.0422 22.0867 8.55916 22.0867Z'
        fill='#1E1B39'
      />
      <path
        d='M125.054 3.74848C125.364 4.02335 125.432 4.53872 125.123 4.84795L122.855 7.1156C122.58 7.39046 122.031 7.35611 121.756 7.1156C120.691 6.11921 119.282 5.63819 117.599 5.63819C112.101 5.63819 111.929 10.1735 117.599 11.0324C122.031 11.6853 125.982 13.6437 125.982 18.7287C125.982 23.264 120.966 26.1158 116.74 26.1158C112.891 26.1158 110.108 25.2224 107.497 23.0235C107.188 22.7143 107.085 22.2333 107.394 21.924L109.662 19.622C109.902 19.3815 110.486 19.3815 110.761 19.622C111.929 20.6528 113.682 21.2369 115.915 21.4087C122.099 21.6492 122.065 16.3236 115.915 15.396C111.861 14.7431 107.875 13.2657 107.875 8.31814C107.875 3.92027 112.651 0.965454 116.602 0.965454C120.519 0.965454 122.512 1.5839 125.054 3.74848Z'
        fill='url(#paint0_linear_2788_8238)'
      />
      <path
        d='M79.6643 13.5406C79.6643 6.42843 85.1273 0.965454 92.2395 0.965454C99.3516 0.965454 104.815 6.42843 104.815 13.5406C104.815 20.6528 99.3516 26.1158 92.2395 26.1158C85.1273 26.1158 79.6643 20.6184 79.6643 13.5406ZM92.2738 21.4087C96.7404 21.4087 99.7983 17.9041 99.7983 13.5406C99.7983 9.14274 96.7404 5.63819 92.2395 5.63819C87.7385 5.63819 84.6806 9.14274 84.6806 13.5406C84.6806 17.9385 87.7385 21.4087 92.2738 21.4087Z'
        fill='url(#paint1_linear_2788_8238)'
      />
      <path
        d='M125.054 3.74848C125.364 4.02335 125.432 4.53872 125.123 4.84795L122.855 7.1156C122.58 7.39046 122.031 7.35611 121.756 7.1156C120.691 6.11921 119.282 5.63819 117.599 5.63819C112.101 5.63819 111.929 10.1735 117.599 11.0324C122.031 11.6853 125.982 13.6437 125.982 18.7287C125.982 23.264 120.966 26.1158 116.74 26.1158C112.891 26.1158 110.108 25.2224 107.497 23.0235C107.188 22.7143 107.085 22.2333 107.394 21.924L109.662 19.622C109.902 19.3815 110.486 19.3815 110.761 19.622C111.929 20.6528 113.682 21.2369 115.915 21.4087C122.099 21.6492 122.065 16.3236 115.915 15.396C111.861 14.7431 107.875 13.2657 107.875 8.31814C107.875 3.92027 112.651 0.965454 116.602 0.965454C120.519 0.965454 122.512 1.5839 125.054 3.74848Z'
        stroke='url(#paint2_linear_2788_8238)'
        strokeWidth='1.22926'
      />
      <path
        d='M79.6643 13.5406C79.6643 6.42843 85.1273 0.965454 92.2395 0.965454C99.3516 0.965454 104.815 6.42843 104.815 13.5406C104.815 20.6528 99.3516 26.1158 92.2395 26.1158C85.1273 26.1158 79.6643 20.6184 79.6643 13.5406ZM92.2738 21.4087C96.7404 21.4087 99.7983 17.9041 99.7983 13.5406C99.7983 9.14274 96.7404 5.63819 92.2395 5.63819C87.7385 5.63819 84.6806 9.14274 84.6806 13.5406C84.6806 17.9385 87.7385 21.4087 92.2738 21.4087Z'
        stroke='url(#paint3_linear_2788_8238)'
        strokeWidth='1.22926'
      />
      <defs>
        <linearGradient
          id='paint0_linear_2788_8238'
          x1='106.062'
          y1='29.1843'
          x2='84.311'
          y2='-2.06694'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.0334644' stopColor='#FF8A00' />
          <stop offset='1' stopColor='#FF0000' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_2788_8238'
          x1='106.062'
          y1='29.1843'
          x2='84.311'
          y2='-2.06694'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.0334644' stopColor='#FF8A00' />
          <stop offset='1' stopColor='#FF0000' />
        </linearGradient>
        <linearGradient
          id='paint2_linear_2788_8238'
          x1='106.062'
          y1='29.1843'
          x2='84.311'
          y2='-2.06694'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.0334644' stopColor='#FF8A00' />
          <stop offset='1' stopColor='#FF0000' />
        </linearGradient>
        <linearGradient
          id='paint3_linear_2788_8238'
          x1='106.062'
          y1='29.1843'
          x2='84.311'
          y2='-2.06694'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.0334644' stopColor='#FF8A00' />
          <stop offset='1' stopColor='#FF0000' />
        </linearGradient>
      </defs>
    </svg>
  )
}
