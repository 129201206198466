import React, { useEffect, useState } from 'react'
import { useAppState } from '~/state'
import { LabelTemplate, pickFromPosition, Point, PointAndLineColor, pointFromPosition } from './utils'
import { PolygonColorOrange } from '../polygon-renderer'
import { BrowserText } from '~/dashboards/graphs/utils'
import { Scheduler, Task } from './task'
import { formatDistance } from './measurement-formats'
import { Config } from '~/config'
import { MeasurementSystem } from '~/models'

interface HeightState {
  src: string
  heights: Array<{
    height: number
    relativeHeight: number
    y: number
  }>
  startX: number
  startY: number
  startPoint: Cesium.Cartographic
  endPoint: Cesium.Cartographic
  deltaX: number
  deltaY: number
}

export const MeasureProfile = () => {
  const { map, components, issues, boundaryState } = useAppState()
  const [points] = useState(new Cesium.PointPrimitiveCollection())
  const [tmpPoints] = useState(new Cesium.PointPrimitiveCollection())
  const [heightState, setHeightState] = useState<HeightState>()
  const [labels] = useState(
    new Cesium.LabelCollection({
      scene: map.viewer.scene,
    })
  )

  useEffect(() => {
    const viewer = map.viewer
    const scene = viewer.scene
    const geodesic = new Cesium.EllipsoidGeodesic()

    // Disable rendering things that could affect the profile.
    const currentComponentDrawState = components.draw
    const currentComponentFillState = components.fill
    components.setDraw(false)
    components.setFill(false)
    const currentIssueState = issues.draw
    issues.setDraw(false)
    const currentBoundaryState = boundaryState.drawIn3D
    boundaryState.setDrawIn3D(false)

    scene.primitives.add(points)
    scene.primitives.add(tmpPoints)
    scene.primitives.add(labels)

    let fillPolygon: Cesium.Entity

    setTimeout(() => {
      const cb = new Cesium.CallbackProperty(() => {
        if (tmpPoints.length !== 2) {
          return new Cesium.PolygonHierarchy([])
        }

        const c1 = Cesium.Cartographic.fromCartesian(tmpPoints.get(0).position)
        const c2 = Cesium.Cartographic.fromCartesian(tmpPoints.get(1).position)
        const c3 = Cesium.Cartographic.fromCartesian(tmpPoints.get(1).position)
        const c4 = Cesium.Cartographic.fromCartesian(tmpPoints.get(0).position)

        const f = 0.00000001

        return new Cesium.PolygonHierarchy([
          Cesium.Cartesian3.fromRadians(c1.longitude - f, c1.latitude - f),
          Cesium.Cartesian3.fromRadians(c2.longitude + f, c2.latitude - f),
          Cesium.Cartesian3.fromRadians(c3.longitude + f, c3.latitude + f),
          Cesium.Cartesian3.fromRadians(c4.longitude - f, c4.latitude + f),
        ])
      }, false)

      fillPolygon = viewer.entities.add({
        polygon: {
          hierarchy: cb,
          material: Cesium.Color.RED,
          classificationType: Cesium.ClassificationType.CESIUM_3D_TILE,
        },
      })

      labels.add({
        ...LabelTemplate,
        position: new Cesium.Cartesian3(0, 0, 0),
      })
    }, 0)

    let point1: Point
    let point2: Point
    let tmpPoint1: Point
    let tmpPoint2: Point
    let computeState = {
      busy: false,
    }

    function getHorizontalDistance(point1: Point, point2: Point): number {
      const point1GeoPosition = Cesium.Cartographic.fromCartesian(point1.position)
      const point2GeoPosition = Cesium.Cartographic.fromCartesian(point2.position)
      geodesic.setEndPoints(point1GeoPosition, point2GeoPosition)
      const meters = geodesic.surfaceDistance
      return meters
    }

    function* computeProfile(task: Task, cb: (result: HeightState) => void) {
      const scalingFactor = 3
      const c1 = Cesium.Cartographic.fromCartesian(point1.position)
      const c2 = Cesium.Cartographic.fromCartesian(point2.position)
      const startPoint = c1 //c1.longitude > c2.longitude ? c2 : c1
      const endPoint = c2 //c1.longitude > c2.longitude ? c1 : c2
      const distance = getHorizontalDistance(point1, point2)
      const timeline = document.getElementsByClassName('timeline')[0] as HTMLDivElement
      const width = timeline.clientWidth
      const paddingX = 60
      const paddingY = 40
      const paddingXHalf = 20
      const numSteps = (width - paddingX * 2)/scalingFactor // Math.ceil(distance / 0.05) // 5CM resolution.
      const resolution = distance / numSteps
      const deltaX = (endPoint.longitude - startPoint.longitude) / numSteps
      const deltaY = (endPoint.latitude - startPoint.latitude) / numSteps

      const heights: Array<{
        height: number
        relativeHeight: number
        y: number
      }> = []

      const label = labels.get(0)
      const ignored = [points.get(0), points.get(1), tmpPoints.get(0), tmpPoints.get(1), fillPolygon, label]

      const result = {
        heights,
        src: '',
        startX: paddingX,
        startY: paddingY,
        deltaX,
        deltaY,
        endPoint,
        startPoint,
      }

      // Set computing label.
      const x = (startPoint.longitude + endPoint.longitude) / 2
      const y = (startPoint.latitude + endPoint.latitude) / 2
      const origin = Cesium.Cartesian3.fromRadians(x, y, 10000)
      const dest = Cesium.Cartesian3.fromRadians(x, y, -10000)
      const ray = new Cesium.Ray(origin, Cesium.Cartesian3.subtract(dest, origin, new Cesium.Cartesian3()))
      const picked = map.viewer.scene.pickFromRay(ray, ignored, 1) as {
        position: Cesium.Cartesian3
        object: object
      }

      if (picked && picked.position) {
        const carto = Cesium.Cartographic.fromCartesian(picked.position)
        label.position = Cesium.Cartesian3.fromRadians(carto.longitude, carto.latitude, carto.height + 3)
        label.text = 'Computing Profile...'
        label.font = '10px Roboto'
        label.pixelOffset = new Cesium.Cartesian2(0, 0)
        label.eyeOffset = new Cesium.Cartesian3(0, 1, 0)
        label.show = true
      }

      for (let i = 0; i < numSteps; i++) {
        const x = startPoint.longitude + deltaX * i
        const y = startPoint.latitude + deltaY * i
        const origin = Cesium.Cartesian3.fromRadians(x, y, 10000)
        const dest = Cesium.Cartesian3.fromRadians(x, y, -10000)
        const ray = new Cesium.Ray(origin, Cesium.Cartesian3.subtract(dest, origin, new Cesium.Cartesian3()))
        const picked = map.viewer.scene.pickFromRay(ray, ignored, resolution) as {
          position: Cesium.Cartesian3
          object: object
        }

        if (picked && picked.position) {
          const height = Cesium.Cartographic.fromCartesian(picked.position).height
          heights.push({
            height,
            relativeHeight: 0,
            y: 0,
          })
        } else {
          if (heights.length > 0) {
            heights.push({ ...heights[heights.length - 1] })
          } else {
            heights.push({
              height: 0,
              relativeHeight: 0,
              y: 0,
            })
          }
        }

        if (i % 4 === 0 && task.switch()) yield result
      }

      const heightValues = heights.map((h) => h.height)
      const minHeightFloor = Math.min(...heightValues)
      const maxHeightCeil = Math.ceil(Math.max(...heightValues))
      const maxHeight = Math.ceil(maxHeightCeil)

      // Set up the canvas.
      const canvas = document.createElement('canvas')
      canvas.width = width
      canvas.height = 300
      const ctx = canvas.getContext('2d')

      // Clear the canvas.
      ctx.fillStyle = '#2b2728'
      ctx.fillRect(0, 0, canvas.width, canvas.height)

      // Draw the grid.
      ctx.strokeStyle = '#474647'
      ctx.fillStyle = '#a9a9a9'
      ctx.lineWidth = 1

      const topY = paddingY
      ctx.beginPath()
      ctx.moveTo(paddingXHalf, topY)
      ctx.lineTo(canvas.width - paddingXHalf, topY)
      ctx.stroke()

      const bottomY = canvas.height - paddingY
      ctx.beginPath()
      ctx.moveTo(paddingXHalf, bottomY)
      ctx.lineTo(canvas.width - paddingXHalf, bottomY)
      ctx.stroke()

      const middleY = canvas.height / 2
      ctx.beginPath()
      ctx.moveTo(paddingXHalf, middleY)
      ctx.lineTo(canvas.width - paddingXHalf, middleY)
      ctx.stroke()

      // Draw the grid text.
      ctx.font = '12px Roboto'
      const av = Math.ceil(maxHeightCeil - minHeightFloor) / 2
      ctx.fillText(formatDistance(0, 0), paddingXHalf + 5, bottomY - 8)
      ctx.fillText(formatDistance(maxHeightCeil - minHeightFloor, 0), paddingXHalf + 5, topY + 20)
      ctx.fillText(formatDistance(av, av % 1 !== 0 ? 1 : 0), paddingXHalf + 5, middleY - 8)

      // Draw the stats text.
      const labelY = topY - 14
      const labelX = paddingXHalf + 5
      const distanceWidth = BrowserText.getWidth('Distance: ' + formatDistance(distance, 2), 12)

      const distanceLabelWidth = BrowserText.getWidth('Distance: ', 12) + 8
      const resolutionLabelWidth = BrowserText.getWidth('Resolution: ', 12) + 8

      const resolutionCM = resolution * 10
      const resolutionLabel =
        Config.DefaultMeasurementSystem === MeasurementSystem.Metric
          ? resolutionCM.toFixed(2) + 'cm'
          : (resolutionCM / 2.54).toFixed(2) + '"'

      ctx.fillText('Distance: ', labelX, labelY)
      ctx.fillText('Resolution: ', labelX + distanceWidth + 24, labelY)

      ctx.fillStyle = '#c9c9c9'
      ctx.fillText(formatDistance(distance, 2), labelX + distanceLabelWidth, labelY)
      ctx.fillText(resolutionLabel, labelX + distanceWidth + 24 + resolutionLabelWidth, labelY)

      // Draw the profile.
      ctx.strokeStyle = PolygonColorOrange

      function mapBetween(currentNum: number, minAllowed: number, maxAllowed: number, min: number, max: number) {
        return ((maxAllowed - minAllowed) * (currentNum - min)) / (max - min) + minAllowed
      }

      for (let i = 1; i < heights.length; i++) {
        const startY = mapBetween(heights[i - 1].height, paddingY, canvas.height - paddingY, minHeightFloor, maxHeight)
        const endY = mapBetween(heights[i].height, paddingY, canvas.height - paddingY, minHeightFloor, maxHeight)

        heights[i].relativeHeight = heights[i].height - minHeightFloor
        heights[i - 1].relativeHeight = heights[i - 1].height - minHeightFloor
        heights[i].y = endY
        heights[i - 1].y = startY
        ctx.beginPath()
        ctx.moveTo((paddingX + i - 1) * scalingFactor, canvas.height - startY)
        ctx.lineTo((paddingX + i) * scalingFactor, canvas.height - endY)
        ctx.stroke()
      }

      result.src = canvas.toDataURL()
      label.show = false
      cb(result)
    }

    function profileTask(cb: (result: HeightState) => void): Task {
      const task = new Task()
      task.setFn(computeProfile(task, cb))
      return task
    }

    function calculateProfile() {
      return new Promise<HeightState>((resolve) => {
        computeState.busy = true

        const scheduler = new Scheduler()
        scheduler.addTask(
          profileTask((res) => {
            scheduler.stop()
            computeState.busy = false
            resolve(res)
          })
        )
        scheduler.run()
      })
    }

    // Mouse over the globe to see the cartographic position
    const leftClickHandler = new Cesium.ScreenSpaceEventHandler(scene.canvas)
    leftClickHandler.setInputAction((click: Cesium.ScreenSpaceEventHandler.PositionedEvent) => {
      if (scene.mode === Cesium.SceneMode.MORPHING || !scene.pickPositionSupported || computeState.busy) {
        return
      }
      pickFromPosition(viewer, click.position, []).then((picked) => {
        const cartesian = picked.cartesian
        if (!Cesium.defined(cartesian)) {
          return
        }

        if (points.length === 2) {
          points.removeAll()
          tmpPoints.removeAll()

          tmpPoint1 = tmpPoints.add(
            pointFromPosition(new Cesium.Cartesian3(cartesian.x, cartesian.y, cartesian.z))
          ) as Point
        }
        //add first point
        if (points.length === 0) {
          point1 = points.add(pointFromPosition(new Cesium.Cartesian3(cartesian.x, cartesian.y, cartesian.z))) as Point
        } //add second point and lines
        else if (points.length === 1) {
          point2 = points.add(pointFromPosition(new Cesium.Cartesian3(cartesian.x, cartesian.y, cartesian.z))) as Point

          calculateProfile().then((res) => {
            setHeightState(res)
          })
        }
      })
    }, Cesium.ScreenSpaceEventType.LEFT_CLICK)

    const mouseMoveHandler = new Cesium.ScreenSpaceEventHandler(scene.canvas)
    mouseMoveHandler.setInputAction((click: Cesium.ScreenSpaceEventHandler.MotionEvent) => {
      if (scene.mode === Cesium.SceneMode.MORPHING || !scene.pickPositionSupported || computeState.busy) {
        return
      }

      if (points.length === 2) {
        tmpPoint1.show = false
        tmpPoint2.show = false
        return
      }

      pickFromPosition(viewer, click.endPosition, []).then((picked) => {
        const cartesian = picked.cartesian
        if (!Cesium.defined(cartesian)) {
          return
        }

        // We haven't added any points yet.
        if (points.length === 0) {
          if (tmpPoints.length === 0) {
            tmpPoint1 = tmpPoints.add(
              pointFromPosition(new Cesium.Cartesian3(cartesian.x, cartesian.y, cartesian.z))
            ) as Point
          } else {
            tmpPoint1.position = new Cesium.Cartesian3(cartesian.x, cartesian.y, cartesian.z)
          }
        }

        // We've added one point.
        if (points.length === 1) {
          if (tmpPoints.length === 1) {
            tmpPoint2 = tmpPoints.add(
              pointFromPosition(new Cesium.Cartesian3(cartesian.x, cartesian.y, cartesian.z))
            ) as Point
          } else {
            tmpPoint2.position = new Cesium.Cartesian3(cartesian.x, cartesian.y, cartesian.z)
          }
        }
      })
    }, Cesium.ScreenSpaceEventType.MOUSE_MOVE)

    function onKeyDown(e: KeyboardEvent) {
      if (e.key === 'Escape') {
        points.removeAll()
        tmpPoints.removeAll()
        setHeightState(undefined)
      }
    }

    window.addEventListener('keydown', onKeyDown, false)

    return () => {
      scene.primitives.remove(points)
      scene.primitives.remove(tmpPoints)
      map.viewer.entities.remove(fillPolygon)
      leftClickHandler.destroy()
      mouseMoveHandler.destroy()
      window.removeEventListener('keydown', onKeyDown)

      components.setDraw(currentComponentDrawState)
      components.setFill(currentComponentFillState)
      issues.setDraw(currentIssueState)
      boundaryState.setDrawIn3D(currentBoundaryState)
    }
  }, [])

  if (heightState) {
    return <ImageVisualizer heightState={heightState} />
  }

  return <></>
}

interface ImageVisualizerProps {
  heightState: HeightState
}

export const ImageVisualizer = (props: ImageVisualizerProps) => {
  const { map } = useAppState()
  const [mouseInside, setMouseInside] = useState(false)
  const [cursor, setCursor] = useState<{
    height: number
    relativeHeight: number
    x: number
    y: number
    lng: number
    lat: number
  }>()
  const [point, setPoint] = useState<Cesium.Entity>()

  useEffect(() => {
    const p = map.viewer.entities.add({
      point: {
        color: PointAndLineColor,
        pixelSize: 12,
      },
      position: new Cesium.Cartesian3(0, 0, 0),
      show: false,
    })

    setPoint(p)

    return () => {
      map.viewer.entities.remove(p)
    }
  }, [])

  function mouseMoved(e: React.MouseEvent) {
    if (!mouseInside) {
      setMouseInside(true)
    }

    const bounds = document.getElementsByClassName('measure-profile-container')[0].getBoundingClientRect()
    let x = e.clientX - bounds.left

    // Constrain X to where the lines are drawn.
    if (x < props.heightState.startX || x > bounds.width - props.heightState.startX) {
      point.show = false
      return
    }

    x -= props.heightState.startX
    if (x < 0 || x >= props.heightState.heights.length) {
      point.show = false
      console.error('image coord out of bounds')
      return
    }

    const lng = props.heightState.startPoint.longitude + props.heightState.deltaX * x
    const lat = props.heightState.startPoint.latitude + props.heightState.deltaY * x

    const value = props.heightState.heights[x]
    const height = value.height
    const y = value.y
    setCursor({
      height,
      relativeHeight: value.relativeHeight,
      x,
      y,
      lng,
      lat,
    })

    if (point) {
      point.show = true
      ;(point as any).position = Cesium.Cartographic.toCartesian(new Cesium.Cartographic(lng, lat, height + 0.15))
    }
  }

  return (
    <div className='measure-profile-container'>
      <div
        onMouseEnter={() => {
          setMouseInside(true)
        }}
        onMouseLeave={() => {
          setMouseInside(false)
        }}
        onMouseMove={mouseMoved}
      >
        <img className='measure-profile-image' src={props.heightState.src} />
        {mouseInside && cursor && (
          <div
            className='measure-profile-cursor'
            style={{
              left: props.heightState.startX + cursor.x,
              bottom: cursor.y - 3,
            }}
          >
            {/* {formatDistance(cursor.relativeHeight, cursor.relativeHeight % 1 === 0 ? 0 : 1)} */}
          </div>
        )}
        {mouseInside && cursor && (
          <div
            className='measure-profile-text'
            style={{
              left: props.heightState.startX + cursor.x - 50,
              bottom: cursor.y + 12,
            }}
            onMouseMove={mouseMoved}
          >
            {formatDistance(cursor.relativeHeight, cursor.relativeHeight % 1 === 0 ? 0 : 2)}
          </div>
        )}
      </div>
    </div>
  )
}
