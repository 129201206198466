import React from 'react'
import { useAppState } from '~/state'
import { Timeline } from './timeline'
import { TimelineLidar } from './timeline-lidar'

export const TimelineContainer = () => {
  const { map, timeline } = useAppState()

  const haveRecons =
    timeline.surveys.length > 0 && timeline.surveys.findIndex((s) => s.reconstructions.length > 0) !== -1

  return (
    <>
      {!map.showLidar && haveRecons && <Timeline />}
      {map.showLidar && timeline.visible && <TimelineLidar />}
    </>
  )
}
