import React from 'react'
import { graphql, ChildProps } from '@apollo/client/react/hoc'
import { ReportErrorMutationVariables } from '~/models'
import { ErrorPage } from './error-page'
import REPORT_ERROR_MUTATION from './mutation-report-error.gql'
import { useIssues } from '~/state/use-issues'
import { useComponentState } from '~/state/use-component-state'
import { useAppState } from '~/state'

interface ErrorBoundaryProps extends ChildProps<any, any, ReportErrorMutationVariables> {
  fallbackComponent?: any
  issues?: ReturnType<typeof useIssues>
  components?: ReturnType<typeof useComponentState>
}

// From https://blog.logrocket.com/error-handling-react-error-boundary/
class ErrorBoundaryImpl extends React.Component<ErrorBoundaryProps, { hasError: boolean; error: Error }> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false, error: undefined }
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error }
  }

  componentDidCatch(error: Error) {
    console.log('Error Boundary:', error)

    import('stacktrace-js').then((StackTrace) => {
      let extra: object = {}
      if (this.props.components && this.props.issues) {
        extra = {
          components: {
            draw: this.props.components.draw,
            fill: this.props.components.fill,
            selectedComponent: !!this.props.components.selectedComponent,
            componentToUpdate: !!this.props.components.componentToUpdate,
          },
          issues: {
            issue: !!this.props.issues.issue,
            issueToUpdate: !!this.props.issues.issueToUpdate,
            addingBulk: this.props.issues.addingBulk,
            movingBulk: false,
            show: this.props.issues.show,
            draw: this.props.issues.draw,
          },
        }
      }

      StackTrace.fromError(error)
        .then((res) => {
          const stack = res.reduce(
            (a, b) => a + `\n\tat ${b.fileName}\t${b.functionName} ${b.lineNumber}:${b.columnNumber}`,
            ''
          )
          this.props.mutate({
            variables: {
              input: {
                url: window.location.href,
                message: error.message || 'No message available',
                stack: JSON.stringify(
                  {
                    stack: stack || 'No stack available',
                    ...extra,
                  },
                  null,
                  2
                ),
              },
            },
          })
        })
        .catch(() => {
          this.props.mutate({
            variables: {
              input: {
                url: window.location.href,
                message: error.message || 'No message available',
                stack: JSON.stringify(
                  {
                    stack: error.stack || 'No stack available',
                    ...extra,
                  },
                  null,
                  2
                ),
              },
            },
          })
        })
    })
  }

  render() {
    if (this.state.hasError) {
      if (this.props.fallbackComponent) {
        const FallbackComponent = this.props.fallbackComponent
        return <FallbackComponent />
      }

      return <ErrorPage />
    }

    return this.props.children
  }
}

export const ErrorBoundary = graphql<ErrorBoundaryProps, any, ReportErrorMutationVariables>(REPORT_ERROR_MUTATION)(
  ErrorBoundaryImpl
)

export const ErrorBoundaryWithAppState = (props: ErrorBoundaryProps) => {
  const { issues, components } = useAppState()
  return (
    <ErrorBoundary fallbackComponent={props.fallbackComponent} issues={issues} components={components}>
      {props.children}
    </ErrorBoundary>
  )
}

window.__cesiumThrowOnDestroyed = (obj: object) => {
  if(!obj || (obj as any).__reportedThrowOnDestroy) {
    return
  }
  try {
    const getCircularReplacer = () => {
      const seen = new WeakSet()
      return (key: string, value: any) => {
        if (typeof value === 'object' && value !== null) {
          if (seen.has(value)) {
            return
          }
          seen.add(value)
        }
        if(Array.isArray(value)) {
          return ''
        }
        return value
      }
    }

    const stack = JSON.stringify(obj, getCircularReplacer(), 2) || 'Could not serialize'

    console.log(stack, typeof obj)

    ;(obj as any).__reportedThrowOnDestroy = true

    window.__apolloClient
      .mutate({
        mutation: REPORT_ERROR_MUTATION,
        variables: {
          input: {
            url: window.location.href,
            message: 'This object was destroyed, i.e., destroy() was called.',
            stack,
          },
        },
      })
      .catch(() => {})
  } catch {}
}
