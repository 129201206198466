import { Modal, ModalBody, ModalHeader } from 'ui/modal'
import { PriorityHeaderTag } from 'ui/priority-header-tag'
import { Row } from 'ui/row'
import { IconButton } from 'ui/icon-button'
import { Input } from 'ui/input'
import { ImageGallery } from 'ui/image-gallery'
import { Column } from 'ui/column'
import { Button } from 'ui/button'
import { Select } from 'ui/select'
import { DatePicker } from 'ui/date-picker'
import { FileUploader } from 'ui/file-uploader'
import React, { useEffect, useState } from 'react'
import { Tabs, TabsContent, TabsList, TabsTrigger } from 'ui/tabs'
import { Currency, MeasurementSystem, OrganizationRole, SupplierRole, SystemRole } from 'models'
import { UploadableFile } from 'ui/files'
import { SlimLoader } from 'ui/slim-loader'
import { MapWithPopover, Popover, PopoverContent, PopoverTrigger } from 'ui/popover'
import { Label } from 'ui/input/label'
import { CostEstimateCard } from 'ui/cost-estimate-card/cost-estimate-card'
import { UserInfo } from 'base'
import { IconAssetiOSWide } from 'ui/icons'
import { Separator } from 'ui/separator/separator'
import { Table, TableBody, TableCell, TableFooter, TableHead, TableHeader, TableRow } from 'ui/table/table'
import { ButtonToggle } from 'ui/button-toggle'
import {
  Pagination,
  PaginationContent,
  PaginationPrevious,
  PaginationItem,
  PaginationLink,
  PaginationEllipsis,
  PaginationNext,
} from 'ui/pagination'

export const Ui = () => {
  const hostname = window.location.hostname
  if (hostname !== 'localhost' && hostname !== 'test.asseti.co') {
    return null
  }

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [selectedDate, setSelectedDate] = useState<Date | null>(null)
  const [selected, setSelected] = useState(null)
  const [uploadedFiles, setUploadedFiles] = useState<UploadableFile[]>([])

  const [modalSize, setModalSize] = useState<'small' | 'medium' | 'large' | 'full'>('small')
  const [modalBackdrop, setModalBackdrop] = useState<'default' | 'glass'>('default')
  const [modalBackground, setModalBackground] = useState<'default' | 'dark'>('default')

  useEffect(() => {
    const interval = setInterval(() => {
      setUploadedFiles((files) =>
        files.map((file) => {
          if (file.uploadState.state === 'loading') {
            const newProgress = Math.min(file.uploadState.progress + Math.random() * 10, 100)
            if (newProgress >= 100) {
              return { ...file, uploadState: { state: 'loaded' } }
            } else {
              return { ...file, uploadState: { state: 'loading', progress: newProgress } }
            }
          }
          return file
        })
      )
    }, 350)

    return () => clearInterval(interval)
  }, [])

  const demoImages = [
    {
      src: 'https://images.unsplash.com/photo-1506748686214-e9df14d4d9d0',
      alt: 'Hipster bra walking in the mountains',
      title: 'Hipster bra walking in the mountains',
    },
    {
      src: 'https://images.unsplash.com/photo-1729508419265-2581beed21bb?q=80&w=1312&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    },
    {
      src: 'https://images.unsplash.com/photo-1727946443877-f6dcad028e39?q=80&w=1170&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    },
    {
      src: 'https://images.unsplash.com/photo-1729558598185-eba72a3353c6?q=80&w=1374&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    },
    {
      src: 'https://images.unsplash.com/photo-1729555906456-f674bcb8a1a9?q=80&w=1374&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    },
    {
      src: 'https://images.unsplash.com/photo-1710446477405-6c6b7c03d3bc?q=80&w=1334&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    },
    {
      src: 'https://images.unsplash.com/photo-1719937050446-a121748d4ba0?q=80&w=1172&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    },
    {
      src: 'https://images.unsplash.com/photo-1729731322022-6b958d019145?q=80&w=1170&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    },
    {
      src: 'https://images.unsplash.com/photo-1729957867644-279d4262e0cf?q=80&w=1170&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    },
    {
      src: 'https://images.unsplash.com/photo-1729912316331-a6b38ee30fd3?q=80&w=1287&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    },
  ]
  const fakeUser: UserInfo & { token: string } = {
    id: ' string',
    firstName: ' string',
    lastName: ' string',
    fullName: ' string',
    email: ' string',
    phoneNumber: ' string',
    systemRole: SystemRole.Client,
    org: {
      canAccessDeflections: true,
      canManageAdvancedAccess: true,
      currency: Currency.Aud,
      measurementSystem: MeasurementSystem.Metric,
      domainName: 'true',
      id: '',
      isBasic: true,
      isIA: true,
      name: '',
      requires2FA: true,
      requiresSubscription: true,
      role: OrganizationRole.Admin,
    },
    orgs: [],
    supplier: {
      name: '',
      role: SupplierRole.Admin,
      id: '',
      requires2FA: false,
    },
    suppliers: [],
    showDemoDashboards: false,
    showDemoSites: false,
    requires2FA: false,
    currentOrgId: 'orgId',
    currentSupplierID: 'supplierId',
    policy: [],
    token: '',
  }

  const existingIssue = (
    <Column spacing={8}>
      <PriorityHeaderTag priority={'high'}>High Priority</PriorityHeaderTag>
      <Input label={'Location'} value={'External'} readOnly />
      <Input label={'Component Type'} value={'External'} readOnly />
      <Row spacing={16}>
        <Input label={'Defect Detected'} value={'Corrosion'} readOnly />
        <Input label={'Defect Date'} value={'2024/10/22'} readOnly />
      </Row>
      <div>
        <hr />
      </div>

      <Label>High Resolution Imagery</Label>

      <ImageGallery images={demoImages} maxImages={3} imagesPerRow={3} />

      <CostEstimateCard cost={1329} />

      <Row spacing={8}>
        <IconButton icon={'fa-light fa-trash'} size={'large'} />
        <Button variant={'ghost'}>Edit</Button>
        <Button variant={'dark'} wrapText={false}>
          Create Work Order
        </Button>
      </Row>
    </Column>
  )
  const newIssue = (
    <Column spacing={16}>
      <Column spacing={0}>
        <h6>Tag an Issue</h6>
        <p>Easily tag and categorise issues or defects to enable tracking and resolution.</p>
        <Button variant={'secondary'}>
          <i className={'fa fa-arrow-pointer'} /> Click to select the issue area/location
        </Button>
      </Column>
      <Column spacing={12}>
        <Select
          placeholder={'Select a location'}
          value={null}
          onChange={() => {
            alert('todo')
          }}
          options={[
            { value: 'internal', label: 'Internal' },
            { value: 'external', label: 'External' },
          ]}
        />
        <Select
          placeholder={'Material/Object Type'}
          value={null}
          onChange={() => {
            alert('todo')
          }}
          options={[
            { value: 'internal', label: 'Internal' },
            { value: 'external', label: 'External' },
          ]}
        />
        <Select
          placeholder={'Issue/Defect Type'}
          value={null}
          onChange={() => {
            alert('todo')
          }}
          options={[
            { value: 'internal', label: 'Internal' },
            { value: 'external', label: 'External' },
          ]}
        />
        <Select
          placeholder={'Priority'}
          value={null}
          onChange={() => {
            alert('todo')
          }}
          options={[
            { value: 'internal', label: 'Internal' },
            { value: 'external', label: 'External' },
          ]}
        />
        <DatePicker />
        <FileUploader
          user={fakeUser}
          files={uploadedFiles}
          setFiles={setUploadedFiles}
          maxSize={100 * 1024 ** 2}
          accept={['jpg', 'jpeg', 'png']}
        />
        <CostEstimateCard cost={1329} />
        <Button>Submit Issue n/ Defect</Button>
      </Column>
    </Column>
  )
  return (
    <>
      <Tabs defaultValue='account' className={'w-100'}>
        <TabsList>
          <TabsTrigger value='form-elements'>Form elements</TabsTrigger>
          <TabsTrigger value='buttons'>Buttons</TabsTrigger>
          <TabsTrigger value='images'>Images</TabsTrigger>
          <TabsTrigger value='modals'>Modals</TabsTrigger>
          <TabsTrigger value='loaders'>Loaders</TabsTrigger>
          <TabsTrigger value='self-tagging'>Self tagging</TabsTrigger>
          <TabsTrigger value='asseti-os'>AssetOS</TabsTrigger>
        </TabsList>
        <TabsContent value='form-elements' className={'w-50'}>
          <Column spacing={16}>
            <Column spacing={16}>
              <p>Easily tag and categorise issues or defects to enable tracking and resolution.</p>
              <Column spacing={32}>
                <Input label={'Input with clear button'} placeholder='Location' value={'External'} clearable />
              </Column>

              <Input label={'Standard input field'} placeholder='Placeholder text is optional' />

              <Input
                label={'Input with helper text'}
                placeholder='Component Type'
                value={'Colorbond Sheeting'}
                helperText={'Some useful helper text here'}
              />

              <Input
                label={'Input in error state'}
                placeholder='Component Type'
                value={'Colorbond Sheeting'}
                error={'Invalid input'}
              />

              <Input
                label={'Input in read-only state'}
                placeholder='Component Type'
                readOnly
                value={'Colorbond Sheeting'}
              />
              <Select
                value={selected}
                onChange={(e) => {
                  setSelected(e)
                }}
                label={'Select dropdown'}
                options={[
                  {
                    value: '1',
                    label: 'Option 1',
                  },
                  {
                    value: '2',
                    label: 'Option 2',
                  },
                  {
                    value: '3',
                    label: 'Option 3',
                    disabled: true,
                  },
                ]}
              />

              <DatePicker
                label='Select Date'
                selectedDate={selectedDate}
                onChange={setSelectedDate}
                placeholder='Choose a date'
                helperText='Please select a date.'
              />
            </Column>

            <FileUploader
              user={fakeUser}
              files={uploadedFiles}
              setFiles={setUploadedFiles}
              maxSize={100 * 1024 ** 2}
              accept={['jpeg', 'png']}
            />

            <Button
              onClick={() => {
                setIsOpen(false)
              }}
            >
              Submit Issue / Defect
            </Button>
          </Column>
        </TabsContent>
        <TabsContent value='buttons' className={'w-50'}>
          <Column spacing={16}>
            <p>Standard buttons</p>
            <Button>Default</Button>
            <Button variant={'primary'}>Primary</Button>
            <Button variant={'secondary'}>Secondary</Button>
            <Button variant={'light'}>Light</Button>
            <Button variant={'ghost'}>Ghost</Button>
            <Button variant={'ghost'}>
              <i className='fa-regular fa-arrow-rotate-right'></i> Ghost With icon
            </Button>

            <p>Button sizes</p>
            <Button>TODO</Button>

            <p> Icon buttons</p>

            <Row spacing={8}>
              <IconButton
                onClick={() => {
                  console.log('')
                }}
                icon={'fa-light fa-trash'}
                size={'large'}
              />
              <IconButton
                color={'green'}
                onClick={() => {
                  console.log('')
                }}
                icon={'fa-light fa-trash'}
                size={'medium'}
              />
              <IconButton
                color={'red'}
                onClick={() => {
                  console.log('')
                }}
                icon={'fa-light fa-trash'}
                size={'small'}
              />
            </Row>
          </Column>
        </TabsContent>
        <TabsContent value='images' className={'w-50'}>
          <ImageGallery imagesPerRow={3} maxImages={6} images={demoImages} />
        </TabsContent>
        <TabsContent value='modals'>
          <Column spacing={16}>
            <Button
              onClick={() => {
                setIsOpen(!isOpen)
              }}
            >
              Show modal
            </Button>

            <Popover>
              <PopoverTrigger asChild>
                <Button variant='secondary'>Open popover</Button>
              </PopoverTrigger>
              <PopoverContent>
                <span>Any content here</span>
              </PopoverContent>
            </Popover>

            <MapWithPopover />
          </Column>
        </TabsContent>
        <TabsContent value='loaders'>
          <Column spacing={16}>
            <Row spacing={16}>
              <Column spacing={16}>
                <p>Progress loader</p>
                <SlimLoader progress={10} height={5} />
                <SlimLoader progress={20} height={10} />
                <SlimLoader progress={30} height={20} />
              </Column>

              <Column spacing={16}>
                <p>Spinners</p>
                <Row spacing={16}>
                  <i className='fas fa-spinner-third fa-fw fa-3x fa-spin' />
                  <i className='fas fa-spinner-third fa-fw fa-2x fa-spin' />
                  <i className='fas fa-spinner-third fa-fw fa-spin' />
                </Row>
              </Column>
            </Row>

            <Row spacing={16}>
              <Column spacing={16}>
                <p>Skeletons</p>
                <p>TODO</p>
              </Column>
            </Row>
          </Column>
        </TabsContent>
        <TabsContent value='self-tagging'>
          <Column spacing={16}>
            <Row spacing={16} alignY={'start'}>
              {existingIssue}
              {newIssue}
              <Column spacing={16}>
                <Popover>
                  <PopoverTrigger asChild>
                    <Button variant='secondary'>Open Existing Issue</Button>
                  </PopoverTrigger>
                  <PopoverContent size={'medium'} align={'start'}>
                    {existingIssue}
                  </PopoverContent>
                </Popover>

                <Popover>
                  <PopoverTrigger asChild>
                    <Button variant='secondary'>Tag new issue</Button>
                  </PopoverTrigger>
                  <PopoverContent size={'medium'} align={'start'}>
                    {newIssue}
                  </PopoverContent>
                </Popover>
              </Column>
            </Row>
          </Column>
        </TabsContent>
        <TabsContent value='asseti-os'>
          <Column spacing={16}>
            <Row spacing={16} alignX={'stretch'}>
              <IconAssetiOSWide size={35} />
              <Button variant={'secondary'}>💡Guide / Walkthrough</Button>
            </Row>
            <p>
              Unlock the power of your asset data with assetOS. Lorem ipsum dolor sit amet, consectetur adipiscing elit,
              sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
              exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
              cupidatat non proident.
            </p>

            <Tabs>
              <TabsList>
                <TabsTrigger value='to-do'>To do</TabsTrigger>
                <TabsTrigger value='sites'>Sites</TabsTrigger>
                <TabsTrigger value='issues'>Issues</TabsTrigger>
                <Separator orientation='vertical' />
                <TabsTrigger value='budget'>Budget</TabsTrigger>
                <TabsTrigger value='work-orders'>Work Orders</TabsTrigger>
                <TabsTrigger value='vendors'>Vendors</TabsTrigger>
                <Separator orientation='vertical' />
              </TabsList>
              <TabsContent value='to-do'>
                <Column spacing={24}>
                  {/*TODO container border*/}
                  <div style={{ border: 1, borderColor: 'red' }}>
                    <Column spacing={16}>
                      <Row spacing={16} alignY={'center'}>
                        <Row spacing={16}>
                          <h5 className={'m-0'}>To do</h5>
                          <ButtonToggle
                            options={[
                              { label: 'List View', value: 'list' },
                              { label: 'Board View', value: 'board' },
                            ]}
                            defaultValue='list'
                            onChange={() => {}}
                          />
                        </Row>
                        <Row spacing={16} alignX={'end'}>
                          <div style={{ width: 350 }}>
                            <Input icon={<i className='fa-solid fa-magnifying-glass'></i>} placeholder='Search' />
                          </div>
                          <IconButton
                            onClick={() => {
                              console.log('')
                            }}
                            icon={'fa-light fa-filter-list'}
                          />
                          <Button variant={'dark'} wrapText={false}>
                            <i className='fa-regular fa-plus' /> Add Task
                          </Button>
                          <IconButton
                            onClick={() => {
                              console.log('')
                            }}
                            icon={'fa-light fa-ellipsis-vertical'}
                          />
                        </Row>
                      </Row>

                      <Table>
                        <TableHeader>
                          <TableRow>
                            <TableHead>Invoice</TableHead>
                            <TableHead>
                              Status <i className='fa-regular fa-arrow-down'></i>
                            </TableHead>
                            <TableHead>Method</TableHead>
                            <TableHead className='text-right'>Amount</TableHead>
                          </TableRow>
                        </TableHeader>
                        <TableBody>
                          <TableRow>
                            <TableCell className='font-medium'>INV001</TableCell>
                            <TableCell>Paid</TableCell>
                            <TableCell>Credit Card</TableCell>
                            <TableCell className='text-right'>$250.00</TableCell>
                          </TableRow>
                        </TableBody>
                        <TableFooter>
                          <TableRow>
                            <TableCell colSpan={3}>Total</TableCell>
                            <TableCell className='text-right'>$2,500.00</TableCell>
                          </TableRow>
                        </TableFooter>
                      </Table>
                    </Column>
                  </div>
                  <Pagination>
                    <PaginationContent>
                      <PaginationPrevious href='#prev' />
                      <PaginationItem>
                        <PaginationLink href='#1' isActive>
                          1
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem>
                        <PaginationLink href='#2'>2</PaginationLink>
                      </PaginationItem>
                      <PaginationItem>
                        <PaginationLink href='#3'>3</PaginationLink>
                      </PaginationItem>
                      <PaginationEllipsis />
                      <PaginationItem>
                        <PaginationLink href='#10'>10</PaginationLink>
                      </PaginationItem>
                      <PaginationNext href='#next' />
                    </PaginationContent>
                  </Pagination>
                </Column>
              </TabsContent>
            </Tabs>
          </Column>
        </TabsContent>
      </Tabs>

      <Modal
        size={modalSize}
        backdrop={modalBackdrop}
        background={modalBackground}
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false)
        }}
      >
        <ModalHeader>
          <h5>Modal Header</h5>
        </ModalHeader>

        <ModalBody>
          <p>Modal Size: {modalSize}</p>
          <p>Modal backdrop: {modalBackdrop}</p>
          <p>Modal background: {modalBackground}</p>
          <Column spacing={16}>
            <Button
              onClick={() => {
                setModalSize((size) => {
                  switch (size) {
                    case 'small':
                      return 'medium'
                    case 'medium':
                      return 'large'
                    case 'large':
                      return 'full'
                    case 'full':
                      return 'small'
                  }
                })
              }}
            >
              Toggle Modal Size
            </Button>

            <Button
              onClick={() => {
                setModalBackdrop((backdrop) => {
                  switch (backdrop) {
                    case 'default':
                      return 'glass'
                    case 'glass':
                      return 'default'
                  }
                })
              }}
            >
              Toggle modal backdrop
            </Button>

            <Button
              onClick={() => {
                setModalBackground((background) => {
                  switch (background) {
                    case 'default':
                      return 'dark'
                    case 'dark':
                      return 'default'
                  }
                })
              }}
            >
              Toggle modal background{' '}
            </Button>

            <Button
              onClick={() => {
                setIsOpen(false)
              }}
              variant={'secondary'}
            >
              Close modal
            </Button>
          </Column>
        </ModalBody>
      </Modal>
    </>
  )
}
