import { UserInfo } from '~/base'
import { Currency } from '~/models'

export const handleCurrencyFormat = (number: number, user: UserInfo) => {
  if (user.org.currency === Currency.Usd) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: Currency.Usd,
      notation: 'compact',
      compactDisplay: 'short',
    })
      .format(number / 100 * 0.6)
      .replace('ZAR', 'R')
  }
  
  if (user.org.currency === Currency.Zar) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      notation: 'compact',
      compactDisplay: 'short',
    })
      .format(number / 100)
      .replace('$', 'R')
  } else if (user.org.currency === Currency.Aud) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'AUD',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      notation: 'compact',
      compactDisplay: 'short',
    })
      .format(number / 100)
      .replace('A', '')
  } else if (user.org.currency === Currency.Nzd) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'NZD',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      notation: 'compact',
      compactDisplay: 'short',
    })
      .format(number / 100)
      .replace('NZ', '')
  }

  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: user.org.currency,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    notation: 'compact',
    compactDisplay: 'short',
  }).format(number / 100)
}

export const handleCurrencyFormatFull = (number: number, user: UserInfo) => {
  if (user.org.currency === Currency.Usd) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: Currency.Usd,
    })
      .format(number / 100 * 0.6)
      .replace('ZAR', 'R')
  }

  if (user.org.currency === Currency.Zar) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    })
      .format(number / 100)
      .replace('$', 'R')
  } else if (user.org.currency === Currency.Aud) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    })
      .format(number / 100)
      .replace('A', '')
  } else if (user.org.currency === Currency.Nzd) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    })
      .format(number / 100)
      .replace('NZ', '')
  }


  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: user.org.currency,
  })
    .format(number / 100)
    .replace('ZAR', 'R')
}

export function getCurrencyPrefix(user: UserInfo) {
  switch (user.org.currency) {
    case Currency.Aud:
      return '$'
    case Currency.Nzd:
      return '$'
    case Currency.Usd:
      return '$'
    case Currency.Eur:
      return '€'
    case Currency.Gbp:
      return '£'
    case Currency.Zar:
      return 'R'
  }
}

export function formatCurrency(number: number, fractionDigits: number = 0, currency: Currency = Currency.Aud) {
  const base: Intl.NumberFormatOptions = {
    style: 'currency',
    notation: 'compact',
    compactDisplay: 'short',
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  }

  if (currency === Currency.Zar) {
    return new Intl.NumberFormat('en-US', {
      ...base,
      currency: 'USD',
    })
      .format(number / 100)
      .replace('$', 'R')
  } else if (currency === Currency.Aud) {
    return new Intl.NumberFormat('en-US', {
      ...base,
      currency: 'AUD',
    })
      .format(number / 100)
      .replace('A', '')
  } else if (currency === Currency.Nzd) {
    return new Intl.NumberFormat('en-US', {
      ...base,
      currency: 'NZD',
    })
      .format(number / 100)
      .replace('NZ', '')
  }

  return new Intl.NumberFormat('en-US', {
    ...base,
    currency: currency,
  }).format(number / 100)
}

export function formatCurrencyFull(number: number) {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'AUD',
  })
    .format(number / 100)
    .replace('A', '')
}
