import 'regenerator-runtime/runtime'
import { Home } from './home'
import { AppStateProvider } from '~/state'
import { useAuthStatus, UserStateProvider, useUser } from './base'
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom'

import { ApolloProvider } from '@apollo/client'
import { AuthSwitch } from './auth/auth-switch'
import { ErrorBoundaryWithAppState, Helmet } from '~/components'
import { MobileNotification } from './auth/auth-mobile-notification'
import { NotFoundPage } from './404'
import React, { useEffect, useState } from 'react'
import { SystemRole } from './models'
import dayjs from 'dayjs'
import { initClient } from './apollo'
import relativeTime from 'dayjs/plugin/relativeTime'
import { ErrorBoundary } from './components'
import { AuthAcceptInvite } from './auth/auth-accept-invite'
import { AuthEmailConfirmationSuccess } from './auth/auth-email-confirmation-success'
import { AuthPreConfirmation } from './auth/auth-pre-confirmation'
import { AuthResendConfirmation } from './auth/auth-resend-confirmation'
import { AuthResetPassword } from './auth/auth-reset-password'
import { AuthSignIn } from './auth/auth-sign-in'
import { AuthSignUp } from './auth/auth-sign-up'
import { AuthSignedOut } from './auth/auth-signed-out'
import { AuthUpdatePassword } from './auth/auth-update-password'
import { AuthSwitchOrg } from './auth/auth-switch-org'
import { AuthCompleteSignIn } from './auth/auth-complete-sign-in'
import { PhotoDownload } from './account/photo-download'
import { AuthSignUpSupplier } from './auth/auth-sign-up-supplier'
import { AuthAcceptSupplierInvite } from './auth/auth-accept-supplier-invite'
import { AuthReactivate } from './auth/auth-reactivate'
import { InstantAssessContainer } from './instant-assess'
import { createRoot } from 'react-dom/client'
import { MantineProvider } from '@mantine/core'
import './ui/_globals.scss'
import { Ui } from 'ui/ui'

dayjs.extend(relativeTime)

let AdminImpl: React.FC = undefined

const Admin = () => {
  useAuthStatus()
  const [loading, setLoading] = useState(AdminImpl === undefined)

  useEffect(() => {
    if (loading) {
      import('./admin/admin').then((res) => {
        AdminImpl = res.default
        setLoading(false)
      })
    }
  }, [loading])

  if (AdminImpl) {
    return <AdminImpl />
  }

  return <div></div>
}

let DashboardsImpl: React.FC = undefined

const Dashboards = () => {
  useAuthStatus()
  const [loading, setLoading] = useState(DashboardsImpl === undefined)

  useEffect(() => {
    if (loading) {
      import('./dashboards/dashboards').then((res) => {
        DashboardsImpl = res.default
        setLoading(false)
      })
    }
  }, [loading])

  if (DashboardsImpl) {
    return <DashboardsImpl />
  }

  return <div></div>
}

let SupplierImpl: React.FC = undefined

const Supplier = () => {
  useAuthStatus()
  const [loading, setLoading] = useState(SupplierImpl === undefined)

  useEffect(() => {
    if (loading) {
      import('./supplier/supplier').then((res) => {
        SupplierImpl = res.default
        setLoading(false)
      })
    }
  }, [loading])

  if (SupplierImpl) {
    return <SupplierImpl />
  }

  return <div></div>
}

const Main = () => {
  const user = useUser()
  const urlParams = new URLSearchParams(window.location.search)
  const switchingToken = urlParams.get('st')
  const authToken = urlParams.get('t')
  const orgId = urlParams.get('id')
  const supplierId = urlParams.get('sid')

  if (window.innerWidth < 650) {
    return <MobileNotification />
  } else {
    if ((orgId || supplierId) && typeof switchingToken === 'string') {
      return <AuthSwitch switchingToken={switchingToken} orgId={orgId} supplierId={supplierId} />
    }

    if ((orgId || supplierId) && typeof authToken === 'string' && window.location.href.includes('/auth')) {
      return <AuthSwitchOrg token={authToken} orgId={orgId} supplierId={supplierId} />
    }

    const isOnInstantAssess = window.location.pathname.includes('/instant-assess')
    if (!user.isLoggedIn && !isOnInstantAssess) {
      const validRoutes = [
        '/signin',
        '/complete-sign-in',
        '/signup',
        '/reset-password',
        '/resend-confirmation',
        '/email-confirmation',
        '/email-confirmation-success',
        '/update-password',
        '/signed-out',
        '/accept-invite',
        '/404',
      ]

      if (!validRoutes.find((x) => window.location.pathname.startsWith(x))) {
        if (window.location.pathname !== '/') {
          window.location.href = '/signin?r=' + window.location.pathname
        } else {
          window.location.pathname = '/signin'
        }
      }

      return null
    }

    if (window.location.href.includes('/dashboards')) {
      return <Dashboards />
    }

    if (!user.loaded && !isOnInstantAssess) {
      return null
    }

    if (user.systemRole === SystemRole.Staff && !user.currentOrgId && !user.currentSupplierID) {
      return <Admin />
    }

    if (user.currentSupplierID) {
      return <Supplier />
    }

    if (
      (!Array.isArray(user.policy) || user.policy.length !== 4 || user.policy === undefined) &&
      !!user.currentOrgId &&
      !isOnInstantAssess
    ) {
      window.location.pathname = '/signed-out'
      return null
    }

    if (isOnInstantAssess) {
      return <InstantAssessContainer />
    }

    return (
      <AppStateProvider>
        <ErrorBoundaryWithAppState>
          <Home />
        </ErrorBoundaryWithAppState>
      </AppStateProvider>
    )
  }
}

const Root = () => {
  return (
    <MantineProvider>
      <BrowserRouter>
        <Helmet defaultTitle='Asseti | Asset intelligence at scale' titleTemplate='%s | Asseti' />
        <ApolloProvider client={initClient()}>
          <UserStateProvider>
            <ErrorBoundary>
              <LocationAwareRoutes />
            </ErrorBoundary>
          </UserStateProvider>
        </ApolloProvider>
      </BrowserRouter>
    </MantineProvider>
  )
}

const LocationAwareRoutes = () => {
  const location = useLocation()

  useEffect(() => {
    if (!window.ga) {
      return
    }

    window.ga('set', 'page', location.pathname)
    window.ga('send', 'pageview')
  }, [location])

  useEffect(() => {
    removeLoader()
  }, [])

  return (
    <Routes>
      <Route path='/signin' element={<AuthSignIn />} />
      <Route path='/signup' element={<AuthSignUp />} />
      <Route path='/signup-supplier' element={<AuthSignUpSupplier />} />
      <Route path='/reset-password' element={<AuthResetPassword />} />
      <Route path='/resend-confirmation' element={<AuthResendConfirmation />} />
      <Route path='/email-confirmation' element={<AuthPreConfirmation />} />
      <Route path='/email-confirmation-success' element={<AuthEmailConfirmationSuccess />} />
      <Route path='/update-password' element={<AuthUpdatePassword />} />
      <Route path='/signed-out' element={<AuthSignedOut />} />
      <Route path='/accept-invite' element={<AuthAcceptInvite />} />
      <Route path='/accept-supplier-invite' element={<AuthAcceptSupplierInvite />} />
      <Route path='/complete-sign-in' element={<AuthCompleteSignIn />} />
      <Route path='/reactivate' element={<AuthReactivate />} />
      <Route path='/404' element={<NotFoundPage />} />
      <Route path='/photo' element={<PhotoDownload />} />
      <Route path='/ui' element={<Ui />} />
      <Route path='*' element={<Main />} />
    </Routes>
  )
}

;(window as any).allowHubspot = true
const container = document.getElementById('root')
const root = createRoot(container)
root.render(<Root />)

window.__assetiWorker = window.Worker

function removeLoader() {
  const loader = document.getElementById('full-page-loader')
  if (loader) {
    loader.remove()
  }
  setTimeout(() => {
    container.style.opacity = '1'
  }, 0)
}