import React, { useState } from 'react'
import { ClientPermission, useUser } from '~/base'
import { classes, DrawerState, notReachable, useLocation, useMutation } from '~/components'
import LOGO from '~/images/asseti_icon_red.png'
import ALERTS from '~/images/main-bar/Alerts.svg'
import AUTOMATION from '~/images/main-bar/Automation.svg'
import FORECAST from '~/images/main-bar/Forecast.svg'
import MESSAGING from '~/images/main-bar/Messaging.svg'
import VENDOR from '~/images/main-bar/Vendor.svg'
import WORKORDER from '~/images/main-bar/Workorder.svg'
import { FeatureRequestMutation, FeatureRequestMutationVariables } from '~/models'
import FEATURE_REQUEST from './feature-request.gql'

import { useAppState } from '~/state'
import { ViewStateType } from '~/state/use-drawer-state'

enum PopoutType {
  None,
  Forecasting,
  WorkOrders,
  VendorPortal,
  Messaging,
  Automation,
  Alerts,
}

export const MainBar = () => {
  const { drawer, issues } = useAppState()
  const user = useUser()

  let showForecasting = user.hasPermission(ClientPermission.DashboardsList) && !user.org.isBasic
  if (
    user.currentOrgId === '01FP9D6098B274FG92W3MS6JFM' ||
    user.currentOrgId === '01GH7MJXR83WYFSJA7QQY2FJ8E' ||
    user.currentOrgId === '01F5FGNHAW61N3W8PE2G51CYNB' ||
    user.currentOrgId === '01HWVS3FM1DA0J7811NBFF07BF' ||
    user.currentOrgId === '01J842KV2T78S9YN8XFF55JQGT'
  ) {
    showForecasting = false
  }

  if (issues.addingBulk || (drawer.leftClosed && drawer.rightClosed)) {
    return null
  }

  return (
    <MainBarImpl
      hideForecasting={!showForecasting}
      onMessage={(msg) => {
        switch (msg.type) {
          case 'account_clicked':
            if (drawer.rightView === ViewStateType.Account) {
              drawer.setRightView(ViewStateType.Activity)
              drawer.set(DrawerState.Normal, DrawerState.Normal)
            } else {
              drawer.setRightView(ViewStateType.Account)
              drawer.rightExpanded ? null : drawer.toggleRight()
              drawer.rightClosed ? drawer.set(drawer.left, DrawerState.Expanded) : null
            }
            break
          case 'help_clicked':
            if (drawer.rightView === ViewStateType.Support) {
              drawer.setRightView(ViewStateType.Activity)
            } else {
              drawer.setRightView(ViewStateType.Support)
            }
            break
          case 'upload_clicked':
            if (drawer.uploadOpen) {
              drawer.setUploadOpen(false)
            } else {
              drawer.setUploadOpen(true)
            }
            break
          default:
            notReachable(msg)
        }
      }}
    />
  )
}

type MainBarMessage = { type: 'help_clicked' } | { type: 'account_clicked' } | { type: 'upload_clicked' }

interface MainBarImplProps {
  hideForecasting?: boolean
  onMessage?: (msg: MainBarMessage) => void
}

export const MainBarImpl = (props: MainBarImplProps) => {
  const location = useLocation()
  const [popout, setPopout] = useState<PopoutType>(PopoutType.None)

  return (
    <>
      <div className='main-bar'>
        <div className='main-bar-logo'>
          <img src={LOGO} />
        </div>
        <div className='main-bar-nav'>
          <MainBarNavItem
            name='Portfolio'
            icon='fa-solid fa-apartment'
            active={popout === PopoutType.None}
            onClick={() => {
              location.setLocation('/')
              setPopout(PopoutType.None)
            }}
          />
          <MainBarNavItem
            name='Insights'
            icon='fa-solid fa-chart-simple'
            onClick={() => {
              location.setLocation('/dashboards')
              setPopout(PopoutType.None)
            }}
          />
          {!props.hideForecasting && (
            <MainBarNavItem
              name='Forecasts'
              icon='fa-solid fa-chart-mixed-up-circle-currency'
              active={popout === PopoutType.Forecasting}
              onClick={() => {
                setPopout(PopoutType.Forecasting)
              }}
            />
          )}
          <MainBarNavItem
            name='Work Orders'
            icon='fa-solid fa-list-check'
            active={popout === PopoutType.WorkOrders}
            onClick={() => {
              setPopout(PopoutType.WorkOrders)
            }}
          />
          <MainBarNavItem
            name='Vendors'
            icon='fa-solid fa-user-group'
            active={popout === PopoutType.VendorPortal}
            onClick={() => {
              setPopout(PopoutType.VendorPortal)
            }}
          />
          <MainBarNavItem
            name='Messages'
            icon='fa-solid fa-messages'
            active={popout === PopoutType.Messaging}
            onClick={() => {
              setPopout(PopoutType.Messaging)
            }}
          />
          <MainBarNavItem
            name='Automation'
            icon='fa-solid fa-layer-group'
            active={popout === PopoutType.Automation}
            onClick={() => {
              setPopout(PopoutType.Automation)
            }}
          />
        </div>
        <div className='main-bar-spacer'></div>
        <div className='main-bar-nav'>
          <MainBarNavItem
            name='Alerts'
            icon='fa-regular fa-bell'
            active={popout === PopoutType.Alerts}
            onClick={() => {
              setPopout(PopoutType.Alerts)
            }}
          />
          <MainBarNavItem
            name='Upload'
            icon='fa-light fa-cloud-arrow-up'
            onClick={() => {
              props.onMessage({ type: 'upload_clicked' })
            }}
          />
          <MainBarNavItem
            name='Help'
            icon='fa-light fa-circle-question'
            onClick={() => {
              props.onMessage({ type: 'help_clicked' })
            }}
          />
          <MainBarNavItem
            name='Account'
            icon='fa-light fa-circle-user'
            onClick={() => {
              props.onMessage({ type: 'account_clicked' })
            }}
          />
        </div>
      </div>

      {popout === PopoutType.Forecasting && <MainBarPopoutForecasting clickAway={() => setPopout(PopoutType.None)} />}
      {popout === PopoutType.WorkOrders && <MainBarPopoutWorkOrders clickAway={() => setPopout(PopoutType.None)} />}
      {popout === PopoutType.VendorPortal && <MainBarPopoutVendors clickAway={() => setPopout(PopoutType.None)} />}
      {popout === PopoutType.Messaging && <MainBarPopoutMessaging clickAway={() => setPopout(PopoutType.None)} />}
      {popout === PopoutType.Automation && <MainBarPopoutAutomation clickAway={() => setPopout(PopoutType.None)} />}
      {popout === PopoutType.Alerts && <MainBarPopoutAlerts clickAway={() => setPopout(PopoutType.None)} />}
    </>
  )
}

interface MainBarNavItemProps {
  name: string
  icon: string
  active?: boolean
  onClick?: () => void
}

const MainBarNavItem = (props: MainBarNavItemProps) => {
  return (
    <div
      className={classes({
        'main-bar-nav-item': true,
        active: props.active,
      })}
      onClick={props.onClick}
    >
      <div>
        <span className={props.icon}></span>
      </div>
      <div>{props.name}</div>
    </div>
  )
}

interface MainBarPopoutProps {
  title: string
  icon?: string
  children?: any
  clickAway: () => void
}

const MainBarPopout = (props: MainBarPopoutProps) => {
  const [executeFeatureRequest] = useMutation<FeatureRequestMutation, FeatureRequestMutationVariables>(FEATURE_REQUEST)
  const [executing, setExecuting] = useState(false)
  const [executed, setExecuted] = useState(false)

  return (
    <>
      <div
        className='main-bar-popout-cover'
        style={{ top: 0, bottom: 0, right: 0, left: 0 }}
        onClick={props.clickAway}
      ></div>
      <div className='main-bar-popout'>
        <div className='main-bar-popout-icon'>
          <img src={props.icon} />
        </div>
        <div className='main-bar-popout-title'>
          <span>{props.title}</span> coming soon
        </div>
        {props.children}
        <div className='main-bar-popout-button'>
          <button
            className='btn btn-main-bar-popout'
            onClick={(e) => {
              e.preventDefault()
              setExecuted(false)
              setExecuting(true)
              executeFeatureRequest({
                variables: {
                  input: {
                    type: props.title,
                  },
                },
              })
                .then(() => {
                  setExecuted(true)
                })
                .finally(() => {
                  setExecuting(false)
                })
            }}
          >
            {!executed && !executing && 'Click here to join the waitlist'}
            {executing && 'Joining the waitlist'}
            {executed && "You've joined the waitlist!"}
          </button>
        </div>
      </div>
    </>
  )
}

interface MainBarPopoutItemProps {
  clickAway: () => void
}

const MainBarPopoutForecasting = (props: MainBarPopoutItemProps) => {
  return (
    <MainBarPopout title='Forecasting' icon={FORECAST} clickAway={props.clickAway}>
      Our Forecasting feature will transform your CapEx and budget planning, offering predictive insights to help you
      plan future expenses, allocate resources more effectively, and maximize your financial strategy
    </MainBarPopout>
  )
}

const MainBarPopoutWorkOrders = (props: MainBarPopoutItemProps) => {
  return (
    <MainBarPopout title='Work Orders' icon={WORKORDER} clickAway={props.clickAway}>
      Streamlines the management of all asset-related tasks. Easily track progress from request to completion, ensuring
      efficient workflows and seamless coordination across your entire portfolio
    </MainBarPopout>
  )
}

const MainBarPopoutVendors = (props: MainBarPopoutItemProps) => {
  return (
    <MainBarPopout title='Vendor Portal' icon={VENDOR} clickAway={props.clickAway}>
      Streamlines the management of all asset-related tasks. Easily track progress from request to completion, ensuring
      efficient workflows and seamless coordination across your entire portfolio
    </MainBarPopout>
  )
}

const MainBarPopoutMessaging = (props: MainBarPopoutItemProps) => {
  return (
    <MainBarPopout title='Messaging' icon={MESSAGING} clickAway={props.clickAway}>
      Effortlessly communicate and track all conversations with contractors and vendors in one place. Keep your team in
      sync, ensuring nothing gets lost and every task stays on track.
    </MainBarPopout>
  )
}

const MainBarPopoutAutomation = (props: MainBarPopoutItemProps) => {
  return (
    <MainBarPopout title='Automation' icon={AUTOMATION} clickAway={props.clickAway}>
      Automate your workflows and set custom rules to streamline processes, saving time and ensuring faster outcomes.
      From task assignments to notifications, let automation do the heavy lifting while you focus on strategic
      decisions.
    </MainBarPopout>
  )
}

const MainBarPopoutAlerts = (props: MainBarPopoutItemProps) => {
  return (
    <MainBarPopout title='Alerts' icon={ALERTS} clickAway={props.clickAway}>
      From tags and job status changes to weather warnings, our system keeps you informed in real-time, ensuring you're
      always in control and able to respond swiftly to any changes or risks.
    </MainBarPopout>
  )
}
