import { Config } from '~/config'

export function getTileProxy(orgID: string, cesiumID: string, useAligned: boolean): Cesium.Resource {
  const res = new Cesium.Resource({
    url: `${Config.BaseFileUrlWithSubdomains}${orgID}/${useAligned ? '3d-tiles-aligned' : '3d-tiles'}/${cesiumID}/${
      useAligned ? 'tileset' : 'Production_1'
    }.json?${orgID === Config.DemoOrgID ? window.__asseti_demo_qs : window.__asseti_data_qs}`,
    //  url: `http://localhost:5000/tileset.json`,
    proxy: new (class {
      private index = 0
      getURL(resource: string): string {
        if (resource.includes('Production_1.json') || resource.includes('tileset.json')) {
          return resource.replace('{s}', 'a')
        }

        const b3dmIndex = resource.indexOf('.b3dm')
        if (b3dmIndex !== -1) {
          const parts = resource.substring(0, b3dmIndex).split('_')
          const finalPart = parts[parts.length - 1].replace(/\D/g, '')
          const i = parseInt(finalPart, 10)
          return resource.replace('{s}', ['a', 'b', 'c', 'd'][i % 4])
        }

        const glbIndex = resource.indexOf('.glb')
        if (glbIndex !== -1) {
          const parts = resource.substring(0, glbIndex).split('_')
          const finalPart = parts[parts.length - 1].replace(/\D/g, '')
          if (parts.length === 1) {
            const i = parseInt(finalPart, 10)
            return resource.replace('{s}', ['a', 'b', 'c', 'd'][i % 4])
          }
          const secondLastPart = parts[parts.length - 2].replace('L', '')
          const t = parseInt(secondLastPart, 10)
          let toAdd = 0
          if (typeof t === 'number' && !isNaN(t)) {
            toAdd = t * 10000
          }
          const i = toAdd + parseInt(finalPart, 10)
          return resource.replace('{s}', ['a', 'b', 'c', 'd'][i % 4])
        }

        return resource.replace('{s}', ['a', 'b', 'c', 'd'][this.index++ % 4])
      }
    })(),
  })

  return res
}

export function getLidarProxy(orgID: string, lidarID: string): Cesium.Resource {
  const res = new Cesium.Resource({
    url: `${Config.BaseFileUrlWithSubdomains}${orgID}/lidar-tiles/${lidarID}/tileset.json?${
      orgID === Config.DemoOrgID ? window.__asseti_demo_qs : window.__asseti_data_qs
    }`,
    proxy: new (class {
      private index = 0
      getURL(resource: string): string {
        if (resource.includes('tileset.json')) {
          return resource.replace('{s}', 'a')
        }

        const b3dmIndex = resource.indexOf('.b3dm')
        if (b3dmIndex !== -1) {
          const parts = resource.substring(0, b3dmIndex).split('_')
          const finalPart = parts[parts.length - 1].replace(/\D/g, '')
          const i = parseInt(finalPart, 10)
          return resource.replace('{s}', ['a', 'b', 'c', 'd'][i % 4])
        }

        const glbIndex = resource.indexOf('.glb')
        if (glbIndex !== -1) {
          const parts = resource.substring(0, glbIndex).split('_')
          const finalPart = parts[parts.length - 1].replace(/\D/g, '')
          const i = parseInt(finalPart, 10)
          return resource.replace('{s}', ['a', 'b', 'c', 'd'][i % 4])
        }

        return resource.replace('{s}', ['a', 'b', 'c', 'd'][this.index++ % 4])
      }
    })(),
  })

  return res
}
