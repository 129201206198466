import React, { useState } from 'react'
import {
  BoundaryCategoryType,
  BoundaryDtoT,
  BoundarySubcategoryType,
  BoundaryUpdateRequestT,
  PointT,
  SiteQueryBoundary,
  SurveyBasedOperation,
  UpdateBoundaryMutation,
} from '~/models'
import { string, object, kmlToPolygon, BOUNDARIES_ALLOWED_ORG_IDS, BoundaryAdditionalData } from '~/components'
import { FloatingInput, FloatingTextArea, useForm } from '../forms'
import { getTracking } from '../tracking'
import { useAppState } from '~/state'
import UPDATE_BOUNDARY from './mutation-boundary-update.gql'
import { FileSelector } from '../upload-panel/upload-panel-file-selector'
import { BoundariesMultipleModal } from './boundaries-multiple-modal'
import { useUser } from '~/base'
import { BoundaryCategoryTypeSelect, BoundarySubcategoryTypeSelect } from './boundary-categories'

interface BoundaryEditFormProps {
  cancel: () => void
  complete: () => Promise<void>
  boundary: Pick<BoundaryDtoT, 'id' | 'name' | 'additional'> & {
    points: Array<Pick<PointT, 'latitude' | 'longitude'>>
  }
  newPoints: React.MutableRefObject<Cesium.Cartesian3[]>
  setPointsFromKML: React.Dispatch<React.SetStateAction<Cesium.Cartesian3[]>>
}

export const BoundaryEditForm = (props: BoundaryEditFormProps) => {
  const { view } = useAppState()
  const [multipleBoundaries, setMultipleBoundaries] = useState<Array<Cesium.Cartesian3[]>>()
  const user = useUser()
  const canSeeAdditionalData = BOUNDARIES_ALLOWED_ORG_IDS.findIndex((x) => x === user.currentOrgId) !== -1

  let data: SiteQueryBoundary['additional'] = undefined
  if (props.boundary.additional) {
    data = props.boundary.additional
  }

  const { values, touched, errors, isSubmitting, handleChange, handleBlur, handleSubmit, error } = useForm<
    UpdateBoundaryMutation,
    BoundaryUpdateRequestT & BoundaryAdditionalData,
    BoundaryUpdateRequestT
  >({
    enableReinitialize: false,
    initialValues: {
      boundaryID: props?.boundary?.id || '',
      name: props?.boundary?.name || '',
      points: props?.boundary?.points || [],
      siteID: view.siteID,
      assetID: view.assetID || '',
      operationType: SurveyBasedOperation.GoingForward,
      surveyID: '',
      category: data ? data.category : BoundaryCategoryType.Cadastral,
      subcategory: data ? data.subcategory : BoundarySubcategoryType.Owned,
      sourceInformation: data ? data.sourceInformation : '',
      additionalData: '',
    },
    validationSchema: object().shape({
      name: string().required('Required'),
      category: canSeeAdditionalData ? string().required('Required') : string(),
      subcategory: canSeeAdditionalData ? string().required('Required') : string(),
      sourceInformation: canSeeAdditionalData ? string().required('Required') : string(),
    }),
    mutation: UPDATE_BOUNDARY,
    mapInput: (input) => {
      const points = props.newPoints.current.map((p) => {
        const pos = Cesium.Cartographic.fromCartesian(p)
        return {
          latitude: (pos.latitude / Math.PI) * 180,
          longitude: (pos.longitude / Math.PI) * 180,
        }
      })
      return {
        name: input.name,
        siteID: input.siteID,
        assetID: input.assetID,
        boundaryID: input.boundaryID,
        operationType: input.operationType,
        surveyID: view.siteID,
        points,
        additionalData: '',
        additional: canSeeAdditionalData
          ? {
              category: input.category,
              subcategory: input.subcategory,
              sourceInformation: input.sourceInformation || '',
              rawBoundaries: [],
              epsgCode: 0,
            }
          : undefined,
      }
    },
    onSuccess: () => {
      getTracking().event({
        category: 'Boundary',
        action: `User Edited Boundary`,
        label: 'Boundary',
      })
      props.complete()
    },
  })

  return (
    <>
      <div className='drawer-panel'>
        <nav aria-label='breadcrumb'>
          <ol className='breadcrumb'>
            <li className='breadcrumb-item'>
              <a
                href='#'
                onClick={(e) => {
                  e.preventDefault()
                  props.cancel()
                }}
              >
                Site
              </a>
            </li>
            <li className='breadcrumb-item active' aria-current='page'>
              Boundary
            </li>
          </ol>
        </nav>
        <div className='drawer-panel-title-container'>
          <h6 className='drawer-panel-title'>Update Boundary</h6>
        </div>
        <div className='drawer-panel-form-container'>
          {error && <div className='error'>{error}</div>}
          <form autoComplete='off' onSubmit={handleSubmit}>
            <label htmlFor='name'>Name</label>
            <FloatingInput
              id='name'
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={touched.name ? errors.name : ''}
              error={touched.name && Boolean(errors.name)}
            />
            {canSeeAdditionalData && (
              <>
                <BoundaryCategoryTypeSelect name='category' selectedValue={values.category} onChange={handleChange} />
                <BoundarySubcategoryTypeSelect
                  name='subcategory'
                  selectedCategory={values.category}
                  selectedValue={values.subcategory}
                  onChange={handleChange}
                />

                <FloatingTextArea
                  label='Source Information'
                  id='sourceInformation'
                  value={values.sourceInformation}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={touched.sourceInformation ? errors.sourceInformation : ''}
                  error={touched.sourceInformation && Boolean(errors.sourceInformation)}
                  rows={8}
                />
                <br />
              </>
            )}
            <p className='drawer-panel-hint'>
              Use your cursor to modify the boundary on the map or upload the boundary from a KML file. <br />
              <FileSelector
                singleFile
                accept='.kml'
                text='Select KML file'
                className='profile-form-btn btn boundaries-file-select'
                filesSelected={(files) => {
                  const reader = new FileReader()
                  reader.onload = function () {
                    const xml = reader.result.toString()
                    const allCoords = kmlToPolygon(xml)
                    if (allCoords.length > 0) {
                      if (allCoords.length === 1) {
                        props.setPointsFromKML(allCoords[0])
                      } else {
                        setMultipleBoundaries(allCoords)
                      }
                    }
                  }
                  reader.readAsText(files[0])
                }}
              />
              <br />
            </p>
            <button type='submit' className='btn submit mb-2' disabled={isSubmitting}>
              Save
            </button>
            <button className='btn cancel' onClick={() => props.cancel()}>
              Cancel
            </button>
          </form>
        </div>
      </div>

      {multipleBoundaries && (
        <BoundariesMultipleModal
          boundaries={multipleBoundaries}
          onComplete={() => props.complete()}
          onCancel={() => {
            props.setPointsFromKML(multipleBoundaries[0])
            setMultipleBoundaries(undefined)
          }}
        />
      )}
    </>
  )
}
