import { useQuery } from '~/components'
import React, { useEffect, useState } from 'react'
import { string, object, number, array } from '~/components'
import { DrawerFormCancelButton, DrawerFormSubmitButton, getCurrencyPrefix, Helmet } from '~/components'
import {
  FloatingInput,
  FloatingTextArea,
  Select,
  useForm,
  DrawerForm,
  DrawerFormBreadcrumbs,
  DrawerFormBreadcrumbItem,
  DrawerFormHeading,
  DrawerFormContent,
  DrawerFormButtons,
  getTracking,
  useToasts,
  pickFromPosition,
} from '~/components'
import { BaseLayerType, MarkerIconType, ProjectionType, useAppState } from '~/state'
import CREATE_MANUALLY_TAGGED_ISSUE from './mutation-issues-create.gql'
import DEFECT_TYPES_QUERY from './query-defect-types.gql'
import { PrioritySelect, DefectTypeSelect, RiskTypeSelect, StatusSelect } from './selects'
import {
  RiskType,
  DefectType,
  DefectSubType,
  GetDefectTypesQuery,
  CreateManuallyTaggedIssueMutation,
  CreateManuallyTaggedIssueRequestT,
  ComponentDtoT,
  IssueStatus,
  SiteQueryAsset,
  ComponentTypeSmallDtoT,
  AssetTypeDtoT,
  GetAssetTypeByIdQuery,
  GetAssetTypeByIdQueryVariables,
  SurveyBasedOperation,
  MaterialTypeSmallDtoT,
  ComponentTypeDtoT,
  SiteQueryComponent,
} from '~/models'
import { ComponentTypeSelect } from '~/components/components2/component-type-select'
import { AssetTypeSelect } from './selects/asset-types-select'
import ASSET_TYPE_BY_ID_QUERY from '../assets/query-asset-type-by-id.gql'
import { useUser } from '~/base'
import { getIssueEstimatedRepairCost } from './issue-repair-cost-calculator'

interface IssuesCreateFormProps {
  setRiskType?: (r: RiskType) => void
}

export const IssuesCreateForm = (props: IssuesCreateFormProps) => {
  const { map, timeline, imageTagger, view, site, asset } = useAppState()
  const toasts = useToasts()
  const user = useUser()

  const [showFormValidationMessages, setShowFormValidationMessages] = useState(false)
  const [defectType, setDefectType] = useState<DefectType | undefined>()
  const [defectSubType, setDefectSubType] = useState<DefectSubType | undefined>()
  const [selectedAssetType, setSelectedAssetType] = useState<AssetTypeDtoT>()
  const [selectedComponent, setSelectedComponent] = useState<ComponentDtoT>()
  const [selectedComponentTypes, setSelectedComponentTypes] = useState<ComponentTypeSmallDtoT[]>([])
  const [selectedMaterialType, setSelectedMaterialType] = useState<MaterialTypeSmallDtoT>(undefined)

  const defectTypesQuery = useQuery<GetDefectTypesQuery>(DEFECT_TYPES_QUERY)
  const [defectTypeList, setDefectTypeList] = useState([])
  const [components, setComponents] = useState<SiteQueryComponent[]>([])
  const [selectedAsset, setSelectedAsset] = useState<SiteQueryAsset>()

  useEffect(() => {
    if (asset.asset) {
      setSelectedAsset(asset.asset)
    }
  }, [asset.asset])

  const { values, isSubmitting, handleChange, handleBlur, handleSubmit, error, setFieldValue, submitForm } = useForm<
    CreateManuallyTaggedIssueMutation,
    CreateManuallyTaggedIssueRequestT['items'][0] & { operationType: SurveyBasedOperation },
    CreateManuallyTaggedIssueRequestT
  >({
    enableReinitialize: true,
    validationSchema: object().shape({
      name: string().optional(),
      priority: number().required(),
      riskType: string().required(),
      notes: string().optional(),
      repairEstimate: number().optional(),
      status: string().required(),
      location: object().required(),
      imageID: string().required(),
      coordinates: array().required(),
      defectTypeID: string().required(),
      defectSubTypeID: string().optional(),
      componentID: string().optional(),
      assetTypeID: string().required(),
      operationType: string().required(),
      assetID: string().optional(),
    }),
    initialValues: {
      name: '',
      priority: 5,
      riskType: RiskType.Low,
      notes: '',
      repairEstimate: 0,
      status: IssueStatus.AwaitingAction,
      location: {
        altitude: 0,
        latitude: 0,
        longitude: 0,
      },
      imageID: imageTagger.photo.imageID,
      coordinates: [],
      defectTypeID: '',
      defectSubTypeID: '',
      componentID: '',
      componentTypeID: '',
      assetTypeID: '',
      assetID: '',
      materialTypeID: '',
      operationType: SurveyBasedOperation.Singular,
    },
    mutation: CREATE_MANUALLY_TAGGED_ISSUE,
    mapInput: (input) => {
      const opType = input.operationType
      delete input['operationType']
      const toSubmit: CreateManuallyTaggedIssueRequestT = {
        siteID: site.site.id,
        surveyID: view.surveyID,
        operationType: opType,
        items: [
          {
            ...input,
            imageID: imageTagger.photo.imageID,
            repairEstimate: Math.floor((input.repairEstimate || 0) * 100),
            coordinates: imageTagger.addingPoints,
            assetID: selectedAsset?.id || '',
            materialTypeID: selectedMaterialType?.id || '',
          },
        ],
      }
      return toSubmit
    },
    onSuccess: (result: CreateManuallyTaggedIssueMutation) => {
      if (result.createManuallyTaggedIssue.issueIDs) {
        getTracking().event({
          category: 'Form',
          action: `User created a issue`,
          label: 'Issue',
        })
        site.refetch().then(() => {
          imageTagger.setAddingIssue(false)
          toasts.addTopLeft('Issue successfully created')
        })
      } else {
        getTracking().event({
          category: 'Form',
          action: `Form Submission Issue`,
          label: 'Issue',
        })
      }
    },
  })

  // Set UI state.
  useEffect(() => {
    map.setShowControls(false)
    timeline.setHidden(true)
    map.setBaseLayerType(BaseLayerType.PhotoModel).then(() => {
      function getHeightOffset() {
        if (!timeline.activeSurvey) {
          return 0
        }
        const heightOffset =
          timeline.activeSurvey.reconstructions.find((x) => x.complete)?.manualTranslation?.altitude || 0
        return heightOffset
      }

      const photo = imageTagger.photo
      const quat = new Cesium.Quaternion(photo.qx, photo.qy, photo.qz, photo.qw)

      if (quat.x === 0 && quat.y === 0 && quat.z === 0 && quat.w === 0) {
        map.viewer.camera.flyTo({
          destination: Cesium.Cartesian3.fromDegrees(
            photo.longitude,
            photo.latitude,
            photo.altitude + getHeightOffset()
          ),
          duration: 0.25,
        })
        return
      }

      map.viewer.camera.flyTo({
        destination: Cesium.Cartesian3.fromDegrees(photo.longitude, photo.latitude, photo.altitude + getHeightOffset()),
        orientation: Cesium.HeadingPitchRoll.fromDegrees(photo.yaw, photo.pitch, photo.roll),
        duration: 0.25,
      })
    })

    return () => {
      map.setShowControls(true)
      timeline.setHidden(false)
    }
  }, [])

  // Load defect types.
  useEffect(() => {
    if (defectTypesQuery.data?.defectTypes[0]) {
      const defectTypesArray = defectTypesQuery.data.defectTypes

      const sortedDefectTypes = [...defectTypesArray].sort((a, b) => {
        return a.name < b.name ? -1 : 1
      })

      setDefectTypeList(sortedDefectTypes)
      setFieldValue('defectTypeID', sortedDefectTypes[0]?.id)
      setDefectType(sortedDefectTypes[0])
    }
  }, [defectTypesQuery.data])

  // Load components.
  useEffect(() => {
    if (!site || !timeline.activeSurvey) {
      return
    }

    if (!view.assetID) {
      if (selectedAsset) {
        setComponents([...(timeline.activeSurvey.components as any)])
      } else {
        setComponents([])
      }
    } else {
      if (asset.asset) {
        setComponents(timeline.activeSurvey.components.filter((c) => c.assetID === asset.asset.id))
      }
    }
  }, [site, asset.asset, selectedAsset])

  const assetTypeQuery = useQuery<GetAssetTypeByIdQuery, GetAssetTypeByIdQueryVariables>(ASSET_TYPE_BY_ID_QUERY, {
    fetchPolicy: 'standby',
    nextFetchPolicy: 'cache-and-network',
  })

  useEffect(() => {
    if (typeof selectedAsset?.assetType?.id !== 'string') {
      return
    }

    assetTypeQuery
      .refetch({
        assetTypeID: selectedAsset.assetType.id,
      })
      .then((res) => {
        setSelectedAssetType(res.data.assetTypeByID as AssetTypeDtoT)
        setFieldValue('assetTypeID', res.data.assetTypeByID.id)
      })
  }, [selectedAsset])

  useEffect(() => {
    if (values.priority <= 5) {
      setFieldValue('riskType', RiskType.Low)
    } else if (values.priority <= 7) {
      setFieldValue('riskType', RiskType.Medium)
    } else {
      setFieldValue('riskType', RiskType.High)
    }
  }, [values.priority])

  // Handle 3D clicks.
  useEffect(() => {
    if (map.projectionType !== ProjectionType.Projection3D) {
      return
    }

    const leftClickHandler = new Cesium.ScreenSpaceEventHandler(map.viewer.scene.canvas)
    leftClickHandler.setInputAction((click: Cesium.ScreenSpaceEventHandler.PositionedEvent) => {
      const picked = pickFromPosition(map.viewer, click.position, [])
      const cartesian = picked.cartesian
      if (!Cesium.defined(cartesian)) {
        return
      }

      const carto = Cesium.Cartographic.fromCartesian(cartesian)
      setFieldValue('location', {
        longitude: Cesium.Math.toDegrees(carto.longitude),
        latitude: Cesium.Math.toDegrees(carto.latitude),
        altitude: carto.height,
      })
    }, Cesium.ScreenSpaceEventType.LEFT_CLICK)

    return () => {
      leftClickHandler.destroy()
    }
  }, [map.projectionType])

  // Render 3D Marker.
  useEffect(() => {
    if (!values.location) {
      return
    }

    const pos = Cesium.Cartographic.fromDegrees(
      values.location.longitude,
      values.location.latitude,
      values.location.altitude
    )
    const photo = imageTagger.photo
    const marker = map.addMarker(
      `tmp-issue-marker-${photo.imageID}`,
      [Cesium.Math.toDegrees(pos.longitude), Cesium.Math.toDegrees(pos.latitude), pos.height],
      undefined,
      null,
      16,
      '#fff',
      values.riskType === 'Low'
        ? MarkerIconType.IssueLow
        : values.riskType === 'Medium'
        ? MarkerIconType.IssueMedium
        : values.riskType === 'High'
        ? MarkerIconType.IssueHigh
        : MarkerIconType.IssueLow
    )

    return () => {
      map.removeMarker(marker)
    }
  }, [values.location, values.riskType])

  const formInvalid =
    imageTagger.addingPoints?.length < 3 ||
    !values.location ||
    values.location.altitude === 0 ||
    !values.assetTypeID ||
    !values.componentTypeID ||
    isSubmitting

  const formValidationMessage =
    imageTagger.addingPoints?.length < 3
      ? 'Polygon needs to be drawn on the image'
      : !values?.location || values.location?.altitude === 0
      ? 'A location needs to be selected on the 3D model'
      : !values.assetTypeID
      ? 'An asset or asset type needs to be selected'
      : !values.componentTypeID
      ? 'A component or component type needs to be selected'
      : ''

  const repairEstimate = getIssueEstimatedRepairCost(user, {
    priority: values.priority,
    repairEstimate: values.repairEstimate,
    defectCostToRepair: (defectSubType || defectType)?.costToRepair || 0,
    defectCostPriorityScalar: (defectSubType || defectType)?.costPriorityScalar || 0,
  })

  return (
    <DrawerForm>
      <Helmet title='Create issue' />
      <DrawerFormBreadcrumbs>
        <DrawerFormBreadcrumbItem
          title={asset.asset?.name || site.site.name}
          onClick={() => {
            imageTagger.setAddingIssue(false)
          }}
        />
        <DrawerFormBreadcrumbItem title='Create issue' />
      </DrawerFormBreadcrumbs>
      <DrawerFormHeading
        title='Issue Details'
        helpText='Use your cursor to draw a boundary around the image and click on the 3D map to set the position of the issue.'
      />
      <DrawerFormContent>
        <form autoComplete='off' id='create-new-issue-form' onSubmit={handleSubmit}>
          {!view.assetID && (
            <Select<SiteQueryAsset>
              id='asset'
              label='Asset'
              placeholder='Select asset'
              onChange={(selected) => {
                setSelectedAsset(selected)
                setFieldValue('componentID', '')
                setSelectedComponent(undefined)
              }}
              canSelectGroup
              canSelectNone
              selectedValue={selectedAsset}
              options={site.site?.assets.map((a) => {
                return {
                  id: a.id,
                  name: a.name,
                  value: a,
                  items: [],
                }
              })}
            />
          )}
          <AssetTypeSelect
            onChange={(s) => {
              setSelectedAssetType(s)
              setFieldValue('assetTypeID', s?.id || '')
            }}
            assetTypeID={selectedAssetType?.id}
            disabled={!!selectedAsset}
          />
          <Select<ComponentDtoT>
            id='component'
            label='Component'
            placeholder='Select component'
            disabled={!selectedAsset}
            onChange={(selected) => {
              setFieldValue('componentID', selected?.id || '')
              setFieldValue('componentTypeID', selected?.componentTypeID || '')
              setSelectedComponent(selected)

              if (selected && selectedAssetType) {
                const newSelectedComponentTypes: ComponentTypeDtoT[] = []

                const evaluateCT = (c: ComponentTypeDtoT): boolean => {
                  if (c.id === selected.componentTypeID) {
                    newSelectedComponentTypes.push(c)
                    return true
                  }
                  const shouldAdd = (c.children || []).map(evaluateCT).reduce((a, b) => a || b, false)
                  if (shouldAdd) {
                    newSelectedComponentTypes.push(c)
                  }

                  return shouldAdd
                }

                selectedAssetType.componentTypes.forEach(evaluateCT)

                setSelectedComponentTypes(newSelectedComponentTypes.reverse())
                if (newSelectedComponentTypes.length > 0) {
                  const last = newSelectedComponentTypes[newSelectedComponentTypes.length - 1]
                  const mt = last.materialTypes.find((x) => x.id === selected.materialTypeID)
                  setSelectedMaterialType(mt)
                }
              }
            }}
            canSelectGroup
            canSelectNone
            selectedValue={selectedComponent}
            options={((selectedAsset
              ? timeline.activeSurvey.components.filter((c) => c.assetID === selectedAsset.id)
              : components) as any
            ).map((c: ComponentDtoT) => {
              return {
                id: c.id,
                name: c.materialTypeName === '' ? '' : c.materialTypeName + ' ' + (c.name || c.componentTypeName),
                value: c,
                items: c.children.map((child) => {
                  return {
                    id: child.id,
                    name:
                      child.materialTypeName === ''
                        ? ''
                        : child.materialTypeName + ' ' + (child.name || c.componentTypeName),
                    value: child,
                    items: child.children.map((subChild) => ({
                      id: subChild.id,
                      name:
                        child.materialTypeName === ''
                          ? ''
                          : child.materialTypeName + ' ' + (child.name || c.componentTypeName),
                      value: subChild,
                      items: [],
                    })),
                  }
                }),
              }
            })}
          />
          <ComponentTypeSelect
            componentTypes={selectedAssetType?.componentTypes || []}
            onChange={(selectedComponentTypes, selectedMaterialType) => {
              setSelectedComponentTypes(selectedComponentTypes)
              setSelectedMaterialType(selectedMaterialType)
              setFieldValue(
                'componentTypeID',
                selectedComponentTypes.length > 0 ? selectedComponentTypes[selectedComponentTypes.length - 1].id : ''
              )
            }}
            selectedComponentTypes={selectedComponentTypes}
            selectedMaterialType={selectedMaterialType}
            disabled={!!selectedComponent}
          />
          <PrioritySelect
            selectedValue={values.priority}
            onChange={(v) => {
              setFieldValue('priority', v)
            }}
          />
          <DefectTypeSelect
            defectType={defectType}
            defectSubType={defectSubType}
            defectTypes={defectTypeList}
            onChange={(d, s) => {
              setDefectType(d)
              setDefectSubType(s)
              setFieldValue('defectTypeID', d?.id || '')
              setFieldValue('defectSubTypeID', s?.id || '')
            }}
          />
          <RiskTypeSelect
            selectedValue={values.riskType}
            onChange={(v) => {
              setFieldValue('riskType', v)
              if (props.setRiskType) {
                props.setRiskType(v)
              }
            }}
          />
          <StatusSelect
            selectedValue={values.status}
            onChange={(v) => {
              setFieldValue('status', v)
            }}
          />
          <FloatingInput
            label='Repair Estimate'
            id='repairEstimate'
            type='number'
            value={values.repairEstimate === 0 ? undefined : values.repairEstimate}
            onChange={handleChange}
            onBlur={handleBlur}
            onFocus={onfocus}
            helperText={''}
            prefix={getCurrencyPrefix(user)}
            placeholder={repairEstimate.cost / 100 + ''}
          />
          <div className='mb-3'>
            <FloatingTextArea
              label='Notes'
              id='notes'
              value={values.notes}
              onChange={handleChange}
              onBlur={handleBlur}
              onFocus={onfocus}
              helperText={''}
              rows={3}
            />
          </div>
        </form>
      </DrawerFormContent>
      <DrawerFormButtons>
        {error && <div className='alert alert-warning ml-3 mr-3'>{error}</div>}
        {showFormValidationMessages && formInvalid && <div className='error'>{formValidationMessage}</div>}
        <DrawerFormSubmitButton
          form='create-new-issue-form'
          onClick={() => {
            if (formInvalid) {
              setShowFormValidationMessages(true)
            } else {
              submitForm()
            }
          }}
          disabled={showFormValidationMessages && formInvalid}
          title={formValidationMessage}
        >
          {isSubmitting ? 'Saving...' : 'Save'}
        </DrawerFormSubmitButton>
        <DrawerFormCancelButton
          onClick={() => {
            imageTagger.setAddingIssue(false)
          }}
        >
          Cancel
        </DrawerFormCancelButton>
      </DrawerFormButtons>
    </DrawerForm>
  )
}
