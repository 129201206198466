function crossProduct(o: Cesium.Cartographic, a: Cesium.Cartographic, b: Cesium.Cartographic): number {
  return (a.longitude - o.longitude) * (b.latitude - o.latitude) - (a.latitude - o.latitude) * (b.longitude - o.longitude);
}

export function makeHull(points: Cesium.Cartographic[]): Cesium.Cartographic[] {
  if (points.length < 3) {
      throw new Error("At least three points are required to compute a convex hull.");
  }

  // Sort points lexicographically (by longitude, then latitude)
  points.sort((a, b) => a.longitude - b.longitude || a.latitude - b.latitude);

  const lowerHull: Cesium.Cartographic[] = [];
  for (const p of points) {
      while (lowerHull.length >= 2 && crossProduct(lowerHull[lowerHull.length - 2], lowerHull[lowerHull.length - 1], p) <= 0) {
          lowerHull.pop();
      }
      lowerHull.push(p);
  }

  const upperHull: Cesium.Cartographic[] = [];
  for (let i = points.length - 1; i >= 0; i--) {
      const p = points[i];
      while (upperHull.length >= 2 && crossProduct(upperHull[upperHull.length - 2], upperHull[upperHull.length - 1], p) <= 0) {
          upperHull.pop();
      }
      upperHull.push(p);
  }

  // Remove last point because it's repeated at the beginning
  upperHull.pop();
  lowerHull.pop();

  return [...lowerHull, ...upperHull];
}