import React, { useEffect, useState } from 'react'
import proj4 from 'proj4'

import {
  BoundaryCategoryType,
  BoundaryCreateRequestT,
  BoundarySubcategoryType,
  CreateBoundaryMutation,
  EpsgCodesQuery,
  EpsgCodesQueryVariables,
  SurveyBasedOperation,
} from '~/models'
import {
  kmlToPolygon,
  notReachable,
  object,
  PolygonColorRed,
  PolygonRendererView,
  Select,
  string,
  Switch,
  useQuery,
  parseDXF,
  Polygon,
} from '~/components'
import { FloatingInput, FloatingTextArea, useForm } from '../forms'
import { getTracking } from '../tracking'
import { useAppState } from '~/state'
import CREATE_BOUNDARY from './mutation-boundary-create.gql'
import QUERY_EPSG from './query-epsg.gql'
import { FileSelector } from '../upload-panel/upload-panel-file-selector'
import { BoundariesMultipleModal } from './boundaries-multiple-modal'
import { useUser } from '~/base'
import { BOUNDARIES_ALLOWED_ORG_IDS, BoundaryAdditionalData } from './boundaries-extension-data'
import { BOUNDARY_CATEGORIES, BoundaryCategoryTypeSelect, BoundarySubcategoryTypeSelect } from './boundary-categories'

interface BoundaryCreateFormProps {
  cancel: () => void
  complete: () => Promise<void>
  points: React.MutableRefObject<Cesium.Cartesian3[]>
  setPointsFromKML: React.Dispatch<React.SetStateAction<Cesium.Cartesian3[]>>
}

interface EPSG {
  id: string
  code: number
  description: string
  projection: string
}

type state = { type: 'create_boundary_form' } | { type: 'confirm_dxf_boundaries' }

type SelectablePolygon = Polygon & { selected: boolean; id: string }

export const BoundaryCreateForm = (props: BoundaryCreateFormProps) => {
  const { map, view } = useAppState()
  const [state, setState] = useState<state>({ type: 'create_boundary_form' })
  const query = useQuery<EpsgCodesQuery, EpsgCodesQueryVariables>(QUERY_EPSG)
  const [epsg, setEpsg] = useState<EPSG[]>()
  const [selectedDatum, setSelectedDatum] = useState<EPSG>()
  const [importedFileName, setImportedFileName] = useState<string>()
  const [multipleBoundaries, setMultipleBoundaries] = useState<Array<Cesium.Cartesian3[]>>()
  const user = useUser()
  const [selectablePolygons, setSelectablePolygons] = useState<SelectablePolygon[]>([])
  const canSeeAdditionalData =
    !view.assetID && BOUNDARIES_ALLOWED_ORG_IDS.findIndex((x) => x === user.currentOrgId) !== -1

  const { values, touched, errors, isSubmitting, handleChange, handleBlur, handleSubmit, error, setFieldValue } =
    useForm<CreateBoundaryMutation, BoundaryCreateRequestT & BoundaryAdditionalData, BoundaryCreateRequestT>({
      enableReinitialize: false,
      initialValues: {
        siteID: view.siteID,
        assetID: view.assetID,
        name: 'Boundary',
        points: [],
        surveyID: view.siteID,
        operationType: SurveyBasedOperation.GoingForward,
        additionalData: '',
        category: BoundaryCategoryType.Cadastral,
        subcategory: BoundarySubcategoryType.Owned,
        sourceInformation: '',
      },
      validationSchema: object().shape({
        name: string().required('Required'),
        category: canSeeAdditionalData ? string().required('Required') : string(),
        subcategory: canSeeAdditionalData ? string().required('Required') : string(),
        sourceInformation: canSeeAdditionalData ? string().required('Required') : string(),
      }),
      mutation: CREATE_BOUNDARY,
      mapInput: (input) => {
        const toRet: BoundaryCreateRequestT = {
          siteID: view.siteID,
          assetID: view.assetID || '',
          name: input.name,
          points: props.points.current.map((p) => {
            const pos = Cesium.Cartographic.fromCartesian(p)
            return {
              latitude: (pos.latitude / Math.PI) * 180,
              longitude: (pos.longitude / Math.PI) * 180,
            }
          }),
          surveyID: view.siteID,
          operationType: input.operationType,
          additionalData: '',
          additional: canSeeAdditionalData
            ? {
                category: input.category,
                subcategory: input.subcategory,
                sourceInformation: input.sourceInformation || '',
                rawBoundaries: [],
                epsgCode: 0,
              }
            : undefined,
        }
        return toRet
      },
      onSuccess: () => {
        getTracking().event({
          category: 'Boundary',
          action: `User Created Boundary`,
          label: 'Boundary',
        })
        props.complete()
      },
    })

  const handleBoundariesConfirm = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    e.stopPropagation()

    const epsgCode = 'EPSG:' + selectedDatum.code
    proj4.defs(epsgCode, selectedDatum.projection)

    const cartesianPoints = selectablePolygons
      .filter((p) => p.selected)
      .map((p) =>
        p.vertices.map((vertex) => {
          const [lon, lat] = proj4(epsgCode, 'WGS84', [vertex.x, vertex.y])
          return Cesium.Cartesian3.fromDegrees(lon, lat)
        })
      )

    setMultipleBoundaries(cartesianPoints)
    setState({ type: 'create_boundary_form' })
  }

  useEffect(() => {
    if (!selectedDatum || !selectablePolygons.length) {
      return
    }

    const epsgCode = 'EPSG:' + selectedDatum.code
    proj4.defs(epsgCode, selectedDatum.projection)

    const renderedPolygons = selectablePolygons
      .filter((p) => p.selected)
      .map((p) => {
        return {
          label: p.id,
          vertices: p.vertices.map((vertex) => {
            const [lon, lat] = proj4(epsgCode, 'WGS84', [vertex.x, vertex.y])
            const cartesian = Cesium.Cartesian3.fromDegrees(lon, lat)
            const pos = Cesium.Cartographic.fromCartesian(cartesian)
            return {
              latitude: (pos.latitude / Math.PI) * 180,
              longitude: (pos.longitude / Math.PI) * 180,
            }
          }),
        }
      })
      .map((p) => {
        const renderedPolygon = new PolygonRendererView({
          points: p.vertices,
          map,
          color: PolygonColorRed,
          label: p.label,
          fill: true,
        })
        renderedPolygon.setActive(true)
        return renderedPolygon
      })

    return () => {
      renderedPolygons.forEach((p) => p.destroy())
    }
  }, [selectablePolygons, selectedDatum])

  useEffect(() => {
    const cat = BOUNDARY_CATEGORIES.find((x) => x.type === values.category)
    if (cat) {
      if (cat.type === BoundaryCategoryType.Cadastral) {
        setFieldValue('subcategory', BoundarySubcategoryType.Owned)
      } else {
        setFieldValue('subcategory', cat.subcategories[0].type)
      }
    }
  }, [values.category])

  useEffect(() => {
    if (!Array.isArray(query?.data?.epsgCodes)) {
      return
    }

    setEpsg(
      query.data.epsgCodes.map((e) => {
        return {
          id: e.code + '',
          code: e.code,
          description: e.description,
          projection: e.projection,
        }
      })
    )
  }, [query?.data])

  switch (state.type) {
    case 'create_boundary_form':
      return (
        <>
          <div className='drawer-panel'>
            <nav aria-label='breadcrumb'>
              <ol className='breadcrumb'>
                <li className='breadcrumb-item'>
                  <a
                    href='#'
                    onClick={(e) => {
                      e.preventDefault()
                      props.cancel()
                    }}
                  >
                    Site
                  </a>
                </li>
                <li className='breadcrumb-item active' aria-current='page'>
                  Boundary
                </li>
              </ol>
            </nav>
            <div className='drawer-panel-title-container'>
              <h6 className='drawer-panel-title'>Create Boundary</h6>
            </div>
            <form autoComplete='off' className='drawer-panel-form-container' onSubmit={handleSubmit}>
              {error && <div className='error'>{error}</div>}
              <FloatingInput
                label='Name'
                id='name'
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={touched.name ? errors.name : ''}
                error={touched.name && Boolean(errors.name)}
              />
              {canSeeAdditionalData && (
                <>
                  <BoundaryCategoryTypeSelect name='category' selectedValue={values.category} onChange={handleChange} />
                  <BoundarySubcategoryTypeSelect
                    name='subcategory'
                    selectedCategory={values.category}
                    selectedValue={values.subcategory}
                    onChange={handleChange}
                  />

                  <FloatingTextArea
                    label='Source Information'
                    id='sourceInformation'
                    value={values.sourceInformation}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={touched.sourceInformation ? errors.sourceInformation : ''}
                    error={touched.sourceInformation && Boolean(errors.sourceInformation)}
                    rows={8}
                  />
                  <br />
                </>
              )}
              <p className='drawer-panel-hint'>
                Use your cursor to draw your boundary on the map or upload the boundary from a supported file. <br />
                <FileSelector
                  singleFile
                  accept='.kml'
                  text='Select KML file'
                  className='profile-form-btn btn boundaries-file-select'
                  filesSelected={(files) => {
                    const reader = new FileReader()
                    reader.onload = function () {
                      setImportedFileName(values.name)
                      const xml = reader.result.toString()
                      const allCoords = kmlToPolygon(xml)
                      if (allCoords.length > 0) {
                        if (allCoords.length === 1) {
                          props.setPointsFromKML(allCoords[0])
                        } else {
                          setMultipleBoundaries(allCoords)
                        }
                      }
                      setSelectedDatum({
                        code: 4326,
                        description: 'WGS 84',
                        id: '4326',
                        projection: '+proj=longlat +datum=WGS84 +no_defs +type=crs',
                      })
                    }
                    reader.readAsText(files[0])
                  }}
                />
                <FileSelector
                  singleFile
                  accept='.dxf'
                  text='Select DXF file'
                  className='profile-form-btn btn boundaries-file-select'
                  filesSelected={(files) => {
                    const reader = new FileReader()
                    reader.onload = function () {
                      setImportedFileName(values.name)
                      const dxf = reader.result.toString()
                      const polygons = parseDXF(dxf)
                      setSelectablePolygons(
                        polygons.map((p) => {
                          return {
                            selected: true,
                            ...p,
                          }
                        })
                      )

                      setState({ type: 'confirm_dxf_boundaries' })
                    }
                    reader.readAsText(files[0])
                  }}
                />
              </p>
              <div className='drawer-panel-form-container-action-container'>
                <button
                  type='submit'
                  className='btn submit mb-2'
                  disabled={
                    isSubmitting || props.points.current.length < 3 || (canSeeAdditionalData && !values.subcategory)
                  }
                >
                  Save
                </button>
                <button className='btn cancel' onClick={() => props.cancel()}>
                  Cancel
                </button>
              </div>
            </form>
          </div>
          {multipleBoundaries && (
            <BoundariesMultipleModal
              additionalData={
                canSeeAdditionalData
                  ? {
                      category: values.category,
                      subcategory: values.subcategory,
                      sourceInformation: values.sourceInformation || '',
                      rawBoundaries: selectablePolygons.map((p) => {
                        return {
                          coordinates: p.vertices.map((vertex) => ({
                            ...vertex,
                          })),
                        }
                      }),
                      epsgCode: selectedDatum?.code,
                    }
                  : undefined
              }
              boundaryName={importedFileName}
              boundaries={multipleBoundaries}
              onComplete={() => props.complete()}
              onCancel={() => {
                props.setPointsFromKML(multipleBoundaries[0])
                setMultipleBoundaries(undefined)
                setSelectablePolygons([])
              }}
            />
          )}
        </>
      )
    case 'confirm_dxf_boundaries':
      return (
        <div className='drawer-panel'>
          <nav aria-label='breadcrumb'>
            <ol className='breadcrumb'>
              <li className='breadcrumb-item'>
                <a
                  href='#'
                  onClick={(e) => {
                    e.preventDefault()
                    props.cancel()
                  }}
                >
                  Site
                </a>
              </li>
              <li className='breadcrumb-item active' aria-current='page'>
                Boundary
              </li>
            </ol>
          </nav>
          <div className='drawer-panel-title-container'>
            <h6 className='drawer-panel-title'>Confirm Boundaries</h6>
          </div>
          <form autoComplete='off' className='drawer-panel-form-container' onSubmit={handleBoundariesConfirm}>
            {error && <div className='error'>{error}</div>}

            <p className='drawer-panel-hint'>What CRS is used in the uploaded DXF file?</p>

            <Select<EPSG>
              id='epsg'
              label='Datum'
              placeholder='Select datum'
              onChange={(selected) => {
                setSelectedDatum(selected)
              }}
              canSelectGroup
              canSelectNone
              selectedValue={selectedDatum}
              options={
                epsg
                  ? epsg.map((e) => {
                      return {
                        id: e.id,
                        name: e.description + ' (EPSG:' + e.id + ')',
                        value: e,
                        items: [],
                      }
                    })
                  : []
              }
            />

            <p className='drawer-panel-hint'>
              Confirm which boundaries you would like to import. <br />
            </p>

            {/*TODO fix bottom margin issue with the slimscroll*/}
            <div className={'ui-slimscroll mb-5'}>
              {selectablePolygons.map((p) => {
                return (
                  <Switch
                    key={p.id}
                    name={`boundary-${p.id}`}
                    label={`Boundary ${p.id}`}
                    checked={p.selected}
                    onChange={() => {
                      const updatedPolygons = selectablePolygons.map((polygon) =>
                        polygon.id === p.id ? { ...polygon, selected: !polygon.selected } : polygon
                      )
                      setSelectablePolygons(updatedPolygons)
                    }}
                  />
                )
              })}
            </div>

            <div className='drawer-panel-form-container-action-container'>
              <button
                type='submit'
                className='btn submit mb-2'
                disabled={!selectablePolygons.length || !selectedDatum?.code}
              >
                Save
              </button>
              <button
                className='btn cancel'
                onClick={() => {
                  setSelectablePolygons([])
                  setState({ type: 'create_boundary_form' })
                }}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      )

    default:
      return notReachable(state)
  }
}
