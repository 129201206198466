import React, { useState, useEffect, useMemo } from 'react'
import { useAppState, ProjectionType, BaseLayerType, Marker } from '~/state'
import { SitesListViewType } from '../sites-list-types'
import { Helmet, useLocation, useToasts } from '~/components'
import { SitesHeader } from '../sites-header'
import { SitesListCards } from '../sites-list-cards'
import { SiteCreate } from '../site-create'
import { ClientPermission, useUser } from '~/base'
import { SiteGroupRemove } from './group-remove'
import { SiteGroupCreate } from './site-groups-create'

export const SiteGroup = () => {
  const { sites, map, search, view, drawer } = useAppState()
  const toasts = useToasts()
  const location = useLocation()
  const user = useUser()

  const [viewType, setViewType] = useState(SitesListViewType.Loading)
  const [isSearching, setIsSearching] = useState(false)
  const [showEdit, setShowEdit] = useState(false)
  const [showDelete, setShowDelete] = useState(false)
  const [jumped, setJumped] = useState(false)
  const [showCreateSite, setShowCreateSite] = useState<boolean>(false)
  const [visibleSites, setVisibleSites] = useState<string[]>((sites?.data?.sites || []).map((s) => s.id))
  const canUpdate = user.hasPermission(ClientPermission.SitesUpdate)
  const canRemove = user.hasPermission(ClientPermission.SitesRemove)

  const group = (sites.groups || []).find((g) => g.group.id === view.groupID)
  const otherGroups = (sites.groups || [])
    .filter((g) => g.group.id !== view.groupID)
    .sort((a, b) => {
      return a.group.name > b.group.name ? 1 : -1
    })

  useEffect(() => {
    if (!sites.sites || !sites.groups || viewType !== SitesListViewType.Loading) {
      return
    }

    setViewType(SitesListViewType.Groups)
  }, [sites.sites, sites.groups, viewType])

  useEffect(() => {
    if (!group || !map.initialized) {
      return
    }

    const sortedSites = [...group.sites].sort((a, b) => {
      const c1 = Cesium.Cartographic.fromCartesian(a.extra.center)
      const c2 = Cesium.Cartographic.fromCartesian(b.extra.center)
      return c1.longitude >= c2.longitude ? -1 : 1
    })

    const markers: Marker[] = []
    for (const site of sortedSites) {
      markers.push(
        map.addClusteredMarker(site.s.id, site.extra.center, site.s.name, site.s.lastSurveyID, () => {
          location.setLocation('/' + site.s.id + '/' + site.s.lastSurveyID)
        })
      )
    }

    function moveListener() {
      if (sites.editing.current) {
        return
      }

      const visibleMarkers = markers.filter((m) => {
        if (m.billboard) {
          const screenPos = m.billboard.computeScreenSpacePosition(map.viewer.scene)

          if (
            screenPos &&
            Cesium.Math.greaterThanOrEquals(screenPos.x, 0, Cesium.Math.EPSILON1) &&
            Cesium.Math.lessThanOrEquals(screenPos.x, window.innerWidth, Cesium.Math.EPSILON1) &&
            Cesium.Math.greaterThanOrEquals(screenPos.y, 0, Cesium.Math.EPSILON1) &&
            Cesium.Math.lessThanOrEquals(screenPos.y, window.innerHeight, Cesium.Math.EPSILON1)
          ) {
            return true
          }
        }

        return false
      })

      if (visibleMarkers.length === 1 && map.viewer.camera.positionCartographic.height <= 1e4) {
        location.setLocation('/' + visibleMarkers[0].id + '/' + visibleMarkers[0].surveyID)
      }

      if (visibleMarkers.length == 0) {
        setVisibleSites(() => markers.map((m) => m.id))
      } else {
        setVisibleSites(() => visibleMarkers.map((m) => m.id))
      }
    }

    map.viewer.camera.moveEnd.addEventListener(moveListener)

    return () => {
      for (const marker of markers) {
        map.removeClusteredMarker(marker)
      }

      map.viewer.camera.moveEnd.removeEventListener(moveListener)
    }
  }, [group, map.initialized])

  useEffect(() => {
    if (map.projectionType === ProjectionType.Projection3D) {
      map.toggleProjection()
    }
  }, [map.projectionType])

  // Jump to all sites.
  useEffect(() => {
    if (!group || viewType === SitesListViewType.Loading || jumped) {
      return
    }

    map.setBaseLayerType(BaseLayerType.Satellite)
    setJumped(true)

    // TODO: Change to group bounds
    map.jumpTo(sites.getBoundsForSites(group.sites.map((s) => s.s)))
  }, [group, viewType, jumped])

  useEffect(() => {
    if (jumped) {
      setJumped(false)
    }
  }, [group?.group?.id])

  // Filter sites based on search.
  const filteredSites = useMemo(() => {
    if (!group) {
      return undefined
    }

    if (isSearching && !drawer.leftExpanded) {
      return group.sites.filter(function (obj) {
        return obj.s.name.toLowerCase().includes(search.search.toLowerCase())
      })
    } else {
      return group.sites.filter((s) => visibleSites.includes(s.s.id))
    }
  }, [group, isSearching, search.search, visibleSites, drawer.leftExpanded])

  const haveError = sites.error
  const isLoading = sites.loading
  const haveSites = !haveError && sites.data && sites.data?.sites.length > 0
  const showEmpty = !haveSites && !isLoading && !haveError
  const showFullList = drawer.leftExpanded

  // On a new site created.
  const siteCreated = (created: boolean, siteID: string) => {
    if (created) {
      toasts.addTopLeft('Site successfully created')
      sites.refetch()
      location.setLocation('/' + siteID)
    }

    setShowCreateSite(false)
  }

  return (
    <>
      <Helmet title={'Sites ' + (showDelete ? 'D' : '')} />
      <div className='sites'>
        {!showDelete && !showEdit && (
          <>
            {' '}
            <SitesHeader
              haveSites={haveSites}
              onCreateSite={() => setShowCreateSite(true)}
              filterSites={(value: string) => {
                search.setSearch(value || '')
                if (value.length >= 1) {
                  setIsSearching(true)
                } else {
                  setIsSearching(false)
                }
              }}
              siteResults={filteredSites}
              viewType={SitesListViewType.Group}
              name={group?.group?.name}
              extraLinks={otherGroups.map((g) => {
                return {
                  name: g.group.name,
                  description: `${g.group.siteIDs.length} sites`,
                  url: '/groups/' + g.group.id,
                }
              })}
              canUpdate={canUpdate}
              canRemove={canRemove}
              onClickEdit={() => setShowEdit(true)}
              onClickDelete={() => setShowDelete(true)}
              permission={ClientPermission.SitesCreate}
            />
            <div className='sites-list' id='sites-list'>
              <SitesListCards
                filter={visibleSites}
                onCreateSite={() => setShowCreateSite(true)}
                sites={filteredSites}
                isLoading={isLoading}
                showEmpty={showEmpty}
                haveSites={haveSites}
                showFullList={showFullList}
              />
            </div>
          </>
        )}
        {showDelete && (
          <SiteGroupRemove
            hide={() => setShowDelete(false)}
            onRemove={() => {
              sites.refetch().then(() => {
                toasts.addTopLeft('Group successfully removed')
                location.setLocation('/groups')
              })
            }}
          />
        )}
      </div>
      {showCreateSite && <SiteCreate onComplete={siteCreated} />}
      {showEdit && Array.isArray(sites.data?.sites) && sites.summaryQuery?.data && (
        <SiteGroupCreate
          updating={true}
          group={group}
          onComplete={(created) => {
            if (created) {
              sites.refetch().then(() => {
                toasts.addTopLeft('The site group was successfully updated')
                setShowEdit(false)
              })
            } else {
              setShowEdit(false)
            }
          }}
        />
      )}
    </>
  )
}
