import { useEffect } from 'react'
import { useAppState } from '~/state'
import { PhotoItem } from './types'

export const useIssuesFilmStripFlyToImage = (selectedPhoto: PhotoItem) => {
  const { timeline, map } = useAppState()


  function getHeightOffset() {
    if (!timeline.activeSurvey || timeline.activeSurvey.reconstructions.length === 0) {
      return map.imageOffsetForBulkTagging.current
    }
    const heightOffset = timeline.activeSurvey.reconstructions[0].manualTranslation.altitude
    return heightOffset + map.imageOffsetForBulkTagging.current
  }

  // Fly to selected photo.
  useEffect(() => {
    if (!selectedPhoto) {
      return
    }

    const photo = selectedPhoto
    const quat = new Cesium.Quaternion(photo.qx, photo.qy, photo.qz, photo.qw)
    
    if(quat.x === 0 && quat.y === 0 && quat.z === 0 && quat.w === 0) {
      map.viewer.camera.flyTo({
        destination: Cesium.Cartesian3.fromDegrees(photo.longitude, photo.latitude, photo.altitude + getHeightOffset()),
        duration: 0.25,
      })
      return
    }

    // const dirForward = new Cesium.Cartesian3(
    //   2 * (quat.x * quat.z + quat.w * quat.y),
    //   2 * (quat.y * quat.z - quat.w * quat.x),
    //   1 - 2 * (quat.x * quat.x + quat.y * quat.y)
    // )
    // const dirUp = new Cesium.Cartesian3(
    //   2 * (quat.x * quat.y - quat.w * quat.z),
    //   1 - 2 * (quat.x * quat.x + quat.z * quat.z),
    //   2 * (quat.y * quat.z + quat.w * quat.x)
    // )
    

    map.viewer.camera.flyTo({
      destination: Cesium.Cartesian3.fromDegrees(photo.longitude, photo.latitude, photo.altitude + getHeightOffset()),
      orientation: Cesium.HeadingPitchRoll.fromDegrees(photo.yaw, photo.pitch, photo.roll),
      duration: 0.25,
    })
  }, [selectedPhoto?.imageID, map.imageOffsetForBulkTagging.current])
}
