import dayjs from 'dayjs'
import { Config } from '~/config'
import { MeasurementSystem } from '~/models'

export function formatTemp(temp: number, includeSymbol: boolean = false, measurementSystem = Config.DefaultMeasurementSystem): string | number {
  let toUse = temp
  if (measurementSystem === MeasurementSystem.Imperial) {
    toUse = (temp * 9) / 5 + 32
  }

  toUse = Math.round(toUse)
  if (includeSymbol) {
    return toUse + '°'
  }

  return toUse
}

export function formatProbOfPrecipitation(
  probOfPrecipitation: number,
  includeSymbol: boolean = false
): string | number {
  let toUse = Math.round(probOfPrecipitation * 100)
  if (includeSymbol) {
    return toUse + '%'
  }

  return toUse
}

export function formatRain(rainInMM: number, includeSymbol: boolean = false, hideZero: boolean = false): string | number {
  console.log('Rain', rainInMM)
  let toUse = Math.round(rainInMM)
  if (includeSymbol) {
    if(hideZero && toUse === 0) {
      return '-'
    }
    return toUse + ' mm'
  }

  return toUse
}

export function formatWindSpeed(speed: number, includeSymbol: boolean = false, measurementSystem = Config.DefaultMeasurementSystem): string | number {
  let toUse = speed
  if (measurementSystem === MeasurementSystem.Imperial) {
    toUse *= 0.621371
  }

  toUse = Math.round(toUse)
  if (includeSymbol) {
    if (measurementSystem === MeasurementSystem.Imperial) {
      return toUse + ' mph'
    } else {
      return toUse + ' kph'
    }
  }

  return toUse
}

export function formatWeatherIcon(icon: string, position: Cesium.Cartesian3): string {
  if (!position) {
    return `https://openweathermap.org/img/wn/${icon}d@2x.png`
  }
  const carto = Cesium.Cartographic.fromCartesian(position)
  const longitude = Cesium.Math.toDegrees(carto.longitude)
  const positionOffsetMinutes = (longitude / 15) * 60
  const utcOffsetInMinutes = dayjs().utcOffset()
  const date = dayjs().subtract(utcOffsetInMinutes, 'minutes').add(positionOffsetMinutes, 'minutes')
  const hour = date.hour()
  const isDay = hour >= 6 && hour < 19

  return `https://openweathermap.org/img/wn/${icon}${isDay ? 'd' : 'd'}@2x.png`
}
