import React from 'react'
import { useUser, ClientPermission } from '~/base'
import { AnalyticsPanel, DrawerListContent } from '~/components'
import { useAppState } from '~/state'
import { RiskType, SiteQuerySite } from '~/models'
import { ExpandedPanel } from '~/state/use-drawer-state'

interface IssuesDrawerListProps {
  target: 'site' | 'asset'
  site?: SiteQuerySite
  refetch: () => Promise<any>
}

export const IssuesDrawerList = (props: IssuesDrawerListProps) => {
  const { issues, drawer } = useAppState()
  // const location = useLocation()
  const user = useUser()
  const isDemoSite = props.site?.isDemoSite
  const canList = user.hasPermission(ClientPermission.IssuesList)
  const canAdd = !isDemoSite && user.hasPermission(ClientPermission.IssuesCreate)
  
  // const entity =
  //   props.target === 'site' ? timeline.activeSurvey : timeline.activeSurvey?.assets.find((a) => a.id === view.assetID)

  if (!canList) {
    return null
  }

  return (
    <DrawerListContent
      loading={false}
      numEntities={issues.issues.length}
      panelType={ExpandedPanel.Issues}
      plural='issues'
      singular='issue'
      title='Issues'
      canAdd={canAdd}
      canBulkAdd={canAdd}
      onAdd={() => {
        drawer.setExpandedPanel(ExpandedPanel.Photos)
      }}
      onBulkAdd={() => {
        issues.setAddingBulk(true)
      }}
      emptyButCanAddText='Select a photo to add an issue.'
      emptyButCantAddText='There are no issues to display.'
    >
      <AnalyticsPanel condensed counts={{
        high: issues.issues.filter(x => x.riskType === RiskType.High).length,
        medium: issues.issues.filter(x => x.riskType === RiskType.Medium).length,
        low: issues.issues.filter(x => x.riskType === RiskType.Low).length,
      }} />
      {/* <Transition width={120} in={!!entity}>
        {[...issues.issues]
          .reverse()
          .slice(0, 1)
          .map((issue) => {
            return (
              <ItemTag
                riskType={issue.riskType}
                title={issue.name || `${issue.defectType}${issue.defectSubType ? ' - ' + issue.defectSubType : ''}`}
                key={issue.id}
                tagType='issue'
                onClick={() => {
                  let url = ''
                  if (view.assetID) {
                    url = `/${view.siteID}/${view.surveyID}/assets/${view.assetID}/issue/${issue.id}`
                  } else {
                    url = `/${view.siteID}/${view.surveyID}/issue/${issue.id}`
                  }

                  if (view.showingComments) {
                    url += '/comments'
                  }

                  location.setLocation(url)
                }}
              />
            )
          })}
      </Transition> */}
    </DrawerListContent>
  )
}
