import React, { useEffect, useRef, useState } from 'react'
import { useAppState, BaseLayerType, BaseLayerSubType } from '~/state'
import { getTracking, DrawerState, Switch, useLocation } from '~/components'
import MAP_TILE_DRAWN from '../images/map-tile-drawn.svg'
import MAP_TILE_PHOTO_MODEL from '../images/map-tile-photo-model.svg'
import MAP_TILE_LIDAR from '../images/lidar.png'
import COMPONENT_DRAW_ICON from '../images/component-draw-48.png'
import COMPONENT_FILL_ICON from '../images/component-fill-48.png'
import MAP_TILE_SATELLITE from '../images/map-tile-satellite.svg'
import MAP_TILE_BING from '../images/bing-logo.png'
import MAP_TILE_MAPBOX from '../images/mapbox-logo.png'
import DRAW_BOUNDARIES_IN_2D_ICON from '../images/boundaries-in-2d.png'
import DRAW_BOUNDARIES_IN_3D_ICON from '../images/draw-boundaries-in-3d.png'
import DRAW_MZ_IN_2D_ICON from '../images/mz-in-2d.png'
import DRAW_MZ_IN_3D_ICON from '../images/mz-in-3d.png'
import DRAW_ISSUES_ICON from '../images/icon-issues-draw.png'
import MEASUREMENTS_DRAW_ICON from '../images/icon-measurements-draw.png'
import { useUser } from '~/base'
import { ExpandedPanel } from '~/state/use-drawer-state'
import { Currency } from '~/models'

interface MapControlsProps {
  sideBarsVisibleToggle: (b: boolean) => void
}

export const MapControls = (props: MapControlsProps) => {
  const {
    map,
    drawer,
    boundaryState,
    monitoringZoneState,
    components: componentState,
    timeline,
    sites,
    annotations,
    issues,
    view,
    site,
  } = useAppState()
  const location = useLocation()
  const user = useUser()
  const [showLayersDropdown, setShowLayersDropdown] = useState<boolean>(false)
  const [showSettings, setShowSettings] = useState<boolean>(false)
  const [fullscreen, setFullscreen] = useState<boolean>(false)
  const settingsRef = useRef<HTMLDivElement>()
  const layersRef = useRef<HTMLDivElement>()


  useEffect(() => {
    if (!showLayersDropdown && !showSettings) {
      return
    }
    function handleClickOutside(e: MouseEvent) {
      if(e.defaultPrevented) {
        return
      }
      if (showLayersDropdown) {
        if (settingsRef.current && !layersRef.current.contains(e.target as any)) {
          setShowLayersDropdown(false)
        }
      }
      if (showSettings) {
        if (layersRef.current && !settingsRef.current.contains(e.target as any)) {
          setShowSettings(false)
        }
      }
    }
    window.addEventListener('click', handleClickOutside)
    return () => {
      window.removeEventListener('click', handleClickOutside)
    }
  }, [settingsRef, layersRef, showLayersDropdown, showSettings])

  const resetView = () => {
    map.setShowLidar(false)
    map.setBaseLayerType(BaseLayerType.Satellite).then(() => {
      map.jumpTo(sites.getBounds(user.org.currency !== Currency.Usd))
      location.setLocation('/')
    })
    getTracking().event({
      category: 'Button',
      action: `User clicked all sites map button`,
      label: 'Map Controls',
    })
  }

  const handleFullscreen = () => {
    if (fullscreen) {
      setFullscreen(false)
      props.sideBarsVisibleToggle(true)
      drawer.set(DrawerState.Normal, DrawerState.Normal)
    } else {
      setFullscreen(true)
      props.sideBarsVisibleToggle(false)
      drawer.set(DrawerState.Hidden, DrawerState.Hidden)
    }
  }

  const handleMapControlsPosition = () => {
    const screenWidth = window.innerWidth
    const screenRemaining = screenWidth - 724 - 250
    const controlsPosition = 724 + (screenRemaining - 400) / 2

    if (drawer.rightExpanded) {
      return { right: controlsPosition, left: 'auto' }
    }

    if (drawer.leftExpanded) {
      return { left: controlsPosition }
    }

    return null
  }

  return (
    <div className='map-controls' style={handleMapControlsPosition()}>
      <div
        className='dropdown'
        onClick={() => {
          map.setShowMeasure(false)
        }}
      >
        <div
          className={`map-control dropdown ${map.showLidar ? 'lidar' : map.baseLayer} ${
            showLayersDropdown ? 'active' : ''
          }`}
          onClick={(e) => {
            e.preventDefault()
            setShowLayersDropdown(!showLayersDropdown)
            setShowSettings(false)
            getTracking().event({
              category: 'Button',
              action: `User clicked map views dropdown button`,
              label: 'Map Controls',
            })
          }}
          title='Map views'
          onDoubleClick={() => {
            if (!view.siteID) {
              return
            }
            if (map.baseLayer === BaseLayerType.PhotoModel) {
              map.setBaseLayerType(BaseLayerType.Satellite)
            } else {
              map.setBaseLayerType(BaseLayerType.PhotoModel)
            }
          }}
        >
          <div
            className={`map-control-dropdown dropdown-menu`}
            style={{ display: showLayersDropdown ? 'block' : 'none' }}
            ref={layersRef}
          >
            <div className='map-control-dropdown-title'>2D Views</div>
            <div
              className='map-control-dropdown-item'
              onClick={() => {
                map.setShowLidar(false)
                map.setBaseLayerType(BaseLayerType.Street)
              }}
            >
              <img src={MAP_TILE_DRAWN} />
              <p>Streets</p>
              {map.baseLayer === BaseLayerType.Street && <div />}
            </div>
            <div
              className='map-control-dropdown-item'
              onClick={() => {
                map.setShowLidar(false)
                map.setBaseLayerType(BaseLayerType.Satellite)
              }}
            >
              <img src={MAP_TILE_SATELLITE} />
              <p>Satellite</p>
              {map.baseLayer === BaseLayerType.Satellite && <div />}
            </div>
            {view.siteID && (
              <>
                <div className='map-control-dropdown-title'>3D Views</div>

                <div
                  className='map-control-dropdown-item'
                  onClick={() => {
                    map.setShowLidar(false)
                    map.setBaseLayerType(BaseLayerType.PhotoModel)
                  }}
                >
                  <img src={MAP_TILE_PHOTO_MODEL} />
                  <p>Photo model</p>
                  {map.baseLayer === BaseLayerType.PhotoModel && !map.showLidar && <div />}
                </div>
                {timeline.lidarTimelime.length > 0 && (
                  <div
                    className='map-control-dropdown-item'
                    onClick={() => {
                      if (view.issueID) {
                        location.setLocation(`/${site.site.id}/${timeline.activeSurvey.id}`)
                      }
                      drawer.setExpandedPanel(ExpandedPanel.None)
                      map.setShowLidar(true)
                    }}
                  >
                    <img src={MAP_TILE_LIDAR} />
                    <p>Lidar</p>
                    {map.showLidar && <div />}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <div
        className='map-control zoom'
        onClick={() => {
          resetView()
        }}
        title='Show all sites'
      >
        <i className='material-icons'>map</i>
      </div>
      {!map.showLidar && !user.org.isBasic && (
        <>
          <div
            className={`map-control dropdown settings ${showSettings ? 'active' : ''}`}
            onClick={(e) => {
              if (e.defaultPrevented) {
                return
              }
              e.preventDefault()
              setShowSettings(!showSettings)
              setShowLayersDropdown(false)
              getTracking().event({
                category: 'Button',
                action: `User clicked map settings dropdown button`,
                label: 'Map Controls',
              })
            }}
            title='Map settings'
          >
            <i className='material-icons'>layers</i>
            <div
              className={`map-control-dropdown dropdown-menu wide`}
              style={{ display: showSettings ? 'flex' : 'none' }}
              ref={settingsRef}
            >
              <div className='map-control-dropdown-column'>
                <div className='map-control-dropdown-title'>Imagery Provider</div>
                <div className='map-control-dropdown-item switch' onClick={(e) => e.preventDefault()}>
                  <img src={MAP_TILE_MAPBOX} />
                  <Switch
                    name='use-mapbox'
                    label='Mapbox'
                    checked={map.subBaseLayer === BaseLayerSubType.MapBox}
                    onChange={() => {
                      map.setSubBaseLayerType(BaseLayerSubType.MapBox)
                    }}
                  />
                </div>
                <div className='map-control-dropdown-item switch' onClick={(e) => e.preventDefault()}>
                  <img src={MAP_TILE_BING} />
                  <Switch
                    name='use-bing'
                    label='Bing'
                    checked={map.subBaseLayer === BaseLayerSubType.Bing}
                    onChange={() => {
                      map.setSubBaseLayerType(BaseLayerSubType.Bing)
                    }}
                  />
                </div>
                <div className='map-control-dropdown-title'>Sites &amp; Assets</div>
                <div className='map-control-dropdown-item switch' onClick={(e) => e.preventDefault()}>
                  <img src={DRAW_BOUNDARIES_IN_2D_ICON} />
                  <Switch
                    name='draw-boundaries-in-2d'
                    label='Boundaries in 2D'
                    checked={boundaryState.drawIn2D}
                    onChange={() => {
                      boundaryState.setDrawIn2D(!boundaryState.drawIn2D)
                    }}
                  />
                </div>
                <div className='map-control-dropdown-item switch' onClick={(e) => e.preventDefault()}>
                  <img src={DRAW_BOUNDARIES_IN_3D_ICON} />
                  <Switch
                    name='draw-boundaries-in-3d'
                    label='Boundaries in 3D'
                    checked={boundaryState.drawIn3D}
                    onChange={() => {
                      boundaryState.setDrawIn3D(!boundaryState.drawIn3D)
                    }}
                  />
                </div>
                <div className='map-control-dropdown-item switch' onClick={(e) => e.preventDefault()}>
                  <img src={DRAW_MZ_IN_2D_ICON} />
                  <Switch
                    name='draw-mz-in-2d'
                    label='Monitoring Zones in 2D'
                    checked={monitoringZoneState.drawIn2D}
                    onChange={() => {
                      monitoringZoneState.setDrawIn2D(!monitoringZoneState.drawIn2D)
                    }}
                  />
                </div>
                <div className='map-control-dropdown-item switch' onClick={(e) => e.preventDefault()}>
                  <img src={DRAW_MZ_IN_3D_ICON} />
                  <Switch
                    name='draw-mz-in-3d'
                    label='Monitoring Zones in 3D'
                    checked={monitoringZoneState.drawIn3D}
                    onChange={() => {
                      monitoringZoneState.setDrawIn3D(!monitoringZoneState.drawIn3D)
                    }}
                  />
                </div>
              </div>
              <div className='map-control-dropdown-column'>
                <div className='map-control-dropdown-title'>Issues</div>
                <div className='map-control-dropdown-item switch' onClick={(e) => e.preventDefault()}>
                  <img src={DRAW_ISSUES_ICON} />
                  <Switch
                    name='show-locations'
                    label='Draw issues'
                    checked={issues.draw}
                    onChange={() => {
                      issues.setDraw(!issues.draw)
                    }}
                  />
                </div>
                <div className='map-control-dropdown-title'>Components</div>
                <div className='map-control-dropdown-item switch' onClick={(e) => e.preventDefault()}>
                  <img src={COMPONENT_DRAW_ICON} />
                  <Switch
                    name='show-locations'
                    label='Draw boundaries'
                    checked={componentState.draw}
                    onChange={() => {
                      componentState.setDraw(!componentState.draw)
                    }}
                  />
                </div>
                <div className='map-control-dropdown-item switch' onClick={(e) => e.preventDefault()}>
                  <img src={COMPONENT_FILL_ICON} />
                  <Switch
                    name='show-locations'
                    label='Fill boundaries'
                    checked={componentState.fill}
                    onChange={() => {
                      componentState.setFill(!componentState.fill)
                    }}
                  />
                </div>
                <div className='map-control-dropdown-title'>Annotations</div>
                <div className='map-control-dropdown-item switch' onClick={(e) => e.preventDefault()}>
                  <img src={MEASUREMENTS_DRAW_ICON} />
                  <Switch
                    name='draw-annotations'
                    label='Draw annotations'
                    checked={annotations.draw}
                    onChange={() => {
                      annotations.setDraw(!annotations.draw)
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            onClick={() => {
              map.setShowMeasure(!map.showMeasure)
            }}
            className={`map-control fullscreen  ${map.showMeasure ? 'active' : ''}`}
          >
            <i className='material-icons'>straighten</i>
          </div>
          <div
            className={`map-control fullscreen ${fullscreen ? 'active' : ''}`}
            onClick={() => {
              handleFullscreen()
              getTracking().event({
                category: 'Button',
                action: `User clicked fullscreen map button`,
                label: 'Map Controls',
              })
            }}
            title='Fullscreen'
          >
            {fullscreen ? (
              <i className='material-icons'>visibility</i>
            ) : (
              <i className='material-icons'>visibility_off</i>
            )}
          </div>
          <div
            className='map-control zoom'
            onClick={() => {
              const cropCanvas = (
                sourceCanvas: HTMLCanvasElement,
                left: number,
                top: number,
                width: number,
                height: number
              ) => {
                let destCanvas = document.createElement('canvas')
                destCanvas.width = width
                destCanvas.height = height
                destCanvas.getContext('2d').drawImage(
                  sourceCanvas,
                  left,
                  top,
                  width,
                  height, // source rect with content to crop
                  0,
                  0,
                  width,
                  height
                ) // newCanvas, same size as source rect
                return destCanvas
              }

              const data = cropCanvas(
                map.viewer.canvas,
                240,
                0,
                map.viewer.canvas.width - 480,
                map.viewer.canvas.height
              ).toDataURL('image/png')
              const hiddenElement = document.createElement('a')
              hiddenElement.href = data
              hiddenElement.target = '_blank'
              hiddenElement.download = 'capture.png'
              hiddenElement.click()
            }}
            title='Capture screenshot'
          >
            <i className='material-icons'>add_a_photo</i>
          </div>
        </>
      )}
    </div>
  )
}
