import React, { useMemo, useState } from 'react'
import { ClientPermission, useUser } from '~/base'
import { SiteQueryBoundary } from '~/models'
import { BoundaryRenderState, BoundaryRenderType, useAppState } from '~/state'
import { DrawerPopoutList, DrawerPopoutListCard } from '../drawer'
import { SearchBox, Switch } from '../forms'
import { calculatePolygonArea, useLocation } from '../helpers'
import { BoundariesRemove } from './boundaries-remove-modal'
import { BOUNDARIES_ALLOWED_ORG_IDS, BoundaryAdditionalData } from './boundaries-extension-data'
import {
  BoundaryCategory,
  BoundarySubcategory,
  getBoundaryCategory,
  getBoundaryColor,
  getBoundarySubcategory,
} from './boundary-categories'
import { handleAreaFormat } from '../measure'
// import { parseDXF } from '../dxf'

interface BoundariesFullListProps {
  boundaries: SiteQueryBoundary[]
  refetch: () => Promise<void>
  boundaryToEdit: SiteQueryBoundary
  setBoundaryToEdit: (b: SiteQueryBoundary) => void
}

export const BoundariesFullList = (props: BoundariesFullListProps) => {
  const user = useUser()
  const canSeeAdditionalData = BOUNDARIES_ALLOWED_ORG_IDS.findIndex((x) => x === user.currentOrgId) !== -1

  if (canSeeAdditionalData) {
    return <BoundariesFullListAdditional {...props} />
  } else {
    return <BoundariesFullListStandard {...props} />
  }
}

const BoundariesFullListStandard = (props: BoundariesFullListProps) => {
  const { drawer, view } = useAppState()
  const [boundaryToRemove, setBoundaryToRemove] = useState<SiteQueryBoundary>()
  const [search, setSearch] = useState<string | undefined>()

  const boundaries = useMemo(() => {
    const lowerSearch = (search || '').toLocaleLowerCase()
    const sorted = [...props.boundaries]
      .filter((a) => {
        if (lowerSearch === '') {
          return true
        }

        return a.name.toLocaleLowerCase().includes(lowerSearch)
      })
      .sort((a, b) => {
        return a.name > b.name ? 1 : -1
      })
    return sorted
  }, [props.boundaries, search])

  const Controls = useMemo(() => {
    return <SearchBox search={search} setSearch={setSearch} />
  }, [search])

  return (
    <>
      <DrawerPopoutList
        width={360}
        title='Boundaries'
        total={props.boundaries.length}
        count={boundaries.length}
        emptyText='There are no boundaries to display.'
        controls={Controls}
      >
        {boundaries.map((b) => {
          return (
            <BoundaryCard
              key={b.id + view.surveyID}
              boundary={b}
              setBoundaryToEdit={props.setBoundaryToEdit}
              setBoundaryToRemove={setBoundaryToRemove}
              active={b.id === props.boundaryToEdit?.id || b.id === boundaryToRemove?.id}
            />
          )
        })}
      </DrawerPopoutList>
      {boundaryToRemove && (
        <BoundariesRemove
          boundary={boundaryToRemove}
          refetch={() => {
            return props.refetch().then(() => {
              if (props.boundaries.length === 1) {
                drawer.closeExpandedPanel()
              }
            })
          }}
          close={() => {
            setBoundaryToRemove(undefined)
          }}
        />
      )}
    </>
  )
}

type Boundary = SiteQueryBoundary &
  BoundaryAdditionalData & {
    fullCategory: BoundaryCategory
    fullSubcategory: BoundarySubcategory
  }

type BoundariesBySubcategory = {
  subcategories: { [subcategory: string]: Boundary[] }
  total: number
}

type BoundariesByCategory = {
  categories: { [subcategory: string]: BoundariesBySubcategory }
  total: number
}

export const BoundariesFullListAdditional = (props: BoundariesFullListProps) => {
  const { drawer, view, boundaryState } = useAppState()
  const [boundaryToRemove, setBoundaryToRemove] = useState<SiteQueryBoundary>()
  const [search, setSearch] = useState<string | undefined>()
  const [rerender, setRerender] = useState(0)

  // useEffect(() => {
  //   parseDXF()
  // }, [])

  const boundariesWithAdditional: Boundary[] = useMemo(() => {
    const sorted: Boundary[] = [...props.boundaries]
      .map((b) => {
        const data = b.additional
        const category = getBoundaryCategory(data?.category)
        const boundary: Boundary = {
          ...b,
          ...data,
          fullCategory: category,
          fullSubcategory: getBoundarySubcategory(category, data?.subcategory),
        }
        return boundary
      })
      .sort((a, b) => {
        return a.name > b.name ? 1 : -1
      })
    return sorted
  }, [props.boundaries])

  const boundariesByCategory: BoundariesByCategory = useMemo(() => {
    const lowerSearch = (search || '').toLocaleLowerCase()
    const sorted = [...boundariesWithAdditional]
      .filter((a) => {
        if (lowerSearch === '') {
          return true
        }

        return (
          a.name.toLocaleLowerCase().includes(lowerSearch) ||
          a.fullCategory.name.toLocaleLowerCase().includes(lowerSearch) ||
          a.fullSubcategory.name.toLocaleLowerCase().includes(lowerSearch)
        )
      })
      .sort((a, b) => {
        if (a.category === b.category) {
          if (a.subcategory === b.subcategory) {
            return a.name > b.name ? 1 : -1
          }

          return a.subcategory > b.subcategory ? 1 : -1
        }

        return a.category > b.category ? 1 : -1
      })

    const data: BoundariesByCategory = {
      categories: {},
      total: 0,
    }
    sorted.forEach((b) => {
      const fullCategoryName = b?.fullCategory?.name
      if (!data.categories[fullCategoryName]) {
        data.categories[fullCategoryName] = {
          subcategories: {},
          total: 0,
        }
      }

      const fullSubcategoryName = b?.fullSubcategory?.name
      if (!data.categories[fullCategoryName].subcategories[fullSubcategoryName]) {
        data.categories[fullCategoryName].subcategories[fullSubcategoryName] = []
      }

      data.categories[fullCategoryName].subcategories[fullSubcategoryName].push(b)
      data.categories[fullCategoryName].total++
      data.total++
    })

    return data
  }, [boundariesWithAdditional, search])

  const Controls = useMemo(() => {
    return <SearchBox search={search} setSearch={setSearch} style={{ width: '100%', paddingRight: '1rem' }} />
  }, [search])

  const showLabels = localStorage.getItem('show-boundary-labels') !== 'false'

  return (
    <>
      <DrawerPopoutList
        width={360}
        title='Boundaries'
        total={props.boundaries.length}
        count={boundariesByCategory.total}
        emptyText='There are no boundaries to display.'
        controls={Controls}
      >
        <h3 className='boundaries-subcategory-title'>
          Show Labels
          <Switch
            name='show-boundary-labels'
            label=''
            className='boundaries-category-title-switch'
            left
            title='Display on Map'
            checked={showLabels}
            onChange={() => {
              localStorage.setItem('show-boundary-labels', showLabels ? 'false' : 'true')
              setRerender(rerender + 1)
              boundaryState.setRenderType(showLabels ? BoundaryRenderType.Lines : BoundaryRenderType.LinesAndLabels)
            }}
          />
        </h3>

        {...Object.keys(boundariesByCategory.categories).map((categoryName) => {
          const category = boundariesByCategory.categories[categoryName]
          return (
            <>
              <h3 className='boundaries-category-title'>
                {categoryName}
                {/* <Switch
                  name={'show-' + categoryName}
                  label=''
                  className='boundaries-category-title-switch'
                  left
                  title='Display on Map'
                  checked={false}
                  onChange={() => {}}
                /> */}
              </h3>
              {Object.keys(category.subcategories).map((subcategoryName) => {
                const subcategory = category.subcategories[subcategoryName]
                const switchName = 'show-' + subcategoryName
                const isChecked = localStorage.getItem(switchName) !== 'false'
                return (
                  <>
                    <h3 className='boundaries-subcategory-title'>
                      {subcategoryName}{' '}
                      {isChecked
                        ? ''
                        : subcategory.length === 1
                        ? '(1 boundary)'
                        : `(${subcategory.length} boundaries)`}
                      <Switch
                        name={switchName}
                        label=''
                        className='boundaries-category-title-switch'
                        left
                        title='Display on Map'
                        checked={isChecked}
                        onChange={() => {
                          localStorage.setItem(switchName, isChecked ? 'false' : 'true')
                          setRerender(rerender + 1)
                          const currentState = boundaryState.renderState
                          boundaryState.setRenderState(BoundaryRenderState.None)

                          setTimeout(() => {
                            boundaryState.setRenderState(currentState)
                          }, 0)
                        }}
                      />
                    </h3>

                    {isChecked &&
                      subcategory.map((b) => {
                        return (
                          <BoundaryAdditionalCard
                            key={b.id + view.surveyID}
                            boundary={b}
                            setBoundaryToEdit={props.setBoundaryToEdit}
                            setBoundaryToRemove={setBoundaryToRemove}
                            active={
                              b.id === props.boundaryToEdit?.id ||
                              b.id === boundaryToRemove?.id ||
                              b.id === view.boundaryID
                            }
                          />
                        )
                      })}
                  </>
                )
              })}
              <br />
            </>
          )
        })}
      </DrawerPopoutList>
      {boundaryToRemove && (
        <BoundariesRemove
          boundary={boundaryToRemove}
          refetch={() => {
            return props.refetch().then(() => {
              if (props.boundaries.length === 1) {
                drawer.closeExpandedPanel()
              }
            })
          }}
          close={() => {
            setBoundaryToRemove(undefined)
          }}
        />
      )}
    </>
  )
}

interface BoundaryCardProps {
  active: boolean
  boundary: SiteQueryBoundary
  setBoundaryToEdit: (b: SiteQueryBoundary) => void
  setBoundaryToRemove: (b: SiteQueryBoundary) => void
}

function BoundaryCard(props: BoundaryCardProps) {
  const user = useUser()
  const { view, site } = useAppState()
  const isDemoSite = site.site?.isDemoSite
  const canEdit = !isDemoSite && user.hasPermission(ClientPermission.BoundariesUpdate)
  const canRemove = !isDemoSite && user.hasPermission(ClientPermission.BoundariesRemove)
  const b = props.boundary
  const area = handleAreaFormat(
    calculatePolygonArea(b.points.map((p) => Cesium.Cartesian3.fromDegrees(p.longitude, p.latitude, 0)))
  )

  return (
    <DrawerPopoutListCard key={b.id + view.surveyID + view.assetID} className='boundaries-card' active={props.active}>
      <div className='boundaries-card-upper'>{b.name}</div>
      <div className='boundaries-card-middle'>{area}</div>
      <div className='boundaries-card-lower'>
        {canEdit && (
          <i
            title='Update'
            className='material-icons'
            onClick={(e) => {
              e.preventDefault()
              props.setBoundaryToEdit(b)
            }}
          >
            edit
          </i>
        )}
        {canRemove && (
          <i
            title='Remove'
            className='material-icons'
            onClick={(e) => {
              e.preventDefault()
              props.setBoundaryToRemove(b)
            }}
          >
            delete
          </i>
        )}
      </div>
    </DrawerPopoutListCard>
  )
}

function BoundaryAdditionalCard(props: BoundaryCardProps) {
  const location = useLocation()
  const { view } = useAppState()
  const b = props.boundary
  const area = handleAreaFormat(
    calculatePolygonArea(b.points.map((p) => Cesium.Cartesian3.fromDegrees(p.longitude, p.latitude, 0)))
  )

  let data: SiteQueryBoundary['additional']
  let haveData = false
  if (b.additional) {
    data = b.additional
    haveData = true
  }

  const cat = getBoundaryCategory(b.additional?.category)
  const subCat = getBoundarySubcategory(cat, b.additional?.subcategory)
  const color = getBoundaryColor(cat, subCat)

  // const hasMarkersRequiringAppoval = b.markers.findIndex(x => x.approvalStatus === BoundaryMarkerApprovalStatus.Pending) !== -1

  return (
    <DrawerPopoutListCard
      key={b.id + view.surveyID + view.assetID}
      className='boundaries-additional-card'
      style={haveData ? { borderLeftColor: color.boundary } : {}}
      active={props.active}
      title={data.sourceInformation}
      onClick={() => {
        location.setLocation(`/${view.siteID}/${view.surveyID}/boundary/${b.id}`)
      }}
      onMouseEnter={() => {
        const prs = window.__assetiBoundaryPolygonRenderers
        if (!prs) {
          return
        }

        const boundary = prs.site[b.id]
        if (boundary) {
          //boundary.renderer.setActive(true)
          boundary.renderer.setFill(true)
        }
      }}
      onMouseLeave={() => {
        const prs = window.__assetiBoundaryPolygonRenderers
        if (!prs) {
          return
        }

        const boundary = prs.site[b.id]
        if (boundary && view.boundaryID !== b.id) {
          //boundary.renderer.setActive(false)
          boundary.renderer.setFill(false)
        }
      }}
    >
      <div className='boundaries-additional-card-top'>
        <div className='boundaries-additional-card-top-upper'>
          {b.name}
          <span>{area}</span>
          {/* {canRemove && (
            <i
              title='Remove'
              className='material-icons'
              onClick={(e) => {
                e.preventDefault()
                props.setBoundaryToRemove(b)
              }}
            >
              delete
            </i>
          )}
          {canEdit && (
            <i
              title='Update'
              className='material-icons'
              onClick={(e) => {
                e.preventDefault()
                props.setBoundaryToEdit(b)
              }}
            >
              edit
            </i>
          )} */}
        </div>
        {/* <div className='boundaries-additional-card-top-middle'>{data.subcategory}</div> */}
        <div className='boundaries-additional-card-top-lower'>
          {/* <span
            title={'Expiry date: ' + (data.expiry === 0 ? 'Never' : shortDate(data.expiry))}
            className={isExpired ? 'expired' : ''}
          >
            {data.expiry === 0 ? 'Never' : shortDate(data.expiry)}
          </span> */}
          {/* {data.pegged && <span className='pegged'>Pegged</span>} */}
        </div>
      </div>
      <div className='boundaries-additional-card-bottom'></div>
    </DrawerPopoutListCard>
  )
}
