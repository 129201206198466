import React from 'react'
import { fullDate, timeSinceShort } from '../helpers/format-date'
import { IssueCounterDots } from './issue-counter-dots'
import { WeatherSummaryForSitesQuery } from '~/models'
import { formatTemp } from '../helpers'
import { useUser } from '~/base'

interface OverviewStatsCardProps {
  numHighIssues: number
  numMediumIssues: number
  numLowIssues: number
  numAssets: number
  numSites?: number
  lastUploadTime: number | undefined
  style?: React.CSSProperties
  alwaysShowIssueCounts?: boolean
  canListIssues: boolean
  weather?: WeatherSummaryForSitesQuery['weatherSummaryForSites'][0]
  position?: Cesium.Cartesian3
}

export const OverviewStatsCard = (props: OverviewStatsCardProps) => {
  const user = useUser()
  const haveSites = props.numSites !== undefined
  const minimalIssues =
    (props.numHighIssues > 0 ? 1 : 0) + (props.numLowIssues > 0 ? 1 : 0) + (props.numMediumIssues > 0 ? 1 : 0) <= 1
  return (
    <div className='overview-stats-card' style={props.style}>
      {!haveSites && (
        <div className='overview-stats-card-section'>
          {(!props.weather || !props.canListIssues || minimalIssues) && (
            <>
              <i className='overview-stats-card-icon material-icons-outlined'>cloud_upload</i>
              <span
                className='overview-stats-card-last-uploaded'
                title={props.lastUploadTime ? fullDate(props.lastUploadTime) : ''}
              >
                {props.lastUploadTime ? timeSinceShort(props.lastUploadTime) : 'Never'}
              </span>
            </>
          )}
        </div>
      )}
      {haveSites && (
        <div className='overview-stats-card-section'>
          <i className='overview-stats-card-icon material-icons-outlined padded'>account_balance</i>
          <span className='overview-stats-card-asset-count'>{props.numSites}</span>
        </div>
      )}
      <div className='overview-stats-card-section'>
        <i className='overview-stats-card-icon material-icons-outlined padded'>domain</i>
        <span className='overview-stats-card-asset-count'>{props.numAssets}</span>
      </div>
      <div className='overview-stats-card-section'>
        {props.canListIssues && (
          <IssueCounterDots
            numHighIssues={props.numHighIssues}
            numMediumIssues={props.numMediumIssues}
            numLowIssues={props.numLowIssues}
            alwaysShowIssueCounts={props.alwaysShowIssueCounts}
          />
        )}
      </div>
      <div className='overview-stats-card-section-spacer'></div>
      {props.weather && (
        <div className='overview-stats-card-weather' title={props.weather.category}>
          <span className='overview-stats-card-weather-temp'>{formatTemp(props.weather.temp, true, user.org.measurementSystem)}</span>
          {/* {props.weather.rainMM >= 0 && <img src={formatWeatherIcon('09', props.position)} />} */}
          {/* <img src={formatWeatherIcon(props.weather.icon, props.position)} /> */}
          {/* <span className='overview-stats-card-weather-rain'>{formatRain(props.weather.rainMM || 10, true)}</span> */}
          <span className='overview-stats-card-weather-rain'>
            {props.weather.rainMM > 0 ? <i className='material-icons'>water_drop</i> : ''}
          </span>
          <span className='overview-stats-card-weather-wind'>
            {props.weather.gustsKMH > 40 ? <i className='material-icons'>air</i> : ''}
          </span>
        </div>
      )}
    </div>
  )
}
