import { useEffect } from 'react'
import { useAppState, OrthotileLayer, ProjectionType, TileLayer3D, Marker } from '~/state'
import { SiteQuerySite } from '~/models'

export function useSiteLayers(site: SiteQuerySite) {
  const { sites, map, timeline } = useAppState()

  useEffect(() => {
    if (!site || !timeline.activeSurvey || map.showLidar) {
      return
    }

    const rect = sites.getBoundsForSite(site)
    map.setHomeView(rect)

    // Create 2D layers.
    let layers: OrthotileLayer[] = []
    let tileLayers3D: TileLayer3D[] = []
    let markers: Marker[] = []
    if (map.projectionType === ProjectionType.Projection2D) {
      layers = layers.concat(
        timeline.activeSurvey.reconstructions
          .filter((r) => r.complete)
          .map((r) => map.addOrthotileLayer(r.orthoID, site.orgID))
      )
    }

    // Create 3D layers.
    if (map.projectionType === ProjectionType.Projection3D) {
      tileLayers3D = tileLayers3D.concat(
        timeline.activeSurvey.reconstructions
          .map((r) => {
            return map.add3DTiles(
              r.cesiumID, 
              r.manualTranslation, 
              r.clippingPlanesEnabled,
              r.clippingPlanesInset,
              r.terrainEnabled,
              r.aoi,
              r.useAligned,
              site.orgID)
          })
          .filter((x) => !!x)
      )
    }

    return () => {
      map.clearOrthotileLayers()
      map.clear3DTiles()
      markers.map((m) => {
        map.removeMarker(m)
      })
    }
  }, [site?.id, map.projectionType, timeline.activeSurvey?.id, map.showLidar])
}
