import React, { Dispatch, MutableRefObject, SetStateAction, useEffect, useState } from 'react'
import { PolygonColorRed } from '../components/polygon-renderer/polygon-renderer'
import { get3DNormalVector, pickFromPosition } from '../components/visualizations'
import { Point3Dt, PointT } from '~/models'
import { Config } from '../config'
import CAMERA_MARKER_ICON_2D_SELECTED from '../images/camera-marker-selected.png'
import CAMERA_MARKER_ICON_2D from '../images/camera-marker.png'
import CLUSTER_ICON_2D from '../images/cluster-marker.png'
import ISSUE_MARKER_ICON_HIGH_SELECTED from '../images/issue-icon-high-selected.png'
import ISSUE_MARKER_ICON_HIGH from '../images/issue-icon-high.png'
import ISSUE_MARKER_ICON_LOW_SELECTED from '../images/issue-icon-low-selected.png'
import ISSUE_MARKER_ICON_LOW from '../images/issue-icon-low.png'
import ISSUE_MARKER_ICON_MEDIUM_SELECTED from '../images/issue-icon-medium-selected.png'
import ISSUE_MARKER_ICON_MEDIUM from '../images/issue-icon-medium.png'
import ISSUE_MARKER_ICON_COMPLETED from '../images/issue-icon-completed.png'
import MARKER_ICON_2D_DARK from '../images/map-marker-dark.png'
import MARKER_ICON_2D from '../images/map-marker.png'
import MONITORING_ZONE_MARKER_ICON_2D from '../images/monitoring-zone-marker.png'
import INTERIOR_ICON from '../images/interior-icon.png'
import { expandRect } from './utils'
import { useUser } from '~/base'
import { getTileProxy, transform3DTiles } from '~/components'
import { getOffsetPolygon, insetPolygon } from '~/components/helpers/get-offset-polygon'
import earcut from 'earcut'

export { MARKER_ICON_2D_DARK, MARKER_ICON_2D }

export enum BaseLayerType {
  Street = 'street',
  Satellite = 'satellite',
  PhotoModel = 'photo-model',
}

export enum BaseLayerSubType {
  MapBox = 'map-box',
  Bing = 'bing',
}

type BaseLayerTypes = Promise<Cesium.TileMapServiceImageryProvider> | Promise<Cesium.IonImageryProvider>

type BaseLayerMap = {
  [key in BaseLayerType]: {
    [key in BaseLayerSubType]: () => BaseLayerTypes
  }
}

export enum ProjectionType {
  Projection2D,
  Projection3D,
}

export enum MarkerIconType {
  Map,
  MonitoringZone,
  Camera,
  IssueLow,
  IssueMedium,
  IssueHigh,
  IssueLowSelected,
  IssueMediumSelected,
  IssueHighSelected,
  PhotoSelected,
  None,
  IssueCompleted,
  Interior,
  MapDark,
}

export enum MarkerTypes {
  Issue = 'issue',
  Map = 'map',
}

export interface Marker {
  id: string
  surveyID: string
  label?: Cesium.Label
  billboard: Cesium.Billboard
  center: Cesium.Cartographic
  onClick?: () => void
  onRightClick?: LeftClickHandler
  siteName: string
  properties?: Cesium.PropertyBag
}

export interface Layer<T, P> {
  id: string
  layer: T
  provider: P
  movement?: Point3Dt
}

export type OrthotileLayer = Layer<Cesium.ImageryLayer, Promise<Cesium.TileMapServiceImageryProvider>>
export type TileLayer3D = Layer<Cesium.Cesium3DTileset, any>

export type LeftClickPosition = {
  windowPos: Cesium.Cartesian2
  cartesian: Cesium.Cartesian3
  cartographic: Cesium.Cartographic
  picked?: Cesium.Entity
}

export type LeftClickModelPosition = {
  position: Cesium.Cartesian3
  id: string
  normal: Cesium.Cartesian3
}

export type MouseMovePosition = {
  windowPos: Cesium.Cartesian2
  startWindowPos: Cesium.Cartesian2
  startCartesian: Cesium.Cartesian3
  cartesian: Cesium.Cartesian3
  cartographic: Cesium.Cartographic
  picked?: Cesium.Entity
}

export type LeftClickHandler = (pos: LeftClickPosition) => boolean
export type LeftClickModelHandler = (pos: LeftClickModelPosition) => void
export type MouseMoveHandler = (pos: MouseMovePosition) => void

export function useCesium() {
  Cesium.Ion.defaultAccessToken = Config.CesiumAPIKey

  // User info from global state.
  const userInfo = useUser()

  // State.

  // The type of layer, street, satellite or photo model.
  const [baseLayer, setBaseLayer] = useState<BaseLayerType>(BaseLayerType.Satellite)
  const [subBaseLayer, setSubBaseLayer] = useState<BaseLayerSubType>(BaseLayerSubType.Bing)

  // 2D or 3D.
  const [projectionType, setProjectionType] = useRefState<ProjectionType>(ProjectionType.Projection2D)
  const [morphing, setMorphing] = useState<boolean>(false)

  const [baseLayers] = useState<BaseLayerMap>(getDefaultBaseLayers())
  // Billboards are viewport-aligned images positioned in the 3D scene.
  const [markerBillboardCollection, setMarkerBillboardCollection] = useState<Cesium.BillboardCollection>(undefined)
  const [markerLabelCollection, setMarkerLabelCollection] = useState<Cesium.LabelCollection>(undefined)

  const [markers, setMarkers] = useRefState<Marker[]>([])

  const [clusteredMarkers, setClusteredMarkers] = useRefState<Marker[]>([])

  const [orthotileLayers, setOrthotileLayers] = useRefState<OrthotileLayer[]>([])

  const [tileLayers3D, setTileLayers3D] = useRefState<TileLayer3D[]>([])

  const [homeView, _setHomeView] = useRefState<Cesium.Rectangle>()

  const [viewer, setViewer] = useRefState<Cesium.Viewer>()

  const [eventHandler, setEventHandler] = useRefState<Cesium.ScreenSpaceEventHandler>()

  const [leftClickHandlers, setLeftClickHandlers] = useRefState<LeftClickHandler[]>([])
  const [rightClickHandlers, setRightClickHandlers] = useRefState<LeftClickHandler[]>([])
  const [leftClickModelHandlers, setLeftClickModelHandlers] = useRefState<LeftClickModelHandler[]>([])
  const [leftDownHandlers, setLeftDownHandlers] = useRefState<LeftClickHandler[]>([])
  const [leftUpHandlers, setLeftUpHandlers] = useRefState<LeftClickHandler[]>([])
  const [middleClickHandlers, setMiddleClickHandlers] = useRefState<LeftClickHandler[]>([])

  const [showControls, setShowControls] = useRefState(true)

  const [showMeasure, setShowMeasure] = useRefState(false)
  const [showLidar, setShowLidar] = useRefState(false)

  const [positionForBulkImageTagging, setPositionForBulkImageTagging] = useRefState(false)
  const [positionForBulkImageMoving, setPositionForBulkImageMoving] = useRefState(false)
  const [bulkTaggingFullscreen, setBulkTaggingFullscreen] = useRefState(false)
  const [imageOffsetForBulkTagging, setImageOffsetForBulkTagging] = useRefState(0)
  const [crashInfo, setCrashInfo] = useState<{
    err: string
    extra: object
  }>(undefined)

  function initialize(bounds: Cesium.Rectangle, containerName = 'cesiumContainer') {
    return initCesiumViewer(bounds, baseLayers, baseLayer, subBaseLayer, containerName).then((v) => {
      // Set the initial center point.
      v.camera.flyTo({
        destination: bounds,
        duration: 0,
      })

      v.canvas.addEventListener('webglcontextlost', () => {
        setCrashInfo({
          err: 'WebGL context lost',
          extra: {
            sceneMode: v.scene.mode,
          },
        })
      })

      v.cesiumWidget.showErrorPanel = (_title, message, error) => {
        setCrashInfo({
          err: error || message,
          extra: {
            sceneMode: v.scene.mode,
          },
        })
      }

      // Save the initial center point as home.
      _setHomeView(() => bounds)

      const collection = new Cesium.BillboardCollection({ scene: v.scene })
      setMarkerBillboardCollection(collection)

      const labelCollection = new Cesium.LabelCollection({ scene: v.scene })
      setMarkerLabelCollection(labelCollection)

      // Add the billboard collection for markers.
      v.scene.primitives.add(collection)
      v.scene.primitives.add(labelCollection)

      const eventHandler = new Cesium.ScreenSpaceEventHandler(v.canvas)
      eventHandler.setInputAction((movement: Cesium.ScreenSpaceEventHandler.PositionedEvent) => {
        const pos = movement.position as Cesium.Cartesian2

        handleLeftClick(pos)
      }, Cesium.ScreenSpaceEventType.LEFT_CLICK)

      eventHandler.setInputAction((movement: Cesium.ScreenSpaceEventHandler.PositionedEvent) => {
        const pos = movement.position as Cesium.Cartesian2

        handleRightClick(pos)
      }, Cesium.ScreenSpaceEventType.RIGHT_CLICK)

      eventHandler.setInputAction((movement: Cesium.ScreenSpaceEventHandler.PositionedEvent) => {
        const pos = movement.position as Cesium.Cartesian2
        handleLeftDown(pos)
      }, Cesium.ScreenSpaceEventType.LEFT_DOWN)

      eventHandler.setInputAction((movement: Cesium.ScreenSpaceEventHandler.PositionedEvent) => {
        const pos = movement.position as Cesium.Cartesian2
        handleLeftUp(pos)
      }, Cesium.ScreenSpaceEventType.LEFT_UP)

      eventHandler.setInputAction((movement: Cesium.ScreenSpaceEventHandler.PositionedEvent) => {
        const pos = movement.position as Cesium.Cartesian2
        handleMiddleClick(pos)
      }, Cesium.ScreenSpaceEventType.MIDDLE_CLICK)

      setViewer(() => v)
      setEventHandler(() => eventHandler)

      v.scene.morphStart.addEventListener(() => {
        setMorphing(true)
      })
      v.scene.morphComplete.addEventListener(() => {
        setMorphing(false)
      })
    })
  }

  function destroy() {
    eventHandler.current.destroy()
    viewer.current.entities.removeAll()
    viewer.current.scene.primitives.removeAll()
    viewer.current.destroy()

    setViewer(() => undefined)
    setEventHandler(() => undefined)
    setMarkerBillboardCollection(() => undefined)
    setMarkerLabelCollection(() => undefined)
    setBaseLayer(BaseLayerType.Satellite)
    setSubBaseLayer(BaseLayerSubType.Bing)
    setProjectionType(ProjectionType.Projection2D)
    setMarkers([])
    setClusteredMarkers([])
    setOrthotileLayers([])
    setTileLayers3D([])
    _setHomeView(undefined)
  }

  function setBaseLayerType(baseLayerType: BaseLayerType, keepCamera?: boolean): Promise<void> {
    if (baseLayerType == baseLayer) {
      return Promise.resolve()
    }

    return new Promise<any>((resolve) => {
      if (baseLayer === BaseLayerType.Street || baseLayer === BaseLayerType.Satellite) {
        viewer.current.imageryLayers.remove(viewer.current.imageryLayers.get(0), false)
      } else {
        viewer.current.imageryLayers.remove(viewer.current.imageryLayers.get(0), false)
      }

      if (baseLayerType === BaseLayerType.Street || baseLayerType === BaseLayerType.Satellite) {
        viewer.current.scene.terrainProvider = new Cesium.EllipsoidTerrainProvider({})
        viewer.current.imageryLayers.add(
          Cesium.ImageryLayer.fromProviderAsync(baseLayers[baseLayerType][subBaseLayer](), {}),
          0
        )
        setProjection(ProjectionType.Projection2D).then(resolve)
      } else {
        viewer.current.scene.terrainProvider = Cesium.createWorldTerrain()
        setProjection(ProjectionType.Projection3D, keepCamera).then(resolve)
        viewer.current.imageryLayers.add(
          Cesium.ImageryLayer.fromProviderAsync(baseLayers[BaseLayerType.Satellite][subBaseLayer](), {}),
          0
        )
      }

      setBaseLayer(() => baseLayerType)
    })
  }

  function setSubBaseLayerType(subType: BaseLayerSubType): Promise<void> {
    if (subType == subBaseLayer) {
      return Promise.resolve()
    }

    return new Promise<any>((resolve) => {
      viewer.current.imageryLayers.remove(viewer.current.imageryLayers.get(0), false)

      if (baseLayer === BaseLayerType.Street || baseLayer === BaseLayerType.Satellite) {
        viewer.current.scene.terrainProvider = new Cesium.EllipsoidTerrainProvider({})
        viewer.current.imageryLayers.add(Cesium.ImageryLayer.fromProviderAsync(baseLayers[baseLayer][subType](), {}), 0)
        setProjection(ProjectionType.Projection2D).then(resolve)
      } else {
        viewer.current.scene.terrainProvider = Cesium.createWorldTerrain()
        setProjection(ProjectionType.Projection3D).then(resolve)
        viewer.current.imageryLayers.add(
          Cesium.ImageryLayer.fromProviderAsync(baseLayers[BaseLayerType.Satellite][subType](), {}),
          0
        )
      }

      setSubBaseLayer(() => subType)
    })
  }

  function jumpTo(destination: Cesium.Rectangle) {
    viewer.current.camera.flyTo({
      destination,
      duration: 0,
    })

    if (viewer.current.scene.mode === Cesium.SceneMode.SCENE3D) {
      const startHeight = viewer.current.camera.positionCartographic.height
      viewer.current.scene.sampleHeight(viewer.current.camera.positionCartographic.clone(), [], 0.5)
      Cesium.sampleTerrain(viewer.current.terrainProvider, 11, [
        viewer.current.camera.positionCartographic.clone(),
      ]).then((res) => {
        const height =
          viewer.current.scene.sampleHeight(viewer.current.camera.positionCartographic.clone(), [], 0.5) || 0
        viewer.current.camera.flyTo({
          destination: Cesium.Cartesian3.fromRadians(
            viewer.current.camera.positionCartographic.longitude,
            viewer.current.camera.positionCartographic.latitude,
            startHeight + Math.max(res[0].height, height)
          ),
          duration: 0,
        })
      })
    }
  }

  function flyTo(lat: number, lng: number, height = 1e5, duration = 0.75) {
    viewer.current.camera.flyTo({
      destination: Cesium.Cartesian3.fromDegrees(lng, lat, height),
      duration: duration,
    })
  }

  function handleLeftClick(windowPos: Cesium.Cartesian2) {
    const picked = viewer.current.scene.pick(windowPos)

    if (picked) {
      let id = picked.id
      if (typeof id !== 'string') {
        id = ((picked.id as any) || {})._id || ''
      }
      id = id.replace('-label', '')
      const marker = markers.current.find((x) => x.id === id)
      if (marker && marker.onClick) {
        marker.onClick()
        return
      }
    }

    const ellipsoid = viewer.current.scene.globe.ellipsoid
    const cartesian = viewer.current.camera.pickEllipsoid(windowPos, ellipsoid)

    if (cartesian) {
      // Position on globe was clicked.
      const cartographic = ellipsoid.cartesianToCartographic(cartesian)

      const input: LeftClickPosition = {
        cartesian,
        cartographic,
        windowPos,
        picked,
      }

      const isHandled = leftClickHandlers.current.findIndex((fn) => fn(input) === true) !== -1
      if (isHandled) {
        return
      }
    }

    if (projectionType.current !== ProjectionType.Projection3D) {
      return
    }

    const pickedFor3D = pickFromPosition(viewer.current, windowPos, [])
    let id = ''
    const url = pickedFor3D.model?.primitive?._url
    if (typeof url === 'string' && url !== '') {
      const split = url.split('/')
      id = split[5]
      if (id === '3d-tiles') {
        id = split[6]
      }
    }
    if (id !== '') {
      const normalVec = get3DNormalVector(windowPos, viewer.current.scene)
      const input: LeftClickModelPosition = {
        position: pickedFor3D.cartesian,
        id,
        normal: normalVec,
      }
      leftClickModelHandlers.current.forEach((fn) => fn(input))
    }
  }

  function handleRightClick(windowPos: Cesium.Cartesian2) {
    const picked = viewer.current.scene.pick(windowPos)

    const ellipsoid = viewer.current.scene.globe.ellipsoid
    const cartesian = viewer.current.camera.pickEllipsoid(windowPos, ellipsoid)

    if (picked) {
      const idToUse = (typeof picked.id?._id === 'string' ? picked.id?._id : picked.id) || ''
      const marker = markers.current.find((x) => idToUse.startsWith(x.id))
      if (marker && marker.onRightClick) {
        marker.onRightClick({
          cartesian,
          cartographic: cartesian ? ellipsoid.cartesianToCartographic(cartesian) : undefined,
          windowPos,
          picked,
        })
        return
      } else if (marker && marker.onClick) {
        marker.onClick()
        return
      }
    }

    if (cartesian) {
      // Position on globe was clicked.
      const cartographic = ellipsoid.cartesianToCartographic(cartesian)

      const input: LeftClickPosition = {
        cartesian,
        cartographic,
        windowPos,
        picked,
      }

      const isHandled = rightClickHandlers.current.findIndex((fn) => fn(input) === true) !== -1
      if (isHandled) {
        return
      }
    }
  }

  function handleMiddleClick(windowPos: Cesium.Cartesian2) {
    const picked = viewer.current.scene.pick(windowPos)
    const ellipsoid = viewer.current.scene.globe.ellipsoid
    const cartesian = viewer.current.camera.pickEllipsoid(windowPos, ellipsoid)
    if (!cartesian) {
      // Position on globe was not clicked.
      return
    }

    const cartographic = ellipsoid.cartesianToCartographic(cartesian)

    const input: LeftClickPosition = {
      cartesian,
      cartographic,
      windowPos,
      picked,
    }

    middleClickHandlers.current.forEach((fn) => fn(input))
  }

  function handleLeftDown(windowPos: Cesium.Cartesian2) {
    const picked = viewer.current.scene.pick(windowPos)
    const ellipsoid = viewer.current.scene.globe.ellipsoid
    const cartesian = viewer.current.camera.pickEllipsoid(windowPos, ellipsoid)
    if (!cartesian) {
      // Position on globe was not clicked.
      return
    }

    const cartographic = ellipsoid.cartesianToCartographic(cartesian)

    const input: LeftClickPosition = {
      cartesian,
      cartographic,
      windowPos,
      picked,
    }

    leftDownHandlers.current.forEach((fn) => fn(input))
  }

  function handleLeftUp(windowPos: Cesium.Cartesian2) {
    const picked = viewer.current.scene.pick(windowPos)
    const ellipsoid = viewer.current.scene.globe.ellipsoid
    const cartesian = viewer.current.camera.pickEllipsoid(windowPos, ellipsoid)
    if (!cartesian) {
      // Position on globe was not clicked.
      return
    }

    const cartographic = ellipsoid.cartesianToCartographic(cartesian)

    const input: LeftClickPosition = {
      cartesian,
      cartographic,
      windowPos,
      picked,
    }

    leftUpHandlers.current.forEach((fn) => fn(input))
  }

  function setProjection(type: ProjectionType, keepCamera?: boolean): Promise<void> {
    if (projectionType.current === type) {
      return Promise.resolve()
    }

    return new Promise((resolve) => {
      const viewRect = viewer.current.camera.computeViewRectangle()
      const startHeight = viewer.current.camera.positionCartographic.height

      if (type === ProjectionType.Projection2D) {
        setProjectionType(() => type)
        viewer.current.scene.morphTo2D(0)
        viewer.current.scene.completeMorph()
        setTimeout(() => {
          jumpTo(viewRect)
          resolve()
        }, 250)
      } else if (type === ProjectionType.Projection3D) {
        viewer.current.scene.morphTo3D(0)
        viewer.current.scene.completeMorph()
        viewer.current.terrainProvider.readyPromise.then(() => {
          viewer.current.scene.sampleHeight(viewer.current.camera.positionCartographic.clone(), [], 0.5)
          Cesium.sampleTerrain(viewer.current.terrainProvider, 11, [
            viewer.current.camera.positionCartographic.clone(),
          ]).then((res) => {
            setProjectionType(() => type)

            if (!keepCamera) {
              viewer.current.camera.flyTo({
                destination: Cesium.Cartesian3.fromRadians(
                  viewer.current.camera.positionCartographic.longitude,
                  viewer.current.camera.positionCartographic.latitude,
                  startHeight + res[0].height
                ),
                duration: 0,
              })
            }

            resolve()
          })
        })
      }
    })
  }

  function toggleProjection() {
    const is2D = projectionType.current === ProjectionType.Projection2D
    setProjection(is2D ? ProjectionType.Projection3D : ProjectionType.Projection2D)
  }

  function addMarker(
    id: string,
    center: number[],
    onClick?: () => void,
    label?: string,
    size = 32,
    color = PolygonColorRed,
    iconType: MarkerIconType | Cesium.CallbackProperty = MarkerIconType.Map,
    scaleByDistance?: Cesium.NearFarScalar,
    translucencyByDistance?: Cesium.NearFarScalar,
    labelPosition?: Cesium.PositionProperty,
    surveyID?: string,
    onRightClick?: LeftClickHandler
  ) {
    const existingMarker = markers.current.find((m) => m.id === id)

    if (existingMarker) {
      return existingMarker
    }

    const positionCartesian = Cesium.Cartesian3.fromDegrees(center[0], center[1], center[2] || 0)
    if (positionCartesian.x === 0 && positionCartesian.y === 0 && positionCartesian.z === 0) {
      positionCartesian.x = 1
    }
    const positionCartographic = Cesium.Cartographic.fromDegrees(center[0], center[1])

    const billboard = markerBillboardCollection.add({
      id,
      position: positionCartesian,
      image: iconTypeToIcon(iconType),
      verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
      width: size,
      height: size,
      pixelOffset: label ? Cesium.Cartesian2.fromElements(0, 0) : undefined,
      heightReference: Cesium.HeightReference.NONE,
      eyeOffset: new Cesium.Cartesian3(0.0, 0.0, -1.0),
      show: new Cesium.TimeIntervalCollection(),
      translucencyByDistance: translucencyByDistance,
    })

    const newMarker: Marker = {
      id,
      billboard,
      center: positionCartographic,
      onClick,
      siteName: '',
      surveyID: surveyID,
      onRightClick,
    }

    if (label) {
      const isIssueIcon =
        iconType === MarkerIconType.IssueLow ||
        iconType === MarkerIconType.IssueMedium ||
        iconType === MarkerIconType.IssueHigh ||
        iconType === MarkerIconType.IssueCompleted
      newMarker.label = markerLabelCollection.add({
        id: id + '-label',
        position: labelPosition || positionCartesian,
        text: label,
        font: '16px Roboto',
        backgroundColor: Cesium.Color.fromCssColorString(color),
        showBackground: true,
        fillColor: Cesium.Color.WHITE,
        horizontalOrigin:
          iconType === MarkerIconType.None || isIssueIcon
            ? Cesium.HorizontalOrigin.CENTER
            : Cesium.HorizontalOrigin.LEFT,
        verticalOrigin: Cesium.VerticalOrigin.CENTER,
        style: Cesium.LabelStyle.FILL,
        pixelOffset:
          iconType === MarkerIconType.None ? undefined : Cesium.Cartesian2.fromElements(isIssueIcon ? 0 : 16, -16),
        scaleByDistance: scaleByDistance || new Cesium.NearFarScalar(1e1, 1, 1e3, 0.5),
        eyeOffset: new Cesium.Cartesian3(0, 0, -5),
        translucencyByDistance,
      })
    }

    setMarkers((oldMarkers) => [...oldMarkers, newMarker])
    return newMarker
  }

  function removeMarker(markerToRemove: Marker) {
    const newMarkers: Marker[] = []
    for (const marker of markers.current) {
      if (marker === undefined || markerToRemove === undefined) {
        return
      } else {
        if (marker.id === markerToRemove.id) {
          markerBillboardCollection.remove(marker.billboard)
          if (marker.label) {
            markerLabelCollection.remove(marker.label)
          }
        } else {
          newMarkers.push(marker)
        }
      }
    }

    setMarkers(() => newMarkers)
  }

  function removeMarkerById(id: string) {
    const newMarkers: Marker[] = []
    for (const marker of markers.current) {
      if (marker.id === id) {
        markerBillboardCollection.remove(marker.billboard)
        if (marker.label) {
          markerLabelCollection.remove(marker.label)
        }
      } else {
        newMarkers.push(marker)
      }
    }

    setMarkers(() => newMarkers)
  }

  function getMarkerById(id: string) {
    for (const marker of markers.current) {
      if (marker.id === id) {
        return marker
      }
    }
  }

  // CLUSTERED MARKER FUNCTIONS BELOW

  useEffect(() => {
    if (clusteredMarkers.current.length === 0 || !viewer.current) {
      return
    }

    const heightInKM = viewer.current.camera.positionCartographic.height / 1000

    let viewport: Cesium.Rectangle | undefined = undefined

    try {
      const leftTop1 = viewer.current.scene.camera.pickEllipsoid(new Cesium.Cartesian2(0, 0))
      const rightDown1 = viewer.current.scene.camera.pickEllipsoid(
        new Cesium.Cartesian2(viewer.current.scene.canvas.width, viewer.current.scene.canvas.height)
      )
      const leftTop2 = Cesium.Ellipsoid.WGS84.cartesianToCartographic(leftTop1)
      const rightDown2 = Cesium.Ellipsoid.WGS84.cartesianToCartographic(rightDown1)
      viewport = new Cesium.Rectangle(leftTop2.longitude, rightDown2.latitude, rightDown2.longitude, leftTop2.latitude)
    } catch {
      // Picking failed. We can ignore this.
    }

    const markersInViewport = clusteredMarkers.current.filter((m) => {
      if (!viewport || heightInKM > 6000) {
        return true
      }

      return Cesium.Rectangle.contains(viewport, m.center)
    })

    const getClusters = (sizeInKM: number) => {
      const clusters: Array<{
        center: Cesium.Cartographic
        sizeInKM: number
        markers: Marker[]
        label?: Cesium.Entity
        billboard?: Cesium.Billboard
      }> = []

      markersInViewport.forEach((m) => {
        const containingCluster = clusters.find((c) => {
          const ellipsoidGeodesic = new Cesium.EllipsoidGeodesic(m.center, c.center)
          const distanceInKM = ellipsoidGeodesic.surfaceDistance * 0.001
          return distanceInKM < c.sizeInKM
        })

        if (containingCluster === undefined) {
          clusters.push({
            center: m.center,
            markers: [m],
            sizeInKM,
          })
        } else {
          containingCluster.markers.push(m)
        }
      })

      return clusters
    }

    const bounds = Cesium.Rectangle.fromCartographicArray(markersInViewport.map((m) => m.center))
    const sw = Cesium.Rectangle.southwest(bounds)
    const ne = Cesium.Rectangle.northeast(bounds)
    const center = Cesium.Rectangle.center(bounds)
    const ellipsoidGeodesic = new Cesium.EllipsoidGeodesic(
      Cesium.Cartographic.fromRadians(sw.longitude, center.latitude),
      Cesium.Cartographic.fromRadians(ne.longitude, center.latitude)
    )

    let sizeInKM = (ellipsoidGeodesic.surfaceDistance * 0.001) / 6
    if (heightInKM > 4000) {
      sizeInKM *= heightInKM / 4000
    }
    sizeInKM = Math.min(sizeInKM, 2000)

    let clusters = getClusters(sizeInKM)
    while (clusters.length === 1) {
      clusters = getClusters(sizeInKM / 2)
      if (clusters.length === 1) {
        break
      }
    }

    if (clusters.length === 1 && heightInKM < 4000) {
      clusters = clusters[0].markers.map((m) => {
        return {
          center: m.center,
          markers: [m],
          sizeInKM: clusters[0].sizeInKM,
        }
      })
    }

    clusters.forEach((c) => {
      const bounds = Cesium.Rectangle.fromCartographicArray(c.markers.map((m) => m.center))
      const position = Cesium.Ellipsoid.WGS84.cartographicToCartesian(Cesium.Rectangle.center(bounds))

      c.billboard = markerBillboardCollection.add({
        id: c.markers[0].id,
        position,
        image: c.markers.length > 1 ? CLUSTER_ICON_2D : MARKER_ICON_2D_DARK,
        verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
        width: c.markers.length > 1 ? 48 : 32,
        height: c.markers.length > 1 ? 48 : 32,
      })

      if (c.markers.length > 1) {
        let xOffset = -15
        let fontSize = 16
        if (c.markers.length < 10) {
          xOffset = -11
        } else if (c.markers.length > 999) {
          xOffset = -20
          fontSize = 12
        } else if (c.markers.length > 99) {
          xOffset = -18
          fontSize = 14
        }
        c.label = viewer.current.entities.add({
          id: c.markers[0].id + '-label',
          position,
          label: {
            text: `${c.markers.length}`,
            font: `${fontSize}px Roboto`,
            backgroundColor: Cesium.Color.fromCssColorString('#1F1F1F'),
            showBackground: true,
            fillColor: Cesium.Color.WHITE,
            horizontalOrigin: Cesium.HorizontalOrigin.LEFT,
            verticalOrigin: Cesium.VerticalOrigin.CENTER,
            style: Cesium.LabelStyle.FILL,
            pixelOffset: Cesium.Cartesian2.fromElements(xOffset, -24),
          },
        })
      } else if (c.markers.length === 1) {
        c.label = viewer.current.entities.add({
          id: c.markers[0].id + '-label',
          position,
          label: {
            text: c.markers[0].siteName,
            font: '16px Roboto',
            backgroundColor: Cesium.Color.fromCssColorString('#1F1F1F'),
            showBackground: true,
            fillColor: Cesium.Color.WHITE,
            horizontalOrigin: Cesium.HorizontalOrigin.LEFT,
            verticalOrigin: Cesium.VerticalOrigin.CENTER,
            style: Cesium.LabelStyle.FILL,
            pixelOffset: Cesium.Cartesian2.fromElements(20, -12),
          },
        })
      }
    })

    const moveHandler = () => {
      setClusteredMarkers((oldMarkers) => [...oldMarkers])
    }

    const leftClickHandler = (pos: LeftClickPosition) => {
      if (!pos.picked) {
        return false
      }

      const cluster = clusters.find((c) => {
        if (typeof pos.picked.id === 'string') {
          return c.markers[0].id === pos.picked.id
        }
        try {
          return c.markers[0].id === ((pos.picked.id as any)._id || '').replace('-label', '')
        } catch {
          return false
        }
      })

      if (!cluster) {
        return false
      }

      if (cluster.markers.length === 1) {
        cluster.markers[0].onClick()
        return true
      }

      viewer.current.camera.flyTo({
        destination: expandRect(Cesium.Rectangle.fromCartographicArray(cluster.markers.map((m) => m.center))),
        duration: 0.6,
      })
    }

    addLeftClickHandler(leftClickHandler)

    viewer.current.camera.moveEnd.addEventListener(moveHandler)

    return () => {
      clusters.forEach((c) => {
        markerBillboardCollection.remove(c.billboard)
        if (c.label) {
          viewer.current.entities.remove(c.label)
        }
      })
      viewer.current.camera.moveEnd.removeEventListener(moveHandler)
      removeLeftClickHandler(leftClickHandler)
    }
  }, [clusteredMarkers.current, viewer.current])

  function addClusteredMarker(
    id: string,
    center: Cesium.Cartesian3,
    siteName: string,
    surveyID: string,
    onClick?: () => void
  ) {
    const existingMarker = clusteredMarkers.current.find((m) => m.id === id)
    if (existingMarker) {
      return existingMarker
    }

    const newMarker: Marker = {
      id,
      billboard: markerBillboardCollection.add({
        id: id + '-placeholder',
        position: center,
        verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
        width: 0,
        height: 0,
      }),
      center: Cesium.Cartographic.fromCartesian(center),
      onClick,
      siteName,
      surveyID,
    }

    setClusteredMarkers((oldMarkers) => [...oldMarkers, newMarker])
    return newMarker
  }

  function removeClusteredMarker(markerToRemove: Marker) {
    const newMarkers: Marker[] = []
    for (const marker of clusteredMarkers.current) {
      if (marker.id === markerToRemove.id) {
        markerBillboardCollection.remove(marker.billboard)
        if (marker.label) {
          markerLabelCollection.remove(marker.label)
        }
      } else {
        newMarkers.push(marker)
      }
    }

    setClusteredMarkers(() => newMarkers)
  }

  function addOrthotileLayer(id: string, orgID?: string) {
    const existing = orthotileLayers.current.find((m) => m.id === id)
    if (existing) {
      return existing
    }

    const idToUse = orgID || userInfo.org.id

    const newLayer: OrthotileLayer = {
      id,
      layer: undefined,
      provider: undefined,
    }

    const url = `${Config.BaseFileUrlWithSubdomains}${idToUse}/orthotiles/${id}/?${
      idToUse === Config.DemoOrgID ? window.__asseti_demo_qs : window.__asseti_data_qs
    }`

    //if (id === '01GHG0DVQP02S03F12PDKYB6HS') {
    //  url = 'http://localhost:5000'
    //}

    const provider = Cesium.TileMapServiceImageryProvider.fromUrl(
      new Cesium.Resource({
        url,
        proxy: new (class {
          getURL(resource: string): string {
            const url = new URL(resource)
            if (url.pathname.endsWith('.png')) {
              const parts = url.pathname.replace('.png', '').split('_')
              const finalPart = parts[parts.length - 1].replace(/\D/g, '')
              const i = parseInt(finalPart, 10)
              return resource.replace('{s}', ['a', 'b', 'c', 'd'][i % 4])
            }

            return resource.replace('{s}', 'a')
          }
        })(),
      }),
      {}
    )

    newLayer.layer = Cesium.ImageryLayer.fromProviderAsync(provider, {})
    newLayer.provider = provider
    viewer.current.imageryLayers.add(newLayer.layer)
    //viewer.current.imageryLayers.lowerToBottom(newLayer.layer)

    // provider.readyPromise.then(() => {
    //   ;(provider as any)._subdomains = ['a', 'b', 'c', 'd']
    // })

    newLayer.layer.alpha = 0
    fade2DTilesIn(newLayer.layer)

    setOrthotileLayers((old) => [...old, newLayer])
    return newLayer
  }

  function clearOrthotileLayers() {
    for (const tile of orthotileLayers.current) {
      viewer.current.imageryLayers.remove(tile.layer, true)
    }

    setOrthotileLayers(() => [])
  }

  function removeOrthotileLayer(tileToRemove: OrthotileLayer) {
    const newTiles: OrthotileLayer[] = []
    for (const tile of orthotileLayers.current) {
      if (tile.id === tileToRemove.id) {
        const fade2DTilesOut = (layer: Cesium.ImageryLayer, t = 0) => {
          setTimeout(() => {
            if (t >= 256) {
              viewer.current.imageryLayers.remove(tile.layer, true)
              return
            }

            layer.alpha = 1 - easeInOutQuart(t / 256)
            fade2DTilesOut(layer, t + 16)
          }, 16)
        }
        fade2DTilesOut(tile.layer)
      } else {
        newTiles.push(tile)
      }
    }

    setOrthotileLayers(() => newTiles)
  }

  function add3DTiles(
    id: string,
    movement: Point3Dt,
    clippingPlanesEnabled: boolean,
    clippingPlanesInset: number,
    terrainEnabled: boolean,
    aoi: PointT[],
    useAligned: boolean,
    orgID?: string
  ) {
    const existing = tileLayers3D.current.find((m) => m.id === id)
    if (existing) {
      remove3DTiles(existing)
    }

    // const url = `http://localhost:8050/${idToUse}/3d-tiles/${id}/Production_1.json?${
    //   idToUse === Config.DemoOrgID ? window.__asseti_demo_qs : window.__asseti_data_qs
    // }`

    const newTiles: TileLayer3D = {
      id,
      layer: undefined,
      provider: undefined,
      movement,
    }

    const maximumScreenSpaceError = parseInt(localStorage.getItem('asseti_sse') || '2', 10)
    const maximumMemoryUsage = parseInt(localStorage.getItem('asseti_max_mem') || '1024', 10)
    console.log('using SSE ' + maximumScreenSpaceError + ' and max mem ' + maximumMemoryUsage)

    const layerPromise = Cesium.Cesium3DTileset.fromUrl(getTileProxy(orgID || userInfo.org.id, id, useAligned), {
      backFaceCulling: true,
      maximumScreenSpaceError,
      skipLevelOfDetail: false,
      preferLeaves: false,
      skipLevels: 1,
      maximumMemoryUsage,
    })

    layerPromise.then((layer) => {
      newTiles.layer = viewer.current.scene.primitives.add(layer)
      ;(newTiles.layer as any).reconID = id

      const westernAustraliaPoints = [
        { longitude: 110.7062008876976, latitude: -32.16126880098066 },
        { longitude: 116.1759261969145, latitude: -36.59603005297902 },
        { longitude: 129.0206783468159, latitude: -33.16817738313252 },
        { longitude: 128.9932111058476, latitude: -12.10162353709799 },
        { longitude: 123.2605179228421, latitude: -13.5199667728441 },
        { longitude: 112.9896724818893, latitude: -21.45362419369986 },
      ]

      const center = Cesium.Cartesian3.clone(layer.boundingSphere.center)
      const rect = Cesium.Rectangle.fromCartesianArray(
        westernAustraliaPoints.map((p) => Cesium.Cartesian3.fromDegrees(p.longitude, p.latitude, 0))
      )
      const isInWesternAustralia = Cesium.Rectangle.contains(rect, Cesium.Cartographic.fromCartesian(center))

      if (
        id === '01H5XYDYDGC37CQCMNN3M1RJQ7' ||
        id === '01H5XY8CM6YQ1J40KPPE8F7WX4' ||
        id === '01H6AXY65RQWA3DW043JFE357T' ||
        id === '01H6BNASWHMZTVZ8R7PP4P21R5' ||
        id === '01H6D6SNH2KFZ21M9HVQCNA5Z7' ||
        id === '01H5ZWMSV0EHDT9SC366XDQB83' ||
        id === '01HADYV0TE42HSRQFCC4PMVJQW' ||
        userInfo.org.id === '01H45MDPHPBW9M7V21PDYW7GMR' ||
        userInfo.org.id === '01HQS159DYT8BCEMDAW7SAZC0H' ||
        userInfo.org.id === '01J3XK3MV9RPR6GASE7QFQARF2' ||
        isInWesternAustralia
      ) {
        viewer.current.scene.globe.depthTestAgainstTerrain = true
      }

      if (id === '01H90YKSZ8PYD1C5TRRX099K61' || !terrainEnabled) {
        viewer.current.scene.terrainProvider = new Cesium.EllipsoidTerrainProvider({})
      }

      if (movement) {
        // Set clock to make the sun high in the sky.
        const cartographic = Cesium.Cartographic.fromCartesian(center)
        const longitude = Cesium.Math.toDegrees(cartographic.longitude)
        const hoursOffset = Math.round(longitude / 15)
        let hour = 12 - hoursOffset + ''
        if (hour.length === 1) {
          hour = '0' + hour
        }
        const time = Cesium.JulianDate.fromIso8601(new Date(`2014-07-01T${hour}:00:00.104Z`).toISOString())
        viewer.current.clock.startTime = time
        viewer.current.clock.currentTime = time

        transform3DTiles(layer, {
          altitude: movement.altitude,
          latitude: movement.latitude,
          longitude: movement.longitude,
        })

        if (clippingPlanesEnabled) {
          let offsetPoints = getOffsetPolygon(
            aoi.map((p) => {
              return {
                longitude: p.longitude + Cesium.Math.toDegrees(movement.longitude),
                latitude: p.latitude + Cesium.Math.toDegrees(movement.latitude),
              }
            }),
            clippingPlanesInset,
            true,
            true
          )


          let points = offsetPoints.map((p) => Cesium.Cartesian3.fromDegrees(p.longitude, p.latitude))

          let pointsForEarCut = points.reduce((a, b) => {
            const c = Cesium.Cartographic.fromCartesian(b)
            a.push(Cesium.Math.toDegrees(c.longitude))
            a.push(Cesium.Math.toDegrees(c.latitude))
            return a
          }, [])

          let triangles = earcut(pointsForEarCut)
          if (triangles.length === 3) {
            offsetPoints = insetPolygon(
              aoi.map((p) => {
                return {
                  longitude: p.longitude + Cesium.Math.toDegrees(movement.longitude),
                  latitude: p.latitude + Cesium.Math.toDegrees(movement.latitude),
                }
              }),
              clippingPlanesInset
            )

            points = offsetPoints.map((p) => Cesium.Cartesian3.fromDegrees(p.longitude, p.latitude))

            pointsForEarCut = points.reduce((a, b) => {
              const c = Cesium.Cartographic.fromCartesian(b)
              a.push(Cesium.Math.toDegrees(c.longitude))
              a.push(Cesium.Math.toDegrees(c.latitude))
              return a
            }, [])

            triangles = earcut(pointsForEarCut)
          }

          const clippingPlanesModels = []
          const clippingPlaneCollections = []

          for (let i = 0; i < triangles.length; i += 3) {
            const index1 = triangles[i]
            const index2 = triangles[i + 1]
            const index3 = triangles[i + 2]
            const clippingPlanes = []

            const p = [points[index1], points[index2], points[index3]]

            for (let i = 0; i < p.length; ++i) {
              const nextIndex = (i + 1) % p.length
              let midpoint = Cesium.Cartesian3.add(p[i], p[nextIndex], new Cesium.Cartesian3())
              midpoint = Cesium.Cartesian3.multiplyByScalar(midpoint, 0.5, midpoint)

              const up = Cesium.Cartesian3.normalize(midpoint, new Cesium.Cartesian3())
              let right = Cesium.Cartesian3.subtract(p[nextIndex], midpoint, new Cesium.Cartesian3())
              right = Cesium.Cartesian3.normalize(right, right)

              let normal = Cesium.Cartesian3.cross(right, up, new Cesium.Cartesian3())
              normal = Cesium.Cartesian3.normalize(normal, normal)

              // Compute distance by pretending the plane is at the origin
              const originCenteredPlane = new Cesium.Plane(normal, 0.0)
              const distance = Cesium.Plane.getPointDistance(originCenteredPlane, midpoint)

              clippingPlanes.push(new Cesium.ClippingPlane(normal, distance))
              clippingPlanesModels.push({
                normalX: normal.x,
                normalY: normal.y,
                normalZ: normal.z,
                distance: distance,
              })
            }

            const cpc = new Cesium.ClippingPlaneCollection({
              planes: clippingPlanes,
              edgeWidth: 1,
              edgeColor: Cesium.Color.WHITE,
              enabled: true,
              unionClippingRegions: false,
            })
            ;(cpc as any).__id = id
            clippingPlaneCollections.push(cpc)
          }

          if (!viewer.current.scene.globe.multiClippingPlanes) {
            viewer.current.scene.globe.multiClippingPlanes = new Cesium.MultiClippingPlaneCollection({
              collections: clippingPlaneCollections,
              enabled: true,
            })
          } else {
            for (const cpc of clippingPlaneCollections) {
              viewer.current.scene.globe.multiClippingPlanes.add(cpc)
            }
          }
        }
      }
    })
    setTileLayers3D((old) => [...old, newTiles])
    return newTiles
  }

  function remove3DTiles(toRemove: TileLayer3D) {
    const newTiles: TileLayer3D[] = []
    for (const t of tileLayers3D.current) {
      if (t.id === toRemove.id) {
        const westernAustraliaPoints = [
          { longitude: 110.7062008876976, latitude: -32.16126880098066 },
          { longitude: 116.1759261969145, latitude: -36.59603005297902 },
          { longitude: 129.0206783468159, latitude: -33.16817738313252 },
          { longitude: 128.9932111058476, latitude: -12.10162353709799 },
          { longitude: 123.2605179228421, latitude: -13.5199667728441 },
          { longitude: 112.9896724818893, latitude: -21.45362419369986 },
        ]

        const center = Cesium.Cartesian3.clone(t.layer.boundingSphere.center)
        const rect = Cesium.Rectangle.fromCartesianArray(
          westernAustraliaPoints.map((p) => Cesium.Cartesian3.fromDegrees(p.longitude, p.latitude, 0))
        )
        const isInWesternAustralia = Cesium.Rectangle.contains(rect, Cesium.Cartographic.fromCartesian(center))

        viewer.current.scene.primitives.remove(t.layer)

        const id = (toRemove.layer as any).reconID

        if (
          id === '01H5XYDYDGC37CQCMNN3M1RJQ7' ||
          id === '01H5XY8CM6YQ1J40KPPE8F7WX4' ||
          id === '01H6AXY65RQWA3DW043JFE357T' ||
          id === '01H6BNASWHMZTVZ8R7PP4P21R5' ||
          id === '01H6D6SNH2KFZ21M9HVQCNA5Z7' ||
          id === '01H5ZWMSV0EHDT9SC366XDQB83' ||
          id === '01HADYV0TE42HSRQFCC4PMVJQW' ||
          userInfo.org.id === '01H45MDPHPBW9M7V21PDYW7GMR' ||
          userInfo.org.id === '01HQS159DYT8BCEMDAW7SAZC0H' ||
          isInWesternAustralia
        ) {
          viewer.current.scene.globe.depthTestAgainstTerrain = false
        }

        viewer.current.scene.globe.multiClippingPlanes = undefined
      } else {
        newTiles.push(t)
      }
    }

    setTileLayers3D(() => newTiles)
  }

  function clear3DTiles() {
    viewer.current.scene.globe.multiClippingPlanes = undefined
    for (const t of tileLayers3D.current) {
      viewer.current.scene.primitives.remove(t.layer)
    }

    setTileLayers3D(() => [])
  }

  function addLeftClickModelHandler(handler: LeftClickModelHandler) {
    const existing = leftClickModelHandlers.current.find((h) => h === handler)
    if (existing) {
      return existing
    }

    setLeftClickModelHandlers((old) => [...old, handler])
  }

  function removeLeftClickModelHandler(handler: LeftClickModelHandler) {
    const newHandlers = leftClickModelHandlers.current.filter((h) => h !== handler)
    setLeftClickModelHandlers(() => newHandlers)
  }

  function addLeftClickHandler(handler: LeftClickHandler) {
    const existing = leftClickHandlers.current.find((h) => h === handler)
    if (existing) {
      return existing
    }

    setLeftClickHandlers((old) => [...old, handler])
  }

  function removeLeftClickHandler(handler: LeftClickHandler) {
    const newHandlers = leftClickHandlers.current.filter((h) => h !== handler)
    setLeftClickHandlers(() => newHandlers)
  }

  function addRightClickHandler(handler: LeftClickHandler) {
    const existing = rightClickHandlers.current.find((h) => h === handler)
    if (existing) {
      return existing
    }

    setRightClickHandlers((old) => [...old, handler])
  }

  function removeRightClickHandler(handler: LeftClickHandler) {
    const newHandlers = rightClickHandlers.current.filter((h) => h !== handler)
    setRightClickHandlers(() => newHandlers)
  }

  function addMiddleClickHandler(handler: LeftClickHandler) {
    const existing = middleClickHandlers.current.find((h) => h === handler)
    if (existing) {
      return existing
    }

    setMiddleClickHandlers((old) => [...old, handler])
  }

  function removeMiddleClickHandler(handler: LeftClickHandler) {
    const newHandlers = middleClickHandlers.current.filter((h) => h !== handler)
    setMiddleClickHandlers(() => newHandlers)
  }

  function addLeftDownHandler(handler: LeftClickHandler) {
    const existing = leftDownHandlers.current.find((h) => h === handler)
    if (existing) {
      return existing
    }

    setLeftDownHandlers((old) => [...old, handler])
  }

  function removeLeftDownHandler(handler: LeftClickHandler) {
    const newHandlers = leftDownHandlers.current.filter((h) => h !== handler)
    setLeftDownHandlers(() => newHandlers)
  }

  function addLeftUpHandler(handler: LeftClickHandler) {
    const existing = leftUpHandlers.current.find((h) => h === handler)
    if (existing) {
      return existing
    }

    setLeftUpHandlers((old) => [...old, handler])
  }

  function removeLeftUpHandler(handler: LeftClickHandler) {
    const newHandlers = leftUpHandlers.current.filter((h) => h !== handler)
    setLeftUpHandlers(() => newHandlers)
  }

  return {
    initialized: !!viewer.current,
    baseLayer,
    subBaseLayer,
    viewer: viewer.current,
    projectionType: projectionType.current,
    initialize,
    destroy,
    setBaseLayerType,
    setSubBaseLayerType,
    jumpTo,
    flyTo,
    goToHome: () => jumpTo(homeView.current),
    setHomeView: (rect: Cesium.Rectangle) => _setHomeView(() => rect),
    toggleProjection,
    addMarker,
    removeMarker,
    removeMarkerById,
    getMarkerById,
    addClusteredMarker,
    removeClusteredMarker,
    addOrthotileLayer,
    removeOrthotileLayer,
    clearOrthotileLayers,
    orthotileLayers: orthotileLayers.current,
    add3DTiles,
    remove3DTiles,
    clear3DTiles,
    tileLayers3D: tileLayers3D.current,
    addLeftClickHandler,
    removeLeftClickHandler,
    addLeftClickModelHandler,
    removeLeftClickModelHandler,
    addRightClickHandler,
    removeRightClickHandler,
    addMiddleClickHandler,
    removeMiddleClickHandler,
    addLeftDownHandler,
    removeLeftDownHandler,
    addLeftUpHandler,
    removeLeftUpHandler,
    showControls: showControls.current,
    setShowControls: (show: boolean) => setShowControls(() => show),
    showMeasure: showMeasure.current,
    setShowMeasure: (show: boolean) => setShowMeasure(() => show),
    showLidar: showLidar.current,
    setShowLidar: (show: boolean) => setShowLidar(() => show),
    positionForBulkImageTagging,
    setPositionForBulkImageTagging,
    positionForBulkImageMoving,
    setPositionForBulkImageMoving,
    imageOffsetForBulkTagging,
    setImageOffsetForBulkTagging,
    crashInfo,
    bulkTaggingFullscreen,
    setBulkTaggingFullscreen,
    morphing,
  }
}

function getDefaultBaseLayers(): BaseLayerMap {
  const isProd = process.env.NODE_ENV === 'production'
  return {
    [BaseLayerType.Street]: {
      [BaseLayerSubType.MapBox]: () =>
        Promise.resolve(
          new Cesium.UrlTemplateImageryProvider({
            url: isProd
              ? `https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=${Config.MapBoxToken}`
              : `https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png`,
            tilingScheme: new Cesium.WebMercatorTilingScheme(),
            maximumLevel: isProd ? 21 : 18,
            tileWidth: isProd ? 512 : 256,
            tileHeight: isProd ? 512 : 256,
          })
        ),
      [BaseLayerSubType.Bing]: () =>
        Promise.resolve(
          new Cesium.UrlTemplateImageryProvider({
            url: isProd
              ? `https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=${Config.MapBoxToken}`
              : `https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png`,
            tilingScheme: new Cesium.WebMercatorTilingScheme(),
            maximumLevel: isProd ? 21 : 18,
            tileWidth: isProd ? 512 : 256,
            tileHeight: isProd ? 512 : 256,
          })
        ),
    },
    [BaseLayerType.Satellite]: {
      [BaseLayerSubType.MapBox]: () =>
        Promise.resolve(
          new Cesium.UrlTemplateImageryProvider({
            url: isProd
              ? `https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/{z}/{x}/{y}?access_token=${Config.MapBoxToken}`
              : `https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}`,
            tilingScheme: new Cesium.WebMercatorTilingScheme(),
            maximumLevel: isProd ? 21 : 18,
            tileWidth: isProd ? 512 : 256,
            tileHeight: isProd ? 512 : 256,
          })
        ),
      [BaseLayerSubType.Bing]: () => Cesium.createWorldImageryAsync(),
    },
    [BaseLayerType.PhotoModel]: {
      [BaseLayerSubType.MapBox]: () =>
        Promise.resolve(
          new Cesium.UrlTemplateImageryProvider({
            url: isProd
              ? `https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/{z}/{x}/{y}?access_token=${Config.MapBoxToken}`
              : `https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}`,
            tilingScheme: new Cesium.WebMercatorTilingScheme(),
            maximumLevel: isProd ? 21 : 18,
            tileWidth: isProd ? 512 : 256,
            tileHeight: isProd ? 512 : 256,
          })
        ),
      [BaseLayerSubType.Bing]: () => Cesium.createWorldImageryAsync(),
    },
  }
}

function initCesiumViewer(
  bounds: Cesium.Rectangle,
  baseLayers: BaseLayerMap,
  baseLayer: BaseLayerType,
  subBaseLayer: BaseLayerSubType,
  containerName: string
): Promise<Cesium.Viewer> {
  return doInitCesiumViewer(baseLayers, baseLayer, subBaseLayer, 0, containerName).then((v) => {
    if (bounds) {
      v.camera.flyTo({
        destination: bounds,
        duration: 0,
      })
    }

    v.scene.globe.baseColor = Cesium.Color.fromCssColorString('#2b2728')
    v.scene.globe.maximumScreenSpaceError = 1.2
    v.scene.globe.depthTestAgainstTerrain = false
    v.scene.globe.backFaceCulling = true
    v.scene.globe.multiClippingPlanes = undefined
    v.screenSpaceEventHandler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK)
    v.camera.frustum.near = 0.000001
    window._cesium = v

    return v
  })
}

function doInitCesiumViewer(
  baseLayers: BaseLayerMap,
  baseLayer: BaseLayerType,
  subBaseLayer: BaseLayerSubType,
  attemptNumber: number,
  containerName: string
): Promise<Cesium.Viewer> {
  return new Promise((resolve) => {
    let v: Cesium.Viewer

    const clockTime = Cesium.JulianDate.fromIso8601(new Date('2014-07-01T12:00:00.104Z').toISOString())

    const clock = new Cesium.Clock({
      startTime: clockTime,
      currentTime: clockTime,
      clockRange: Cesium.ClockRange.LOOP_STOP,
      clockStep: Cesium.ClockStep.SYSTEM_CLOCK_MULTIPLIER,
    })

    try {
      const aa = localStorage.getItem('asseti_aa') || '1'
      const af = localStorage.getItem('asseti_af') || '1'
      console.log('Antialiasing: ' + (aa === '1' ? 'Yes' : 'No'))
      console.log('Anistropic Filtering: ' + (af === '1' ? 'Yes' : 'No'))

      v = new Cesium.Viewer(containerName, {
        clockViewModel: new Cesium.ClockViewModel(clock),
        sceneMode: Cesium.SceneMode.SCENE2D,
        baseLayer: Cesium.ImageryLayer.fromProviderAsync(baseLayers[baseLayer][subBaseLayer](), {}),
        terrainProvider: new Cesium.EllipsoidTerrainProvider({}),
        mapProjection: new Cesium.WebMercatorProjection(),
        homeButton: false,
        geocoder: false,
        timeline: false,
        animation: true,
        fullscreenButton: false,
        navigationHelpButton: false,
        baseLayerPicker: false,
        projectionPicker: false,
        sceneModePicker: false,
        selectionIndicator: false,
        infoBox: false,
        shouldAnimate: true,
        requestRenderMode: true,
        // maximumRenderTimeChange : Infinity,
        contextOptions: {
          webgl: {
            alpha: false,
            depth: true,
            stencil: true,
            antialias: aa === '1',
            powerPreference: 'high-performance',
            premultipliedAlpha: false,
            preserveDrawingBuffer: true,
            failIfMajorPerformanceCaveat: attemptNumber >= 2,
          },
          allowTextureFilterAnisotropic: af === '1',
        },
      })
    } catch {
      setTimeout(() => {
        doInitCesiumViewer(baseLayers, baseLayer, subBaseLayer, attemptNumber + 1, containerName).then(resolve)
      }, 50)
      return
    }

    resolve(v)
  })
}

type useRefStateSetFn<T> = T | ((val: T) => T)

export function useRefState<T>(initialValue?: T): [MutableRefObject<T>, Dispatch<SetStateAction<T>>] {
  const [val, _setVal] = useState<T>(initialValue)
  const ref = React.useRef(val)
  const setVal = (fn: useRefStateSetFn<T>) => {
    if (typeof fn === 'function') {
      ref.current = (fn as any)(ref.current)
    } else {
      ref.current = fn
    }
    _setVal(fn)
  }

  return [ref, setVal]
}

export function iconTypeToIcon(iconType: MarkerIconType | Cesium.CallbackProperty) {
  return iconType === MarkerIconType.Map
    ? MARKER_ICON_2D
    : iconType === MarkerIconType.MapDark
    ? MARKER_ICON_2D_DARK
    : iconType === MarkerIconType.MonitoringZone
    ? MONITORING_ZONE_MARKER_ICON_2D
    : iconType === MarkerIconType.IssueLow
    ? ISSUE_MARKER_ICON_LOW
    : iconType === MarkerIconType.IssueMedium
    ? ISSUE_MARKER_ICON_MEDIUM
    : iconType === MarkerIconType.IssueHigh
    ? ISSUE_MARKER_ICON_HIGH
    : iconType === MarkerIconType.IssueHighSelected
    ? ISSUE_MARKER_ICON_HIGH_SELECTED
    : iconType === MarkerIconType.IssueMediumSelected
    ? ISSUE_MARKER_ICON_MEDIUM_SELECTED
    : iconType === MarkerIconType.IssueLowSelected
    ? ISSUE_MARKER_ICON_LOW_SELECTED
    : iconType === MarkerIconType.IssueCompleted
    ? ISSUE_MARKER_ICON_COMPLETED
    : iconType === MarkerIconType.PhotoSelected
    ? CAMERA_MARKER_ICON_2D_SELECTED
    : iconType === MarkerIconType.Interior
    ? INTERIOR_ICON
    : iconType === MarkerIconType.Camera
    ? CAMERA_MARKER_ICON_2D
    : iconType === MarkerIconType.None
    ? undefined
    : iconType
}

function easeInOutQuart(x: number): number {
  return x < 0.5 ? 8 * x * x * x * x : 1 - Math.pow(-2 * x + 2, 4) / 2
}

function fade2DTilesIn(layer: Cesium.ImageryLayer, t = 0) {
  setTimeout(() => {
    if (t >= 256) {
      layer.alpha = 1
      return
    }

    layer.alpha = easeInOutQuart(t / 256)
    fade2DTilesIn(layer, t + 16)
  }, 16)
}
