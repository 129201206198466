import * as React from 'react'
import * as TabsPrimitive from '@radix-ui/react-tabs'
import { cn } from 'ui/utils'
import './tabs.scss'

const Tabs = TabsPrimitive.Root

const TabsList = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.List>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.List>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.List ref={ref} className={cn('ui-tabs-list', className)} {...props} />
))

const TabsTrigger = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.Trigger ref={ref} className={cn('ui-tabs-trigger', className)} {...props} />
))

type TabsContentProps = React.ComponentPropsWithoutRef<typeof TabsPrimitive.Content> & {
  border?: boolean
}

const TabsContent = React.forwardRef<React.ElementRef<typeof TabsPrimitive.Content>, TabsContentProps>(
  ({ className, border = false, ...props }, ref) => (
    <TabsPrimitive.Content ref={ref} className={cn('ui-tabs-content', className, border && 'border')} {...props} />
  )
)

export { Tabs, TabsList, TabsTrigger, TabsContent }
