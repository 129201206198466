import dayjs from 'dayjs'
import React, { useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { LidarState, SiteQueryLidar, SiteQueryLidarClassification } from '~/models'
import { BaseLayerType, useAppState } from '~/state'
import { classes, formatNumber } from '../helpers'
import { useUser } from '~/base'
import { ExpandedPanel } from '~/state/use-drawer-state'
import { DrawerListContent } from '../drawer'
import { ItemTag } from '../item-tag'
import { RangeSlider, RangeSliderMulti, Switch } from '../forms'
import { getLidarProxy } from '../tile-proxy'

const PADDING_OFFSET = 20

enum ColorBy {
  RGB,
  Intensity,
  Classification,
  Elevation,
}

enum ScreenSpaceError {
  Low,
  Medium,
  High,
  Max,
}

interface LidarItemTooltipProps {
  lidar: SiteQueryLidar
}

const LidarItemTooltip = (props: LidarItemTooltipProps) => {
  const start = dayjs(props.lidar.dateTaken / 1000 / 1000)
  const title = start.format('DD MMMM YYYY')

  return (
    <>
      <li>{title}</li>
      <li>{props.lidar.state !== LidarState.Assigned && 'Processing'}</li>
      {props.lidar.state === LidarState.Assigned && 'Processed'}
    </>
  )
}

interface LidarLeftDrawerProps {
  lidar: SiteQueryLidar
  layer: Cesium.Cesium3DTileset
  colorBy: ColorBy
  setColorBy: (c: ColorBy) => void
  pointSize: number
  setPointSize: (s: number) => void
  attenuation: number
  setAttenuation: (a: number) => void
  lighting: number
  setLighting: (a: number) => void
  altitude: number[]
  setAltitude: (a: number[]) => void
  sse: ScreenSpaceError
  setSSE: (s: ScreenSpaceError) => void
}

interface ClassificationItem {
  c: SiteQueryLidarClassification
  enabled: boolean
  hovered: boolean
}

const LidarLeftDrawer = (props: LidarLeftDrawerProps) => {
  const [classifications, setClassifications] = useState<ClassificationItem[]>([])

  useEffect(() => {
    setClassifications(
      props.lidar.classifications.map((c) => {
        return {
          c,
          enabled: true,
          hovered: false,
        }
      })
    )
  }, [props.lidar])

  useEffect(() => {
    if (!Array.isArray(classifications) || classifications.length === 0 || !props.layer || !props.layer.ready) {
      return
    }

    const minZ = props.lidar.bbox[0].altitude
    const maxZ = props.lidar.bbox[1].altitude

    // Inspiration from https://sandcastle.cesium.com/#c=tVdrb9s2FP0rhFegMmbLevmh1AnWeq8A3VLMbvdhHgpKom0iEimQlF0v8H/fJSk5cpygXdcECWxe8t5z7pPMFgu0pWRHBLpEjOzQjEhaFe4HI3OWndSsZ5wpTBkRy04P3S0ZQooIAZJ3gm9pRsRFo5gKghX5k4s8W9gjTre3ZIfuqyVbsi3AKZoTSRTgWWBXpoQRtxS0oIpuiXRxljkao8XHfoQ/LqyyYzggVIn8iHzN2R9E8kqkxF0JXryWcPA6c4JJHA01B61QcsrULOdVNt/gjLL1BapNIYSVIqzCinJ2gZSoSK/ZKfAnWlTF6/aBoN49mM9Dd8msi7V7LsQh20N4CiqJqzaEOauKpVrX6VrMwQDN1T4nKMGSZIgztCF0vVFIs0ckz2kpOc3qo4sNlQh+MUO4LAUHRoYJCDK0wbCBVhCvUpCUSi1P8RZSIWt1hHwXbBC0qgSwEQjv8B50TEC0XQMKO8f8QFYgycihTCe7kIivUM7ZmqoqIz2UA7r+1jVKBRcEUcZwWgnIv5HV3iQk5QW55xG46N3N/HpxffP7x9dv5jdv3y9+Mo6hMOgnVKEtzittzBiRkCZgsdvQdKNPMa6QrFYrmlLg13J3xaG0dIxkinNi0Y6hA0I7jsBjiRRHtID4bYk9jgVph2jGi7ICB0oi+jY2x0Q0DnHLbPbuvYl9gW+BrUJ4i2mOE8gnQBjqJrkmM0lFc9UHl6DGBE0AoB2OBtPal43rOhHCQEhIpTUExIGZ2mv1VjPNbK4um7Wb8Irp8p6XkGvi2ly+arRkJVY4JTMsFF8LXEJwQbdptZbUNJIWwBZmzgla15h7xJRbx+kSea73GKYx9gSg4vdwj9juHu1lVCrMUrLgc3vs1KCxELoFXjNTps5DfNut6Bgxm63Lp4aO6dRm7GRkBcNQtmaHdfkCLTs5YWu1cV7cnRX5oYv6cAB9f869PUoQSnnORct4yiGXutcB8a9GiOBr58WdBT6gKYog2no8w8zW+s5LLjBbk5fdZefv3pNaw4dae6h3vvuM1uihVk6LzyGNH+qke8we0dGT9+Qc9L6yxptz9ZeDnbyvjtcLNNTP+V73H0aMQklAlwt9y+jZpVsqpWrvwjVkL54UF0Rgd5XvF/w+tVJRVs/4di0ca8qxB/1gNPYmvjvy42E4mkzC2ot+FMTeOIrc2B/74SQK43ojGoZeHPmuF/lDP479WIvtvcSFHmY1aEOEwmj7PA0A9NxxOJz4oReNh74Xe5NjPD13FMDlB/KRF4aTYBS0toIoHof+MI7C4RB2/cBuNTdlVX4BsBeP41EcRqNJFAVh4I97rc1oBO6PRwA+CoajY4A09mTiRcDYDzxgF7eBbQ9k+hYxvnt1cju9ztS06FVj5QcY41wo/QZwXHegSFHCnUTkIKnSW2joVEo7LfTPd4rzPIGxcXdfawlOb9dCD8oLJNYJdqKgh5o/4HivDe8GeJOY10JUfmqJEy7gduoLeEpU8nTzcAZNGUz5NgEoTkXhturjnK7B14JmWU7OUfuKQzKCE+RmK+FK8eJ09xwaRrK5RlvgK3jS9Xf12Ep4nj1Unw7a8Z5mdItodvnIixClOZYSdlZVns/pP9C9V9MBnD9Tzbl5c92A4zne62Mb/+qtFbquOx3A8nHN2hFQaVhOlb5rr+4dmqqEZ/uWQIvEyVpLsiNdGxMwOdMCCk8K+6Ba7EsipwOVndoaPDD2qPGrX0xBnWnbzaktAQUAOpAbkt4m/BNEMMMKw9uHZY2YQE3a2jTB/Doub/kOfSBrYifLE5xOJSD7Txzhqvi4PUK00nMk+lXMfyPQUMVzky8MynPw/xXa6rnZbwDjObi/gbeqbkj5TYo4aaz9jzq+0f+wDN6ztO5T8m36i2uzX0ALlieTBdat0dOeV/8C
    const tilesetCenter = props.layer.boundingSphere.center
    const distanceToCenter = Cesium.Cartesian3.magnitude(tilesetCenter)
    const center = Cesium.Cartographic.fromCartesian(props.layer.boundingSphere.center)

    props.layer.style = new Cesium.Cesium3DTileStyle({
      defines: {
        minZ,
        maxZ,
        delta: maxZ - minZ,
        height: 'length(${POSITION_ABSOLUTE}) - ' + (distanceToCenter - center.height),
        normalizedHeight:
          '((length(${POSITION_ABSOLUTE}) - ' +
          (distanceToCenter - center.height) +
          ') - ' +
          minZ +
          ') / ' +
          (maxZ - minZ) +
          ' - 0.5',
      },
      color: {
        conditions: [
          ...classifications.map((c, cIdx) => {
            return [
              '${CLASSIFICATION} === ' + (cIdx + 1),
              c.hovered
                ? 'hsl(' + (cIdx * (360 / (classifications.length + 1))) / 360 + ', 0.75, 0.5)'
                : props.colorBy === ColorBy.RGB
                ? '${COLOR}'
                : props.colorBy === ColorBy.Intensity
                ? 'rgb(${INTENSITY}, ${INTENSITY}, ${INTENSITY})'
                : props.colorBy === ColorBy.Classification
                ? `hsl(${(cIdx * (360 / (classifications.length + 1))) / 360}, 0.75, 0.5)`
                : 'hsl(${height} === 0 ? 0.33 : ${height} > 0 ? (0.33 - (${normalizedHeight}*2)*0.33) : (0.33 + (${normalizedHeight}*-2)*0.33), 0.75, 0.5)',
            ]
          }),
          [
            'true',
            props.colorBy === ColorBy.RGB
              ? '${COLOR}'
              : props.colorBy === ColorBy.Intensity
              ? 'rgb(${INTENSITY}, ${INTENSITY}, ${INTENSITY})'
              : props.colorBy === ColorBy.Classification
              ? `hsl(${((classifications.length + 2) * (360 / (classifications.length + 1))) / 360}, 0.75, 0.5)`
              : 'hsl(${height} === 0 ? 0.33 : ${height} > 0 ? (0.33 - (${normalizedHeight}*2)*0.33) : (0.33 + (${normalizedHeight}*-2)*0.33), 0.75, 0.5)',
          ],
        ],
      },
      show: {
        conditions: [
          ...classifications.map((c, cIdx) => {
            if (c.hovered) {
              return ['${CLASSIFICATION} === ' + (cIdx + 1), 'true']
            }

            if (!c.enabled) {
              return ['${CLASSIFICATION} === ' + (cIdx + 1), 'false']
            }

            if (props.altitude.length === 0) {
              return ['${CLASSIFICATION} === ' + (cIdx + 1), 'true']
            }

            if (
              props.altitude[0] === props.lidar.bbox[0].altitude &&
              props.altitude[1] === props.lidar.bbox[1].altitude
            ) {
              return ['${CLASSIFICATION} === ' + (cIdx + 1), 'true']
            }

            return [
              '${CLASSIFICATION} === ' + (cIdx + 1),
              '${height} >= ' + props.altitude[0] + ' && ${height} <= ' + props.altitude[1],
            ]
          }),
          [
            'true',
            props.altitude.length === 0 ||
            (props.altitude[0] === props.lidar.bbox[0].altitude && props.altitude[1] === props.lidar.bbox[1].altitude)
              ? 'true'
              : '${height} >= ' + props.altitude[0] + ' && ${height} <= ' + props.altitude[1],
          ],
        ],
      },
      pointSize: props.attenuation === 0 ? props.pointSize : undefined,
    })

    props.layer.pointCloudShading.attenuation = props.attenuation !== 0
    props.layer.pointCloudShading.maximumAttenuation = props.attenuation === 0 ? undefined : props.attenuation
    props.layer.pointCloudShading.eyeDomeLighting = props.lighting !== 0
    props.layer.pointCloudShading.eyeDomeLightingStrength = props.lighting === 0 ? undefined : 10 - props.lighting
    props.layer.pointCloudShading.eyeDomeLightingRadius = 1
    props.layer.maximumScreenSpaceError =
      props.sse === ScreenSpaceError.Low
        ? 16
        : props.sse === ScreenSpaceError.Medium
        ? 8
        : props.sse === ScreenSpaceError.High
        ? 4
        : 2
  }, [
    props.lidar,
    props.layer,
    classifications,
    props.colorBy,
    props.pointSize,
    props.attenuation,
    props.lighting,
    props.altitude,
    props.sse,
  ])

  const pointSizes = [1, 2, 3, 4, 5]

  return (
    <>
      <div className='site-panel'>
        <DrawerListContent
          canAdd={false}
          loading={true}
          numEntities={1}
          panelType={ExpandedPanel.None}
          plural=''
          singular=''
          title='Lidar Classifications'
          onAdd={() => {}}
          emptyButCanAddText=''
          emptyButCantAddText={''}
          hideExpand
        >
          {classifications.map((c, idx) => (
            <ItemTag
              key={c.c.name}
              title={
                c.c.name
                  .split(' ')
                  .map((word) => {
                    return word[0].toUpperCase() + word.substring(1)
                  })
                  .join(' ') + ` (${formatNumber(c.c.numPoints)})`
              }
              tagType={c.enabled ? 'component' : 'none'}
              tagStyle={
                c.enabled
                  ? {
                      borderLeftColor: `hsl(${idx * (360 / (classifications.length + 1))}, 75%, 50%)`,
                    }
                  : undefined
              }
              onClick={() => {
                const newClassifications = [...classifications]
                newClassifications[idx].enabled = !newClassifications[idx].enabled
                newClassifications[idx].hovered = false
                setClassifications(newClassifications)
              }}
              onHover={() => {
                const newClassifications = [...classifications]
                for (let i = 0; i < newClassifications.length; i++) {
                  newClassifications[i].hovered = i === idx
                }
                setClassifications(newClassifications)
              }}
              onBlur={() => {
                const newClassifications = [...classifications]
                for (let i = 0; i < newClassifications.length; i++) {
                  newClassifications[i].hovered = false
                }
                setClassifications(newClassifications)
              }}
            />
          ))}
        </DrawerListContent>
      </div>

      <div className='site-panel'>
        <DrawerListContent
          canAdd={false}
          loading={true}
          numEntities={1}
          panelType={ExpandedPanel.None}
          plural=''
          singular=''
          title='Color By'
          onAdd={() => {}}
          emptyButCanAddText=''
          emptyButCantAddText={''}
          hideExpand
        >
          <ItemTag
            title='Elevation'
            tagType={props.colorBy === ColorBy.Elevation ? 'component' : 'none'}
            onClick={() => {
              props.setColorBy(ColorBy.Elevation)
            }}
          />
          {props.lidar.hasRGB && (
            <ItemTag
              title='RGB'
              tagType={props.colorBy === ColorBy.RGB ? 'component' : 'none'}
              onClick={() => {
                props.setColorBy(ColorBy.RGB)
              }}
            />
          )}
          <ItemTag
            title='Intensity'
            tagType={props.colorBy === ColorBy.Intensity ? 'component' : 'none'}
            onClick={() => {
              props.setColorBy(ColorBy.Intensity)
            }}
          />
          <ItemTag
            title='Classification'
            tagType={props.colorBy === ColorBy.Classification ? 'component' : 'none'}
            onClick={() => {
              props.setColorBy(ColorBy.Classification)
            }}
          />
        </DrawerListContent>
      </div>

      <div className='site-panel'>
        <Switch
          name='attenuation-enabled'
          label='Attenuation'
          labelClassName='drawer-list-content-h5'
          checked={props.attenuation !== 0}
          onChange={() => {
            if (props.attenuation === 0) {
              props.setAttenuation(2)
            } else {
              props.setAttenuation(0)
            }
          }}
        />
        <RangeSlider min={1} max={5} step={0.1} value={props.attenuation} onChange={props.setAttenuation} />

        <Switch
          name='lighting-enabled'
          label='Lighting'
          labelClassName='drawer-list-content-h5'
          checked={props.lighting !== 0}
          onChange={() => {
            if (props.lighting === 0) {
              props.setLighting(1)
            } else {
              props.setLighting(0)
            }
          }}
        />
        <RangeSlider min={0} max={10} step={0.1} value={props.lighting} onChange={props.setLighting} />
        <Switch
          name='slicing-enabled'
          label='Slice'
          labelClassName='drawer-list-content-h5'
          checked={props.altitude.length !== 0}
          onChange={() => {
            if (props.altitude.length === 0) {
              props.setAltitude([props.lidar.bbox[0].altitude, props.lidar.bbox[1].altitude])
            } else {
              props.setAltitude([])
            }
          }}
        />
        <RangeSliderMulti
          min={props.lidar.bbox[0].altitude}
          max={props.lidar.bbox[1].altitude}
          step={0.1}
          values={props.altitude}
          onChange={props.setAltitude}
        />
      </div>

      <div className='site-panel'>
        <DrawerListContent
          canAdd={false}
          loading={true}
          numEntities={1}
          panelType={ExpandedPanel.None}
          plural='classifications'
          singular='classification'
          title='Point Density'
          onAdd={() => {}}
          emptyButCanAddText=''
          emptyButCantAddText={''}
          hideExpand
        >
          <div className='timeline-lidar-option-container'>
            <div
              className={classes({
                'timeline-lidar-option': true,
                active: props.sse === ScreenSpaceError.Low,
              })}
              onClick={() => {
                props.setSSE(ScreenSpaceError.Low)
              }}
            >
              Low
            </div>
            <div
              className={classes({
                'timeline-lidar-option': true,
                active: props.sse === ScreenSpaceError.Medium,
              })}
              onClick={() => {
                props.setSSE(ScreenSpaceError.Medium)
              }}
            >
              Med
            </div>
            <div
              className={classes({
                'timeline-lidar-option': true,
                active: props.sse === ScreenSpaceError.High,
              })}
              onClick={() => {
                props.setSSE(ScreenSpaceError.High)
              }}
            >
              High
            </div>
            <div
              className={classes({
                'timeline-lidar-option': true,
                active: props.sse === ScreenSpaceError.Max,
              })}
              onClick={() => {
                props.setSSE(ScreenSpaceError.Max)
              }}
            >
              Max
            </div>
          </div>
        </DrawerListContent>
      </div>

      <div className='site-panel'>
        <DrawerListContent
          canAdd={false}
          loading={true}
          numEntities={1}
          panelType={ExpandedPanel.None}
          plural='classifications'
          singular='classification'
          title='Point Size'
          onAdd={() => {}}
          emptyButCanAddText=''
          emptyButCantAddText={''}
          hideExpand
        >
          <div className='timeline-lidar-option-container'>
            {pointSizes.map((p) => {
              return (
                <div
                  className={classes({
                    'timeline-lidar-option': true,
                    active: props.pointSize === p,
                  })}
                  onClick={() => {
                    props.setPointSize(p)
                    props.setAttenuation(0)
                  }}
                >
                  {p}
                </div>
              )
            })}
          </div>
        </DrawerListContent>
      </div>
      <br />
      <br />
      <br />
    </>
  )
}

export const TimelineLidar = () => {
  const { timeline, drawer, imageTagger, map, site, components, issues, boundaryState } = useAppState()
  const contentRef = useRef<HTMLDivElement>(null)
  const datesRef = useRef<HTMLDivElement>(null)
  const [years, setYears] = useState<dayjs.Dayjs[]>([])
  const [leftByID, setLeftByID] = useState<{ [key: string]: number }>()
  const [tooltipItems, setTooltipItems] = useState<SiteQueryLidar[]>([])
  const user = useUser()
  const [activeLayer, setActiveLayer] = useState<Cesium.Cesium3DTileset>()
  const [colorBy, setColorBy] = useState<ColorBy>(ColorBy.Elevation)
  const [pointSize, setPointSize] = useState<number>(1)
  const [attenuation, setAttenuation] = useState<number>(2)
  const [lighting, setLighting] = useState<number>(1)
  const [altitude, setAltitude] = useState<number[]>([])
  const [sse, setSSE] = useState<ScreenSpaceError>(ScreenSpaceError.Medium)

  useEffect(() => {
    if (!site.site) {
      return
    }
    drawer.setExpandedPanel(ExpandedPanel.None)
    map.setBaseLayerType(BaseLayerType.PhotoModel, true)
    map.viewer.scene.globe.show = false
    map.viewer.scene.skyAtmosphere.show = false
    map.viewer.scene.skyBox.show = false
    map.viewer.scene.sun.show = false
    map.viewer.scene.sunBloom = false

    components.setHidden(true)
    issues.setShow(false)
    boundaryState.setDrawIn3D(false)

    map.tileLayers3D.forEach((x) => {
      x.layer.show = false
    })

    return () => {
      map.viewer.scene.globe.show = true
      map.viewer.scene.skyAtmosphere.show = true
      map.viewer.scene.skyBox.show = true
      map.viewer.scene.sun.show = true
      map.viewer.scene.sunBloom = true
      components.setHidden(false)
      issues.setShow(true)

      map.tileLayers3D.forEach((x) => {
        x.layer.show = true
      })
      map.setShowLidar(false)
    }
  }, [site.site])

  useEffect(() => {
    const allDates = timeline.lidarTimelime.map((i) => {
      return i.dateTaken / 1000 / 1000
    })
    allDates.sort((a, b) => {
      return a < b ? -1 : 1
    })

    const startDate = dayjs(allDates[0]).startOf('year')
    const endDate = dayjs().endOf('year')

    const years = []
    let currentDate = startDate
    while (currentDate <= endDate) {
      years.push(currentDate)
      currentDate = currentDate.add(1, 'year')
    }

    setYears(years)

    if (!timeline.activeLidar) {
      timeline.setActiveLidar(timeline.lidarTimelime[timeline.lidarTimelime.length - 1])
    }

    return () => {
      timeline.setActiveLidar(undefined)
    }
  }, [timeline.lidarTimelime])

  useEffect(() => {
    if (!contentRef.current || !datesRef.current || timeline.lidarTimelime.length === 0) {
      return
    }

    const first = timeline.lidarTimelime[0]
    const last = timeline.lidarTimelime[timeline.lidarTimelime.length - 1]
    if (first === last) {
      // TODO
    }

    const startDate = dayjs(first.dateTaken / 1000 / 1000).startOf('month')
    const endDate = dayjs(last.dateTaken / 1000 / 1000).endOf('month')

    const totalWidth = contentRef.current.clientWidth - PADDING_OFFSET * 2
    const totalDiffInMilliseconds = endDate.diff(startDate)

    const itemLefts: { [key: string]: number } = {}
    for (const item of timeline.lidarTimelime) {
      const diff = endDate.diff(dayjs(item.dateTaken / 1000 / 1000))
      const left = totalWidth - (diff / totalDiffInMilliseconds) * totalWidth
      itemLefts[item.id] = left
    }

    setLeftByID(itemLefts)
  }, [contentRef.current, datesRef.current, timeline.lidarTimelime])

  useEffect(() => {
    if (!timeline.activeLidar) {
      return
    }

    const layerPromise = Cesium.Cesium3DTileset.fromUrl(getLidarProxy(user.currentOrgId, timeline.activeLidar.id), {
      classificationType: Cesium.ClassificationType.CESIUM_3D_TILE,
    })

    let addedLayer: Cesium.Cesium3DTileset
    layerPromise.then((layer) => {
      addedLayer = layer
      map.viewer.scene.primitives.add(layer)
      if (!activeLayer) {
        map.viewer.zoomTo(layer)
      }
      setActiveLayer(layer)
      setAltitude([timeline.activeLidar.bbox[0].altitude, timeline.activeLidar.bbox[1].altitude])
    })

    return () => {
      if (addedLayer) {
        map.viewer.scene.primitives.remove(addedLayer)
      }
    }
  }, [timeline.activeLidar])

  function showTooltip(e: React.MouseEvent) {
    const positionElem = document.getElementById('timeline-lidar-content-hover-date')
    const tooltipElem = document.getElementById('timeline-lidar-content-hover-date-tooltip')
    const tooltipDateElem = document.getElementById('timeline-lidar-content-hover-date-tooltip-date')
    const rect = e.currentTarget.getBoundingClientRect()
    const x = e.clientX - rect.left - PADDING_OFFSET
    const date = getDateFromPosition(contentRef, timeline.lidarTimelime, x)

    if (date) {
      positionElem.style.left = x + 'px'
      positionElem.style.display = 'inline-block'
      if (tooltipDateElem) {
        tooltipDateElem.innerText = date.format('DD MMMM YYYY')
      }
      const left = e.pageX - tooltipElem.offsetWidth / 2
      tooltipElem.style.display = 'inline-block'

      if (left < 260) {
        tooltipElem.style.left = '260px'
      } else if (left > document.body.clientWidth - 350) {
        tooltipElem.style.left = document.body.clientWidth - 350 + 'px'
      } else {
        tooltipElem.style.left = left + 'px'
      }
    } else {
      positionElem.style.display = 'none'
      tooltipElem.style.display = 'none '
    }
  }

  function onClick(e: React.MouseEvent) {
    const positionElem = document.getElementById('timeline-lidar-content-hover-date')
    const rect = e.currentTarget.getBoundingClientRect()
    const x = e.clientX - rect.left - PADDING_OFFSET
    positionElem.style.left = x + 'px'
    positionElem.style.display = 'inline-block'

    const elems = document.elementsFromPoint(e.clientX, e.clientY)
    for (const elem of elems) {
      if (elem.classList.contains('timeline-lidar-content-item')) {
        const activeItem = timeline.lidarTimelime.find((x) => x.id === elem.id)
        if (activeItem.state === LidarState.Assigned) {
          timeline.setActiveLidar(activeItem)
          return
        }
      }
    }

    // Find closest matching date.
    const date = getDateFromPosition(contentRef, timeline.lidarTimelime, x)
    let minDiff = Number.POSITIVE_INFINITY
    let closest: SiteQueryLidar
    for (const s of timeline.lidarTimelime) {
      if (s.state !== LidarState.Assigned) {
        continue
      }
      const d = date.diff(dayjs(s.dateTaken / 1000 / 1000), 's')
      if (Math.abs(d) < minDiff) {
        minDiff = Math.abs(d)
        closest = s
      }
    }

    if (closest) {
      timeline.setActiveLidar(closest)
    }
  }

  return (
    <>
      <div
        className={classes({
          'timeline-lidar': true,
          'left-expanded': drawer.leftExpanded,
          'film-strip-expanded': imageTagger.filmStripOpen,
          'right-expanded': drawer.rightExpanded,
          'full-width': user.org.isBasic,
          lower: true,
        })}
      >
        <div
          className={classes({
            'timeline-lidar-container': true,
            hovered: false,
          })}
        >
          <div
            className={classes({
              'timeline-lidar-container-content': true,
              hovered: false,
            })}
            onMouseMove={(e) => {
              showTooltip(e)
            }}
            onClick={(e) => {
              onClick(e)
            }}
          >
            <div className='timeline-lidar-dates'>
              <div
                className={classes({
                  'timeline-lidar-dates-entries': true,
                })}
                ref={datesRef}
              >
                {years.map((year) => {
                  return (
                    <div
                      key={year.format('YYYY')}
                      className={classes({
                        'timeline-lidar-dates-entry': true,
                        bold: true,
                      })}
                      style={{ flex: `1` }}
                    >
                      {year.format('YYYY')}
                    </div>
                  )
                })}
              </div>
            </div>
            <div
              className={classes({
                'timeline-lidar-content': true,
              })}
            >
              <div
                className='timeline-lidar-content-entries'
                id='timeline-lidar-content-entries'
                ref={contentRef}
              >
                {years.map((m, i) => {
                  const formatted = m.format('MMM YYYY')
                  return (
                    <div key={formatted} className='timeline-lidar-content-month' style={{ flex: `1` }}>
                      &nbsp;
                    </div>
                  )
                })}

                {leftByID &&
                  timeline.lidarTimelime.map((s) => {
                    const left = leftByID[s.id]
                    const key = s.id
                    return (
                      <div
                        key={key}
                        id={s.id}
                        className={classes({
                          'timeline-lidar-content-item': true,
                          active: timeline.activeLidar?.id === s.id,
                          processing: s.state !== LidarState.Assigned,
                          processed: s.state === LidarState.Assigned,
                        })}
                        onMouseEnter={() => {
                          if (tooltipItems.findIndex((x) => x.id === s.id) === -1) {
                            setTooltipItems([s])
                          }
                        }}
                        onMouseLeave={() => {
                          setTooltipItems([...tooltipItems.filter((i) => i.id !== s.id)])
                        }}
                        onBlur={() => {
                          setTooltipItems([...tooltipItems.filter((i) => i.id !== s.id)])
                        }}
                        onMouseOut={() => {
                          setTooltipItems([...tooltipItems.filter((i) => i.id !== s.id)])
                        }}
                        style={{ left: left + 'px' }}
                      ></div>
                    )
                  })}
                <div id='timeline-lidar-content-hover-date' className='timeline-lidar-content-hover-date'></div>
                {leftByID && timeline.activeLidar && (
                  <div
                    className='timeline-lidar-content-active-date'
                    style={{ left: leftByID[timeline.activeLidar.id] + 2.5 + 'px' }}
                  ></div>
                )}
              </div>
            </div>
            <div
              id='timeline-lidar-content-hover-date-tooltip'
              className='timeline-lidar-content-hover-date-tooltip'
            >
              {tooltipItems.length === 0 && (
                <div
                  id='timeline-lidar-content-hover-date-tooltip-date'
                  className={classes({
                    'timeline-lidar-content-hover-date-tooltip-date': true,
                  })}
                ></div>
              )}
              {tooltipItems.length > 0 && (
                <div className='timeline-content-image-upload-tooltip'>
                  <div className='timeline-content-image-upload-tooltip-title'>Lidar</div>
                  <ul className='timeline-content-image-upload-tooltip-points'>
                    {...tooltipItems.map((i) => <LidarItemTooltip key={i.id} lidar={i} />)}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {timeline.activeLidar &&
        activeLayer &&
        createPortal(
          <LidarLeftDrawer
            lidar={timeline.activeLidar}
            layer={activeLayer}
            colorBy={colorBy}
            setColorBy={setColorBy}
            pointSize={pointSize}
            setPointSize={setPointSize}
            attenuation={attenuation}
            setAttenuation={setAttenuation}
            lighting={lighting}
            setLighting={setLighting}
            altitude={altitude}
            setAltitude={setAltitude}
            sse={sse}
            setSSE={setSSE}
          />,
          document.getElementById('site-left-drawer-portal')
        )}
    </>
  )
}
function getDateFromPosition(
  contentRef: React.MutableRefObject<HTMLDivElement>,
  lidarTimeline: SiteQueryLidar[],
  x: number
) {
  const totalWidth = contentRef.current.clientWidth - PADDING_OFFSET * 2

  const first = lidarTimeline[0]
  const last = lidarTimeline[lidarTimeline.length - 1]
  const startDate = dayjs(first.dateTaken / 1000 / 1000).startOf('month')
  const endDate = dayjs(last.dateTaken / 1000 / 1000).endOf('month')
  const totalDiffInMilliseconds = endDate.diff(startDate)

  const deltaMS = (x / totalWidth) * totalDiffInMilliseconds
  const date = startDate.add(deltaMS, 'ms')
  return date
}
