query instantAssessmentsBySite($input: QueryInstantAssessmentsBySiteRequestT!) {
  instantAssessmentsBySite(input: $input) {
    id
    status
    orgID
    orgName
    siteID
    siteName
    surveyDate
    surveyResourceID
    externalImageIDs
    reconstruction {
      id
      orgId
      siteId
      orgName
      siteName
      priority
      aoi {
        longitude
        latitude
      }
      imageDays
      images {
        id
        hash
        dateTaken
        latitude
        longitude
        altitude
      }
      createdById
      createdAt
      updatedById
      updatedAt
      instantAssessment
    }
    aois {
      points {
        latitude
        longitude
      }
    }
    createdByID
    createdAt
    updatedByID
    updatedAt
  }
}
