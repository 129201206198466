import { Dayjs } from 'dayjs'
import React, { useRef } from 'react'
import { useAppState } from '~/state'
import { classes } from '../helpers'

interface TimelineDatesProps {
  months: Dayjs[]
  adjustmentX: number
  leftMouseDown: boolean
}

export const TimelineDates = (props: TimelineDatesProps) => {
  const { timeline } = useAppState()
  const months = props.months
  const adjustmentX = props.adjustmentX

  const container = useRef<HTMLDivElement>(null)

  return (
    <div
      className={classes({
        'timeline-dates': true,
        hovered: props.leftMouseDown,
      })}
      ref={container}
    >
      <div
        className={classes({
          'timeline-dates-entries': true,
          'animate-movement': !props.leftMouseDown,
        })}
        style={{ left: `${adjustmentX}px` }}
      >
        {months.map((month, i) => {
          const monthFormat = month.format('MMM')
          return (
            <div
              key={month.format('MMMM YYYY')}
              className={classes({
                'timeline-dates-entry': true,
                bold: i % 12 === 0,
              })}
              style={{ flex: `0 0 ${timeline.entrySize}px` }}
            >
              {i % 12 === 0 ? month.format('MMM YY') : timeline.entrySize > 50 || i % 2 === 0 ? monthFormat : ''}
            </div>
          )
        })}
      </div>
    </div>
  )
}
